import React, { FC, useMemo } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { NavFrameHeight } from '../stores/uiStore'
import { LayerState, PhaseSources } from '../stores/layerStore'
import { ILegend, ILegendSource } from '../api/sources'
import { SourceTypeEnum } from '../enums/SourceTypeEnum'

import Style from '../styles/MapLegend.module.sass'

const LEGEND_HEIGHT_OFFSET = 135

const MapLegend: FC = () => {
  const frameHeight = useRecoilValue(NavFrameHeight)
  const layerState = useRecoilValue(LayerState)
  const phaseSources = useRecoilValueLoadable(PhaseSources)

  const sourceLegend = useMemo(() => {
    if (phaseSources.state !== 'hasValue' || !phaseSources.contents) return

    const sources = phaseSources.contents.filter((source) => {
      return source.Layers.length && source.Layers.every((layer) => layerState[layer.layerId])
    })

    const legend: ILegendSource[] = []
    sources.forEach((source) => {
      if (!source.legend) return
      let legendPaint = null
      try {
        legendPaint = JSON.parse(source.legend)
      } catch (error) {
        console.log(`Source ${source.sourceId} has malformed legend definition.`)
      }

      if (legendPaint)
        legend.push({
          name: source.name,
          type: source.type,
          paint: source.paint,
          ...(legendPaint as ILegend),
        })
    })

    return legend
  }, [phaseSources, layerState])

  if (!sourceLegend?.length) return null

  return (
    <div
      className={Style.mapLegend}
      style={{ bottom: `${frameHeight}px`, maxHeight: `calc(100vh - ${frameHeight + LEGEND_HEIGHT_OFFSET}px)` }}
    >
      <p className={Style.legendHeader}>Legend</p>
      {sourceLegend?.map((source) => {
        if (source.type === SourceTypeEnum.HEATMAP && source.heatmap) {
          let colours = ['blue', 'lightgreen', 'yellow', 'red']

          try {
            const paint = JSON.parse(source.paint)
            const heatmapColours: [] = paint['heatmap-color']

            if (heatmapColours) {
              heatmapColours.splice(0, 3)
              colours = heatmapColours.filter((value) => typeof value === 'string')
            }
          } catch (error) {
            console.log('Error getting heatmap colours - using default')
          }

          const style = `linear-gradient(to right, ${colours.join(', ')})`

          return (
            <div key={source.name}>
              <p key={source.name} className={Style.legendEntry}>
                {source.name}
              </p>
              <div className={Style.heatmapLegend}>
                <span className={Style.heatmapLeftLabel}>{source.heatmap.minName}</span>
                <div className={Style.heatmapGradient} style={{ backgroundImage: style }}></div>
                <span className={Style.heatmapRightLabel}>{source.heatmap.maxName}</span>
              </div>
            </div>
          )
        }

        let previewStyle = Style.legendFill

        switch (source.type) {
          case SourceTypeEnum.LINE:
            previewStyle = Style.legendLine
            break
          case SourceTypeEnum.FILL:
            previewStyle = Style.legendFill
            break
          case SourceTypeEnum.CIRCLE:
            previewStyle = Style.legendCircle
            break

          default:
            break
        }

        if (source.legend.length === 1) {
          const legendStyle = {
            backgroundColor: source.legend[0].bg,
            borderColor: source.legend[0].line,
          }

          return (
            <div key={source.name} className={Style.legendEntry}>
              <div className={previewStyle} style={legendStyle} />
              <span>{source.name}</span>
            </div>
          )
        } else if (source.legend.length > 1) {
          return (
            <div key={source.name}>
              <p key={source.name} className={Style.legendEntry}>
                {source.name}
              </p>

              {source.legend.map((l) => {
                const legendStyle = {
                  backgroundColor: l.bg,
                  borderColor: l.line,
                }

                return (
                  <div key={l.name} className={Style.legendSubEntry}>
                    <div className={previewStyle} style={legendStyle} />
                    <span>{l.name}</span>
                  </div>
                )
              })}
            </div>
          )
        }
        return null
      })}
    </div>
  )
}

export default MapLegend
