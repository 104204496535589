import React, { FC, useState, ReactElement, Fragment, useEffect, useMemo } from 'react'
import Style from '../styles/CommentsMenu.module.sass'
import { Pagination, Pill, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import { unixDateToString } from '../helpers/utils'
import { IComment } from '../api/comments'
import { ReactComponent as ReplyIcon } from '../assets/reply.svg'
import { ReactComponent as CollapseIcon } from '../assets/chevron_up.svg'
import { CategoryNameColour, ASSIGNED_COLOUR, UNASSIGNED_COLOUR } from './CommentsTab'
import ArchiveButton from './ArchiveButton'
import { useRecoilValue } from 'recoil'
import { ProjectDetails } from '../stores/projectStore'
import { isGlobalAdmin, isProjectAdmin } from '../helpers/appRoles'
import { useAuth0 } from '@auth0/auth0-react'

const COMMENTS_PER_PAGE = 10

type SortType =
  | 'RepliesAsc'
  | 'RepliesDesc'
  | 'CategoryAsc'
  | 'CategoryDesc'
  | 'AuthorAsc'
  | 'AuthorDesc'
  | 'AssigneeAsc'
  | 'AssigneeDesc'
  | 'DateAsc'
  | 'DateDesc'
  | 'VisibilityAsc'
  | 'VisibilityDesc'
  | 'AttachmentsAsc'
  | 'AttachmentsDesc'

const getRowClass = (
  active: boolean,
  topLevel: boolean,
  archived: boolean,
  archivedReply: boolean,
  isReply: boolean,
) => {
  let rowClass = ''
  if (active) rowClass = Style.active
  else if (!isReply) rowClass = Style.reply

  if (archived) rowClass = rowClass ? `${rowClass} ${Style.archived}` : Style.archived
  if (archivedReply && !isReply) rowClass = rowClass ? `${rowClass} ${Style.archivedReply}` : Style.archivedReply
  return rowClass
}

const sortCategories = (
  categoryId1: string,
  categoryId2: string,
  categories: CategoryNameColour,
  isAsc: boolean,
): number => {
  const category1 = !categoryId1 ? categories[categoryId1].name : categoryId1
  const category2 = !categoryId2 ? categories[categoryId2].name : categoryId2
  return isAsc ? category1.localeCompare(category2) : category2.localeCompare(category1)
}

const setSortTitle = (column: string, sortMode: string): string => {
  const columnSortAsc = `${column}Asc`
  const columnSortDesc = `${column}Desc`
  return sortMode === columnSortAsc ? 'Sort A-Z' : sortMode === columnSortDesc ? 'Sort Z-A' : ''
}

const setSortingDateTitle = (sortMode: string): string => {
  const columnSortAsc = `DateAsc`
  const columnSortDesc = `DateDesc`
  return sortMode === columnSortAsc ? 'Sort newest' : sortMode === columnSortDesc ? 'Sort oldest' : ''
}

const setSortingVisibilityTitle = (sortMode: string): string => {
  const columnSortAsc = `VisibilityAsc`
  const columnSortDesc = `VisibilityDesc`
  return sortMode === columnSortAsc ? 'Sort internal team' : sortMode === columnSortDesc ? 'Sort public' : ''
}

const sortComments = (comments: IComment[], categories: CategoryNameColour, sortMode: SortType) => {
  const tempComments = [...comments]
  return tempComments.sort((comment1, comment2) => {
    let replyCount1 = 0
    let replyCount2 = 0
    if (sortMode === 'RepliesAsc' || sortMode === 'RepliesDesc') {
      replyCount1 = comments.filter((otherComment) => otherComment.threadId === comment1.commentId).length
      replyCount2 = comments.filter((otherComment) => otherComment.threadId === comment2.commentId).length
    }

    switch (sortMode) {
      case 'RepliesAsc':
        return replyCount1 - replyCount2

      case 'RepliesDesc':
        return replyCount2 - replyCount1

      case 'CategoryAsc':
        return sortCategories(
          JSON.stringify(comment1.categoryId) || '',
          JSON.stringify(comment2.categoryId) || '',
          categories,
          true,
        )
      case 'CategoryDesc':
        return sortCategories(
          JSON.stringify(comment1.categoryId) || '',
          JSON.stringify(comment2.categoryId) || '',
          categories,
          false,
        )

      case 'AuthorAsc':
        return (comment1.authorName || '').localeCompare(comment2.authorName || '')

      case 'AuthorDesc':
        return (comment2.authorName || '').localeCompare(comment1.authorName || '')

      case 'AssigneeAsc':
        if (comment1.assigneeName && comment2.assigneeName)
          return comment1.assigneeName.localeCompare(comment2.assigneeName)
        else if (comment1.assigneeName) return -1
        else if (comment2.assigneeName) return 1
        else return 0

      case 'AssigneeDesc':
        if (comment1.assigneeName && comment2.assigneeName)
          return comment2.assigneeName.localeCompare(comment1.assigneeName)
        else if (comment1.assigneeName) return -1
        else if (comment2.assigneeName) return 1
        else return 0

      case 'DateAsc':
        return comment1.createdAt - comment2.createdAt

      case 'DateDesc':
        return comment2.createdAt - comment1.createdAt

      case 'VisibilityAsc':
        if (comment1.onlyVisibleToOwners && !comment2.onlyVisibleToOwners) return -1
        else if (comment2.onlyVisibleToOwners && !comment1.onlyVisibleToOwners) return 1
        else return 0

      case 'VisibilityDesc':
        if (comment1.onlyVisibleToOwners && !comment2.onlyVisibleToOwners) return 1
        else if (comment2.onlyVisibleToOwners && !comment1.onlyVisibleToOwners) return -1
        else return 0

      default:
        return 0
    }
  })
}

export interface ICommentsListProps {
  comments: IComment[] | null
  categoryList: CategoryNameColour
}

const CommentsList: FC<ICommentsListProps> = (props) => {
  const [page, setPage] = useState(1)
  const [activeCommentId, setActiveCommentId] = useState<string>()
  const [sortMode, setSortMode] = useState<SortType>('DateDesc')
  const { comments } = props
  const { user } = useAuth0()
  const details = useRecoilValue(ProjectDetails)

  useEffect(() => {
    setActiveCommentId(undefined)
  }, [page])

  const sortedComments = useMemo(() => {
    if (comments) {
      return sortComments(comments, props.categoryList, sortMode)
    } else {
      return []
    }
  }, [comments, props.categoryList, sortMode])

  const pageCount = Math.ceil(sortedComments.filter((comment) => !comment.threadId).length / COMMENTS_PER_PAGE)
  const showArchiveButton = isProjectAdmin(details) || isGlobalAdmin(user)

  const getCommentRow = (comment: IComment, index: number, isCommentArchived: boolean): ReactElement => {
    const topLevel = !comment.threadId
    const matchingCategory = comment.categoryId ? props.categoryList[comment.categoryId] : null
    const replies =
      topLevel && comments ? comments.filter((otherComment) => otherComment.threadId === comment.commentId) : []
    const active = topLevel && comment.commentId === activeCommentId

    return (
      <Fragment key={comment.commentId}>
        <TableRow
          key={comment.commentId}
          onClick={
            replies.length > 0
              ? () => setActiveCommentId(comment.commentId === activeCommentId ? undefined : comment.commentId)
              : undefined
          }
          rowClass={getRowClass(active, topLevel, comment.archived, isCommentArchived, topLevel)}
        >
          <TableCell title={!active && replies.length > 0 ? 'View comment thread' : ''}>
            {topLevel ? replies.length : <ReplyIcon />}
            {active ? <CollapseIcon /> : null}
          </TableCell>
          <TableCell>
            {matchingCategory && (
              <Pill colour={matchingCategory.colour} size='small'>
                {matchingCategory.name}
              </Pill>
            )}
          </TableCell>
          <TableCell>
            <Pill colour={comment.assignee ? ASSIGNED_COLOUR : UNASSIGNED_COLOUR} size='small'>
              {comment.assignee ? 'Assigned' : 'Unassigned'}
            </Pill>
          </TableCell>
          <TableCell>{comment.authorName || ''}</TableCell>
          <TableCell>{comment.title}</TableCell>
          <TableCell>{comment.content}</TableCell>
          <TableCell>{comment.assigneeName || 'N/A'}</TableCell>
          <TableCell>{unixDateToString(comment.createdAt)}</TableCell>
          <TableCell>{comment.onlyVisibleToOwners ? 'Internal' : 'Public'}</TableCell>
          <TableCell>{comment.attachments.length}</TableCell>
          {showArchiveButton && <TableCell>{topLevel && <ArchiveButton comment={comment} size='small' />}</TableCell>}
        </TableRow>
        {activeCommentId === comment.commentId &&
          topLevel &&
          replies.map((reply) => getCommentRow(reply, index, comment.archived))}
      </Fragment>
    )
  }

  return (
    <>
      <Table
        headers={[
          {
            label: 'Replies',
            sort: sortMode === 'RepliesAsc' ? 'asc' : sortMode === 'RepliesDesc' ? 'desc' : 'none',
            onSort: () => setSortMode(sortMode === 'RepliesDesc' ? 'RepliesAsc' : 'RepliesDesc'),
          },
          {
            label: 'Category',
            sort: sortMode === 'CategoryAsc' ? 'asc' : sortMode === 'CategoryDesc' ? 'desc' : 'none',
            title: setSortTitle('Category', sortMode),
            onSort: () => setSortMode(sortMode === 'CategoryAsc' ? 'CategoryDesc' : 'CategoryAsc'),
          },
          { label: 'Status' },
          {
            label: 'Author',
            sort: sortMode === 'AuthorAsc' ? 'asc' : sortMode === 'AuthorDesc' ? 'desc' : 'none',
            title: setSortTitle('Author', sortMode),
            onSort: () => setSortMode(sortMode === 'AuthorAsc' ? 'AuthorDesc' : 'AuthorAsc'),
          },
          { label: 'Title' },
          { label: 'Description' },
          {
            label: 'Assigned To',
            sort: sortMode === 'AssigneeAsc' ? 'asc' : sortMode === 'AssigneeDesc' ? 'desc' : 'none',
            title: setSortTitle('Assignee', sortMode),
            onSort: () => setSortMode(sortMode === 'AssigneeAsc' ? 'AssigneeDesc' : 'AssigneeAsc'),
          },
          {
            label: 'Date',
            sort: sortMode === 'DateAsc' ? 'asc' : sortMode === 'DateDesc' ? 'desc' : 'none',
            title: setSortingDateTitle(sortMode),
            onSort: () => setSortMode(sortMode === 'DateDesc' ? 'DateAsc' : 'DateDesc'),
          },
          {
            label: 'Visibility',
            sort: sortMode === 'VisibilityAsc' ? 'asc' : sortMode === 'VisibilityDesc' ? 'desc' : 'none',
            title: setSortingVisibilityTitle(sortMode),
            onSort: () => setSortMode(sortMode === 'VisibilityAsc' ? 'VisibilityDesc' : 'VisibilityAsc'),
          },
          { label: 'Attachments' },
          ...(showArchiveButton ? [{ label: '' }] : []),
        ]}
        striped={true}
        hoverable={true}
        headerLight={true}
      >
        {sortedComments
          .filter((comment) => !comment.threadId)
          .slice((page - 1) * COMMENTS_PER_PAGE, (page - 1) * COMMENTS_PER_PAGE + COMMENTS_PER_PAGE)
          .map((comment, index, array) => {
            const previousComment = array[index - 1]
            const isCommentArchived = previousComment ? previousComment.archived : false
            return getCommentRow(comment, index, isCommentArchived)
          })}
      </Table>
      <div className={Style.pagination}>
        <Pagination
          page={page}
          pageCount={pageCount}
          onChange={setPage}
          prevButtonTitle='Previous page'
          nextButtonTitle='Next page'
        />
      </div>
    </>
  )
}

export default CommentsList
