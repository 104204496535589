export enum ArchivedCommentEnum {
  ALL = 0,
  ONLY_ARCHIVED = 1,
}

export const ArchivedCommentEnumLabels = {
  [ArchivedCommentEnum.ALL]: 'Show all comments',
  [ArchivedCommentEnum.ONLY_ARCHIVED]: 'Show archived comments only',
}

export const ArchivedCommentEnumOptions = [
  { id: ArchivedCommentEnum.ALL.toString(), label: ArchivedCommentEnumLabels[ArchivedCommentEnum.ALL] },
  {
    id: ArchivedCommentEnum.ONLY_ARCHIVED.toString(),
    label: ArchivedCommentEnumLabels[ArchivedCommentEnum.ONLY_ARCHIVED],
  },
]
