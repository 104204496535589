import { TokenExpiryWrapper, ProjectReadScope } from './TokenManager'
import { headersWithToken } from '../helpers/utils'
import { ReactText } from 'react'

export type Visibility = 'internal' | 'public'
export type ShowComment = 'archived' | 'non-archived' | 'all'

export interface IComment {
  commentId: string
  phaseId: string
  threadId: string | null
  userId: string
  categoryId: ReactText | null
  title: string
  content: string
  onlyVisibleToOwners: boolean
  lng: number
  lat: number
  zoom: number
  createdAt: number
  assignee: string | null
  assigneeName: string | null
  authorName?: string
  seen?: boolean
  attachments: string[]
  archived: boolean
}

export interface IRequestComments {
  token?: string
  phaseId: string
  showComment: ShowComment
}

const getCommentsRequest = async (props: IRequestComments): Promise<IComment[] | null> => {
  const { phaseId, showComment } = props
  const apiUrl = `/comments/${phaseId}?show=${showComment}`
  const response = await fetch(apiUrl, {
    headers: headersWithToken(props.token),
  })
  if (!response.ok) throw response
  return response.json()
}
export const getComments = TokenExpiryWrapper(getCommentsRequest, [ProjectReadScope], null)

export interface ICommentAddProps {
  token?: string
  phaseId: string
  comment: IComment
  toRemove?: string[]
}

const addCommentRequest = async (props: ICommentAddProps): Promise<IComment | null> => {
  const responseAddComment = await fetch(`/comments`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.comment),
  })

  if (!responseAddComment.ok) throw responseAddComment
  return responseAddComment.json()
}
export const addComment = TokenExpiryWrapper(addCommentRequest, [ProjectReadScope], null)

const updateCommentRequest = async (props: ICommentAddProps): Promise<IComment | null> => {
  const responseUpdateComment = await fetch(`/comments`, {
    method: 'put',
    headers: headersWithToken(props.token),
    body: JSON.stringify({
      phaseId: props.phaseId,
      comment: props.comment,
      toRemove: props.toRemove,
    }),
  })

  if (!responseUpdateComment.ok) throw responseUpdateComment

  return responseUpdateComment.json()
}
export const updateComment = TokenExpiryWrapper(updateCommentRequest, [ProjectReadScope], null)

export interface ICommentArchiveProps {
  token?: string
  phaseId: string
  commentId: string
  archived: boolean
}

const archiveCommentRequest = async (props: ICommentArchiveProps): Promise<IComment | null> => {
  const responseArchiveComment = await fetch(`/comments/archive`, {
    method: 'put',
    headers: headersWithToken(props.token),
    body: JSON.stringify({
      phaseId: props.phaseId,
      commentId: props.commentId,
      archived: props.archived,
    }),
  })

  if (!responseArchiveComment.ok) throw responseArchiveComment
  return responseArchiveComment.json()
}
export const archiveComment = TokenExpiryWrapper(archiveCommentRequest, [ProjectReadScope], null)
