import React, { FC, useEffect, useState, Suspense } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { RecoilRoot } from 'recoil'
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide'

import Login from './pages/Login'
import Consent from './pages/Consent'
import Profile from './pages/Profile'
import Project from './pages/Project'
import MyProjects from './pages/MyProjects'
import NotFound from './pages/NotFound'
import NoAccess from './pages/NoAccess'

// Code split admin functions
const EditProject = React.lazy(() => import('./pages/EditProject'))
const Admin = React.lazy(() => import('./pages/Admin'))

import AdminRoute from './components/AdminRoute'
import LoadingScreen from './components/LoadingScreen'
import { isGlobalAdmin } from './helpers/appRoles'
import TermsCookiesModal from './components/TermsCookiesModal'
import TermsAndConditions from './pages/TermsAndConditions'
import About from './pages/About'
import PrivacyPolicy from './pages/PrivacyPolicy'
import CookiesPolicy from './pages/CookiesPolicy'
import { getAppInsightsInstance } from './helpers/applicationInsights'

const App: FC = () => {
  const { isLoading, isAuthenticated, user } = useAuth0()
  const [isConsented, setIsConsented] = useState(false)

  useEffect(() => {
    getAppInsightsInstance().then((appInsights) => {
      if (!user || !user.sub || !appInsights) return
      console.log('** Setting AppInsights Session...')
      appInsights.setAuthenticatedUserContext(user.sub, undefined, true)
    })

    return () => {
      getAppInsightsInstance().then((appInsights) => {
        appInsights && appInsights.clearAuthenticatedUserContext()
      })
    }
  }, [user])

  if (isLoading) {
    return <LoadingScreen text='Loading application...' />
  }

  const publicRoutes = [
    <Route key='home_public' path='/login' component={Login} exact={true} />,
    <Route key='terms' path='/termsandconditions' component={TermsAndConditions} exact={true} />,
    <Route key='about' path='/about' component={About} exact={true} />,
    <Route key='privacy' path='/privacy' component={PrivacyPolicy} exact={true} />,
    <Route key='cookies' path='/cookies' component={CookiesPolicy} exact={true} />,
  ]

  const privateRoutes = [
    <Route key='home_private' path='/' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        {isGlobalAdmin(user) ? <Admin /> : <Redirect to='/projects' />}
      </Suspense>
    </Route>,
    <Route
      key='project'
      path='/project/:projectId/:phaseId/'
      render={(props) => {
        return <Redirect to={`/project/${props.match.params.phaseId}`} />
      }}
      exact={false}
    ></Route>,
    <Route key='project' path='/project/:phaseId/' component={Project} exact={false} />,
    <Route key='project' path='/project/:slug/' component={Project} exact={false} />,
    <Route key='projects' path='/projects' component={MyProjects} exact={true} />,
    <Route key='noaccess' path='/noaccess/:phaseId?' component={NoAccess} exact={true} />,
    <Route key='userguide' path='/userguide' component={About} exact={true} />,
    <Route key='profile' path='/profile' component={Profile} exact={true} />,
    <Route key='project_create' path='/admin/project/create' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <EditProject createMode={true} />
      </Suspense>
    </Route>,
    <Route key='project_create' path='/admin/project/:slugOrPhaseId' exact={true}>
      <Suspense fallback={<LoadingScreen text='Loading application...' />}>
        <EditProject createMode={false} />
      </Suspense>
    </Route>,
  ]

  if (!isAuthenticated) {
    return (
      <RecoilRoot>
        <HashRouter hashType='slash'>
          <Switch>
            {publicRoutes}
            <Route
              render={(props) => {
                return (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: props.location },
                    }}
                  />
                )
              }}
            />
          </Switch>
        </HashRouter>
      </RecoilRoot>
    )
  }

  if (!isConsented) {
    return (
      <RecoilRoot>
        <Consent
          setConsented={() => {
            setIsConsented(true)
          }}
        />
      </RecoilRoot>
    )
  }

  // In the case with two routes with the same path, private will override public
  return (
    <RecoilRoot>
      <ToastProvider>
        <HashRouter hashType='slash'>
          <Switch>
            {privateRoutes}
            {publicRoutes}
            <AdminRoute path='/admin' exact>
              <Suspense fallback={<LoadingScreen text='Loading application...' />}>
                <Admin />
              </Suspense>
            </AdminRoute>
            <Route component={NotFound} />
          </Switch>
          <TermsCookiesModal />
        </HashRouter>
        <Toast />
      </ToastProvider>
    </RecoilRoot>
  )
}

export default App
