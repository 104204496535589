import { Button, Container, Grid } from '@aurecon-creative-technologies/styleguide'
import React, { FC } from 'react'
import Page from '../components/Page'

import { ReactComponent as StorytellingIcon } from '../assets/storytelling.svg'
import { ReactComponent as DigitalPlatformIcon } from '../assets/digital_platform.svg'
import { ReactComponent as CollaborationIcon } from '../assets/collaboration.svg'

import Style from '../styles/About.module.sass'
import { JIRA_SUPPORT_URL } from '../config/config'

const About: FC = () => {
  const handleButtonClick = (url: string) => {
    window.open(url)
  }

  return (
    <Page background>
      <Container>
        <div className={Style['container']}>
          <Grid row>
            <Grid xs={12} lg={2} />
            <Grid xs={12} lg={8}>
              <h1>About Align</h1>
              <p>
                Align is an interactive tool created to support large rail infrastructure projects by helping visualise
                multiple rail corridor options, making decision-making more agile and user-friendly.
              </p>
              <p>
                The web-based tool displays entire rail routes on an interactive map, allowing stakeholders and clients
                to view and navigate all options available. Align supports the display of high-resolution aerial
                photography and custom GIS layers including planning zones, heritage sites, and waterways.
              </p>
              <h1>How Align Works</h1>
            </Grid>
            <Grid xs={12} lg={2} />

            <Grid xs={12} md={4}>
              <div className={Style['works']}>
                <StorytellingIcon />
                <h3>Storytelling and visualisation</h3>
                <p>
                  Provides the ability for different stakeholders to view maps and design options in an interactive
                  manner. This allows all stakeholders involved to assess and compared the various impacts of the design
                  options, ensuring that they meet the specified requirements.
                </p>
              </div>
            </Grid>

            <Grid xs={12} md={4}>
              <div className={Style['works']}>
                <DigitalPlatformIcon />
                <h3>Single digital platform</h3>
                <p>
                  Allows multiple drawings from different disciplines to be viewed simultaneously in a presentation. The
                  combined view reduces the need to review multiple scattered documents. The hustle to print large
                  amount of separate sets of CAD drawings is also minimised.
                </p>
              </div>
            </Grid>

            <Grid xs={12} md={4}>
              <div className={Style['works']}>
                <CollaborationIcon />
                <h3>Collaboration {'&'} decision making</h3>
                <p>
                  The comment function within Align presents the opportunity for all stakeholders to record their
                  feedback on various design options anytime and anywhere. It also allows valuable ideas to be captured
                  during a remote workshop or presentation, and hence provide the opportunities for further improvement.{' '}
                </p>
              </div>
            </Grid>

            <div className={Style['find-out']}>
              <h3>Find out more from the Aurecon team</h3>
              <Button label='Contact us' onClick={() => handleButtonClick(`${JIRA_SUPPORT_URL}`)} />
            </div>
          </Grid>
        </div>
      </Container>
    </Page>
  )
}

export default About
