import { MAPBOX_ACCOUNT } from '../config/config'
import { IResponse } from '../helpers/utils'
import { getMapboxToken } from './storage'
import { S3 } from 'aws-sdk'

const BaseAPIURL = 'https://api.mapbox.com/uploads/v1'

const region = 'us-east-1'

interface IAWSCredentials {
  key: string
  accessKeyId: string
  bucket: string
  secretAccessKey: string
  sessionToken: string
  url: string
}

const getCredentials = async (token: string): Promise<IAWSCredentials | null> => {
  try {
    const result = await fetch(`${BaseAPIURL}/${MAPBOX_ACCOUNT}/credentials?access_token=${token}`, { method: 'POST' })
    if (result.ok) return result.json()
  } catch (error) {
    console.error(error)
  }
  return null
}

const putFileOnS3 = async (
  file: File,
  credentials: IAWSCredentials,
  setUploadProgress: (loaded: number, total: number) => void,
): Promise<boolean> => {
  const s3 = new S3({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region,
  })

  const request = s3.putObject({
    Bucket: credentials.bucket,
    Key: credentials.key,
    Body: file,
  })

  request.on('httpUploadProgress', ({ loaded, total }) => setUploadProgress(loaded, total))

  try {
    await request.promise()
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const createUpload = async (
  credentials: IAWSCredentials,
  token: string,
  tilesetId: string,
  sourceName: string,
): Promise<{ id: string } | null> => {
  try {
    const result = await fetch(`${BaseAPIURL}/${MAPBOX_ACCOUNT}?access_token=${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tileset: `${MAPBOX_ACCOUNT}.${tilesetId}`,
        url: credentials.url,
        name: sourceName,
      }),
    })
    if (result.ok) return result.json()
    console.error(await result.json())
  } catch (error) {
    console.error(error)
  }
  return null
}

const upload = async (
  file: File,
  phaseId: string,
  tilesetId: string,
  sourceName: string,
  setUploadProgress: (loaded: number, total: number) => void,
): Promise<IResponse> => {
  const token = await getMapboxToken({ phaseId })
  if (!token) return { success: false, message: 'Failed to get authentication' }

  const creds = await getCredentials(token)
  if (!creds) return { success: false, message: 'Failed to get credentials' }

  const uploadResult = await putFileOnS3(file, creds, setUploadProgress)
  if (!uploadResult) return { success: false, message: 'Failed to upload tileset' }

  const createResult = await createUpload(creds, token, tilesetId, sourceName)
  if (!createResult) return { success: false, message: 'Failed to publish tileset' }

  return { success: true, message: createResult.id }
}

export default upload
