import { LngLat } from 'mapbox-gl'
import React, { FC, useEffect, useState } from 'react'
import { Loader, Button } from '@aurecon-creative-technologies/styleguide'
import { mapboxAccessToken, googleMapsApiKey } from '../config/config'
import { useRecoilValue } from 'recoil'
import { RightDrawerOpen } from '../stores/uiStore'

import Style from '../styles/Streetview.module.sass'

const ReactStreetView = require('react-streetview')

interface IStreetViewProps {
  position?: LngLat
  close: () => void
}

const streetViewPanoramaOptions = {
  pov: { heading: 100, pitch: 0 },
  zoom: 1,
}

const StreetView: FC<IStreetViewProps> = (props) => {
  const [address, setAddress] = useState<string | null>(null)
  const [imgURL, setImgURL] = useState<string | null>(null)
  const [unavailable, setUnavailable] = useState(false)
  const [streetViewOpen, setStreetViewOpen] = useState(false)
  const drawerOpen = useRecoilValue(RightDrawerOpen)

  useEffect(() => {
    const getStreetAddress = async (position: LngLat) => {
      try {
        const res = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${position.lng},${position.lat}.json?access_token=${mapboxAccessToken}`,
        )
        if (res.ok) {
          const data = await res.json()
          //eslint-disable-next-line
          const matches = data.features.filter((place: any) => place.id.startsWith('address'))
          if (matches.length > 0) {
            setAddress(matches[0].place_name)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const getStreetView = async (position: LngLat) => {
      try {
        const res = await fetch(
          `https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=${position.lat || ''},${
            position.lng || ''
          }&fov=90&heading=235&pitch=10&key=${googleMapsApiKey}`,
        )
        if (res.ok) {
          const data = await res.json()
          if (data.status === 'OK') {
            setImgURL(
              `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${position.lat || ''},${
                position.lng || ''
              }&fov=90&heading=235&pitch=10&key=${googleMapsApiKey}`,
            )
          } else {
            setUnavailable(true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    setAddress(null)
    setImgURL(null)
    setUnavailable(false)
    setStreetViewOpen(false)

    if (props.position) {
      getStreetAddress(props.position)
      getStreetView(props.position)
    }
  }, [props.position])

  return (
    <>
      <div
        className={`${Style.streetViewPreview} ${props.position ? Style.active : ''} ${
          drawerOpen ? Style.drawerOpen : ''
        }`}
      >
        {unavailable && <p style={{ marginBottom: 0 }}>Street view unavailable at this location</p>}
        {!unavailable && address === null && <Loader type='circle' size='small' />}
        {!unavailable && address !== null && (
          <>
            {imgURL && <img src={imgURL} alt='Street View preview' onClick={() => setStreetViewOpen(true)} />}
            {address && (
              <div>
                <p>{address}</p>
                <p>{`${(props.position?.lat || '').toString().slice(0, 10)}, ${(props.position?.lng || '')
                  .toString()
                  .slice(0, 10)}`}</p>
              </div>
            )}
          </>
        )}
        <Button cssClass={Style.closeButton} icon='close' type='secondary' onClick={props.close} />
      </div>
      {streetViewOpen && (
        <div className={Style.streetViewPopup}>
          <ReactStreetView
            apiKey={googleMapsApiKey}
            streetViewPanoramaOptions={{
              ...streetViewPanoramaOptions,
              position: {
                lat: props.position?.lat,
                lng: props.position?.lng,
              },
            }}
          />
          <Button cssClass={Style.closeButton} icon='close' default={true} type='icon-square' onClick={props.close} />
        </div>
      )}
    </>
  )
}

export default StreetView
