import { Button, Icon, Pill, useToast } from '@aurecon-creative-technologies/styleguide'
import React, { FC } from 'react'
import Style from '../styles/AddEditComment.module.sass'
import AttachStyle from '../styles/FileUpload.module.sass'
import { unixDateTimeToString } from '../helpers/utils'
import { IComment } from '../api/comments'
import { IProjectCategory } from '../api/categories'
import { ReactComponent as ReplyIcon } from '../assets/reply.svg'
import { ASSIGNED_COLOUR, UNASSIGNED_COLOUR } from './CommentsTab'
import { getAttachment } from '../api/storage'
import { CommentVisibilityEnum, CommentVisibilityLabels } from '../enums/CommentsEnum'

interface ISideCommentProps {
  phaseId: string
  categoryList: IProjectCategory[] | null
  comment: IComment

  displayActions: boolean
  canEdit: boolean

  onEdit: (id: string) => void
  onCancel: () => void
}

const SideComment: FC<ISideCommentProps> = (props) => {
  const { addToast } = useToast()

  const date = unixDateTimeToString(props.comment.createdAt)
  const existingCommentCategory = props.categoryList?.filter((cat) => cat.id === props.comment.categoryId)[0]
  const isTopLevel = !props.comment.threadId

  const onAttachmentClick = async (location: string) => {
    const url = await getAttachment({ location, phaseId: props.phaseId, commentId: props.comment.commentId })

    if (!url) {
      addToast({
        type: 'error',
        message: 'Failed to fetch the file',
        timeout: 4000,
      })
      return
    }

    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = location
    document.body.appendChild(link)
    link.click()

    // Clean up the temporary elements and URL
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <div>
      <p className={Style.dateIcon}>
        {isTopLevel && <Icon type='chat' />}
        {!isTopLevel && <ReplyIcon />}
        {date}
      </p>
      <div className={isTopLevel ? '' : Style.replyComment}>
        <div className={Style.title}>
          <h2>{props.comment.authorName}</h2>
          {props.canEdit && (
            <span>
              <Button
                label='Edit'
                type='secondary'
                size='small'
                onClick={() => props.onEdit(props.comment.commentId)}
                disabled={props.comment.archived}
              />
            </span>
          )}
        </div>
        <p className={Style.medText}>{props.comment.title}</p>
        <p className={Style.medText}>{props.comment.content}</p>
        <p className={Style.smallText}>
          {props.comment.assigneeName ? `Assigned to: ${props.comment.assigneeName}` : 'Not assigned'}
        </p>
        {existingCommentCategory && (
          <p className={Style.smallText}>
            Category:{' '}
            <Pill colour={existingCommentCategory.colour} size='small'>
              {existingCommentCategory.categoryName}
            </Pill>
          </p>
        )}
        <p className={Style.smallText}>
          Status:{' '}
          <Pill colour={props.comment.assignee ? ASSIGNED_COLOUR : UNASSIGNED_COLOUR} size='small'>
            {props.comment.assignee ? 'Assigned' : 'Unassigned'}
          </Pill>
        </p>
        <p className={Style.smallText}>
          Visibility:{' '}
          {props.comment.onlyVisibleToOwners
            ? CommentVisibilityLabels[CommentVisibilityEnum.INTERNAL]
            : CommentVisibilityLabels[CommentVisibilityEnum.PUBLIC]}
        </p>
        {!!props.comment.attachments.length && (
          <div className={AttachStyle.attachments}>
            <div>
              <Icon type='attach_file' />
              <span>{props.comment.attachments.length}</span>
            </div>
            <div>
              {props.comment.attachments.map((file) => (
                <Button
                  key={file}
                  type='text'
                  onClick={(e) => {
                    const button = e.currentTarget as HTMLButtonElement
                    button.disabled = true
                    onAttachmentClick(file).finally(() => (button.disabled = false))
                  }}
                  label={file.split('/').slice(-1)[0]}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {props.displayActions && (
        <div className={Style.drawerActions}>
          <Button label='Edit' onClick={() => props.onEdit(props.comment.commentId)} />
          <Button label='Done' type='secondary' onClick={props.onCancel} />
        </div>
      )}
    </div>
  )
}

export default SideComment
