import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilValueLoadable,
  useRecoilStateLoadable,
  useRecoilState,
} from 'recoil'
import { ProjectDetails } from '../stores/projectStore'
import createPlotlyComponent from 'react-plotly.js/factory'
import { NavFrameHeight } from '../stores/uiStore'
import { HighlightIndex, VideoPlaying, SectionSize, Sections } from '../stores/sectionStore'
import { LayerGroups, SelectedLayerId, LayerState } from '../stores/layerStore'
import { Switcher } from '@aurecon-creative-technologies/styleguide'
import { MIN_CHAINAGE } from '../config/config'
import { LayerGroupTypeEnum } from '../enums/LayerGroupTypeEnum'
import useResizeObserver from 'use-resize-observer'

import Style from '../styles/Section.module.sass'

const Plotly = require('plotly.js-basic-dist')

const PADDING = { x: 100, y: 185 }
const Plot = createPlotlyComponent(Plotly)

const initialLayout: Partial<Plotly.Layout> = {
  autosize: true,
  margin: {
    t: 10,
    l: 50,
    r: 40,
    b: 60,
  },
  xaxis: {
    fixedrange: true,
    title: 'Chainage',
    nticks: 10,
    exponentformat: 'none',
    ticksuffix: 'm',
    range: [0, 10],
  },
  yaxis: {
    fixedrange: true,
    title: 'Height',
    ticksuffix: 'm',
    range: [-130, 130],
  },
  shapes: [],
  annotations: [],
}

const config: Partial<Plotly.Config> = {
  displayModeBar: false,
  responsive: true,
}

const Section: FC = () => {
  const details = useRecoilValue(ProjectDetails)
  const frameHeight = useRecoilValue(NavFrameHeight)
  const layerGroups = useRecoilValueLoadable(LayerGroups)
  const sections = useRecoilValueLoadable(Sections)
  const [selectedLayerId, setSelectedLayerId] = useRecoilStateLoadable(SelectedLayerId)
  const [sectionSize, setSectionSize] = useRecoilStateLoadable(SectionSize)
  const [mainIndex, setMainIndex] = useState(0)
  const obs = useResizeObserver<HTMLDivElement>()
  const setLayersState = useSetRecoilState(LayerState)
  const [layout, setLayout] = useState<Partial<Plotly.Layout>>({
    ...initialLayout,
    width: window.innerWidth - PADDING.x,
    height: frameHeight - PADDING.y,
  })
  const setHighlightIndex = useSetRecoilState(HighlightIndex)
  const [videoPlaying, setVideoPlaying] = useRecoilState(VideoPlaying)

  const onHover = (e: Plotly.PlotMouseEvent) => {
    // Have to iterate through these as the index changes depending on y-value
    // I.E. curveNumber != i
    for (let i = 0; i < e.points.length; i++)
      if (e.points[i].curveNumber === mainIndex) {
        setHighlightIndex({ index: e.points[i].pointIndex, seek: true })
        break
      }
  }

  useLayoutEffect(() => {
    const onResize = () => setLayout({ ...layout, width: window.innerWidth - PADDING.x })
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [layout])

  useEffect(() => {
    setLayout((layout) => {
      return { ...layout, height: obs.height }
    })
  }, [obs.height])

  useLayoutEffect(() => {
    if (sectionSize.state !== 'hasValue' || sections.state !== 'hasValue') return

    const main = sections.contents.find((section) => section.isMain)
    if (!main) return

    const coords = main.data.features[0].geometry.coordinates
    let [min, max] = sectionSize.contents
    if (max >= coords.length) {
      max = coords.length - 1
      if (min >= coords.length) {
        min = 0
      }
    }

    if (main.direction === 1) {
      const temp = min
      min = max
      max = temp
    }

    setLayout((layout) => {
      layout.xaxis = { ...layout.xaxis, range: [coords[min][3], coords[max][3]] }
      return { ...layout }
    })
  }, [sections, sectionSize])

  const sectionLayers = useMemo(() => {
    if (layerGroups.state === 'hasValue' && layerGroups.contents && layerGroups.contents.length > 0) {
      return layerGroups.contents
        .flatMap((group) => group.Layers)
        .filter((layer) => layer.Sections.length)
        .filter((layer, index, array) => array.findIndex((l) => l.layerId === layer.layerId) === index)
    }
    return []
  }, [layerGroups])

  const setActiveLayers = useCallback(
    (layerId: string) => {
      if (layerGroups.state === 'hasValue' && layerGroups.contents && layerGroups.contents.length) {
        const optionGroups = layerGroups.contents.filter(
          (group) =>
            group.groupType === LayerGroupTypeEnum.DESIGN_OPTION ||
            group.groupType === LayerGroupTypeEnum.DESIGN_OPTION_COMMON,
        )
        const optionLayers = optionGroups.flatMap((group) => group.Layers)

        if (sectionLayers.length) {
          const optionLayerStates = {}
          optionLayers.forEach((layer) => (optionLayerStates[layer.layerId] = layer.layerId === layerId))

          setLayersState((state) => {
            return {
              ...state,
              ...optionLayerStates,
            }
          })
        }
      }
    },
    [layerGroups, sectionLayers, setLayersState],
  )

  useEffect(() => {
    if (selectedLayerId.state === 'hasValue' && selectedLayerId.contents) {
      setActiveLayers(selectedLayerId.contents)
    }
  }, [selectedLayerId, setActiveLayers])

  const sectionData = useMemo(() => {
    if (sections.state === 'hasValue') {
      return sections.contents.map((section, i) => {
        if (section.isMain) setMainIndex(i)
        return {
          name: section.sectionName,
          hovertemplate: '%{y:.3f}m',
          line: section.format ? JSON.parse(section.format) : undefined,
          x: section.data.features[0].geometry.coordinates.map((coords) => coords[3]),
          y: section.data.features[0].geometry.coordinates.map((coords) => coords[2]),
        } as Partial<Plotly.PlotData>
      })
    }

    return []
  }, [sections])

  useLayoutEffect(() => {
    if (sections.state !== 'hasValue') return

    const main = sections.contents.find((section) => section.isMain)
    if (!main) return

    const coords = main.data.features[0].geometry.coordinates
    let endIndex = coords.length
    for (let i = 0; i < coords.length; i++) {
      if (coords[i][3] > MIN_CHAINAGE) {
        endIndex = i
        break
      }
    }

    setSectionSize([0, endIndex])
    setHighlightIndex((hIndex) => {
      if (hIndex.index >= coords.length - 1) {
        return { index: coords.length - 2, seek: false }
      } else return hIndex
    })
  }, [selectedLayerId, sections, setSectionSize, setHighlightIndex])

  useLayoutEffect(() => {
    // Set chart y-axis range
    if (sectionSize.state !== 'hasValue') return
    let minHeight = 1000
    let maxHeight = -1000
    let height = 0
    const [startIndex, endIndex] = sectionSize.contents

    for (let i = startIndex; i <= endIndex; i++) {
      for (let j = 0; j < sectionData.length; j++) {
        height = Number(sectionData[j].y![i])
        minHeight = Math.min(minHeight, height)
        maxHeight = Math.max(maxHeight, height)
      }
    }

    setLayout((layout) => {
      layout.yaxis = { ...layout.yaxis, range: [minHeight - 1, maxHeight + 1] }
      return { ...layout }
    })
  }, [sectionData, sectionSize])

  const onChangeLayer = (layerId: string) => {
    setSelectedLayerId(layerId)
    setActiveLayers(layerId)
  }

  return (
    <div className={Style.content}>
      <h3>{details && `${details.title} / ${details.phaseName}`}</h3>
      {sectionLayers.length > 0 && (
        <>
          <Switcher
            items={sectionLayers.map((layer) => {
              return { id: layer.layerId, label: layer.layerName }
            })}
            size='small'
            default
            selectedItem={selectedLayerId.state === 'hasValue' ? selectedLayerId.contents : ''}
            onSelectItem={(item) => onChangeLayer(item.toString())}
          />
          <div className={Style.chartHolder} ref={obs.ref}>
            <Plot
              data={sectionData}
              layout={layout}
              config={config}
              onHover={onHover}
              onClick={() => setVideoPlaying(!videoPlaying)}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Section
