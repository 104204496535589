import React, { FC } from 'react'
import Style from '../styles/CommentTile.module.sass'
import { IComment } from '../api/comments'
import { SelectedCommentId } from '../stores/commentStore'
import { unixDateToString } from '../helpers/utils'
import { Icon, Pill } from '@aurecon-creative-technologies/styleguide'
import { CategoryNameColour, ASSIGNED_COLOUR, UNASSIGNED_COLOUR } from './CommentsTab'
import { ReactComponent as ReplyIcon } from '../assets/reply.svg'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useHandleDrawer } from '../stores/uiStore'
import { getMap } from './HybridMap/HybridMap'
import ArchiveButton from './ArchiveButton'
import { ProjectDetails } from '../stores/projectStore'
import { isGlobalAdmin, isProjectAdmin } from '../helpers/appRoles'
import { useAuth0 } from '@auth0/auth0-react'
import classNames from 'classnames'
interface ICommentTile {
  comment: IComment
  comments: IComment[]
  categoryList: CategoryNameColour
}

const CommentTile: FC<ICommentTile> = (props) => {
  const setSelectedCommentId = useSetRecoilState(SelectedCommentId)
  const handleDrawer = useHandleDrawer()
  const { user } = useAuth0()
  const details = useRecoilValue(ProjectDetails)

  const date = unixDateToString(props.comment.createdAt)
  const category = props.comment.categoryId ? props.categoryList[props.comment.categoryId] : null
  const replyCount = props.comments.filter((otherComment) => otherComment.threadId === props.comment.commentId).length

  const onCommentClick = () => {
    setSelectedCommentId(props.comment.commentId)
    handleDrawer(true)
    getMap()?.flyTo({ center: props.comment, zoom: props.comment.zoom })
  }

  const showArchiveButton = isProjectAdmin(details) || isGlobalAdmin(user)

  return (
    <div className={classNames(Style.commentTile, { [Style.archived]: props.comment.archived })}>
      <div className={Style.commentTileContent} onClick={onCommentClick}>
        <p className={Style.dateIcon}>
          <Icon type='chat' />
          <span>{date}</span>
          {replyCount > 0 && (
            <>
              <ReplyIcon />
              <span>{replyCount}</span>
            </>
          )}
          {props.comment.attachments.length > 0 && (
            <>
              <Icon type='attach_file' className={Style.attach} />
              <span>{props.comment.attachments.length}</span>
            </>
          )}
        </p>
        <h2>{props.comment.authorName}</h2>
        <p className={Style.title}>{props.comment.title}</p>
        <p className={Style.smallText}>Assigned to: {props.comment.assigneeName || 'Not assigned'}</p>
        {category && (
          <p className={Style.smallText}>
            Category:{' '}
            <Pill colour={category.colour} size='small'>
              {category.name}
            </Pill>
          </p>
        )}
        <p className={Style.smallText}>
          Status:{' '}
          <Pill colour={props.comment.assignee ? ASSIGNED_COLOUR : UNASSIGNED_COLOUR} size='small'>
            {props.comment.assignee ? 'Assigned' : 'Unassigned'}
          </Pill>
        </p>
      </div>
      {showArchiveButton && (
        <div className={Style.commentTileArchive}>
          <ArchiveButton comment={props.comment} size='medium' />
        </div>
      )}
    </div>
  )
}

export default CommentTile
