import React, { FC, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { ProjectDetails, ProjectCategories } from '../stores/projectStore'
import { FilteredComments, ShowCommentOption, useRefreshComments } from '../stores/commentStore'
import { Grid, Loader, Radio, Switcher } from '@aurecon-creative-technologies/styleguide'

import CommentsPills from './CommentsPills'
import CommentsList from './CommentsList'
import CommentTile from './CommentTile'
import CommentsFilters from './CommentsFilters'
import DownloadComments from './DownloadComments'

import { categoryColours } from '../api/categories'

import Style from '../styles/CommentsMenu.module.sass'
import { isGlobalAdmin, isProjectAdmin } from '../helpers/appRoles'
import { useAuth0 } from '@auth0/auth0-react'
import { ArchivedCommentEnum, ArchivedCommentEnumOptions } from '../enums/ArchivedCommentEnum'

type ViewType = 'tiles' | 'list'

export type CategoryNameColour = {
  [key: string]: {
    colour: categoryColours
    name: string
  }
}

export const ASSIGNED_COLOUR = 14
export const UNASSIGNED_COLOUR = 15

const CommentsTab: FC = () => {
  const details = useRecoilValue(ProjectDetails)
  const comments = useRecoilValueLoadable(FilteredComments)
  const categories = useRecoilValueLoadable(ProjectCategories)
  const [viewMode, setViewMode] = useState<ViewType>('list')
  const [showCommentOption, setShowCommentOption] = useRecoilState(ShowCommentOption)
  const { user } = useAuth0()
  const refreshComments = useRefreshComments()

  const categoryNamesAndColours = useMemo(() => {
    const namesAndColours: CategoryNameColour = {}

    if (categories.state === 'hasValue' && categories.contents) {
      categories.contents.forEach((category) => {
        if (category.id)
          namesAndColours[category.id] = {
            colour: category.colour,
            name: category.categoryName,
          }
      })
    }

    return namesAndColours
  }, [categories])

  const handleChangeArchivedComments = (value: string) => {
    setShowCommentOption(value === ArchivedCommentEnum.ONLY_ARCHIVED.toString() ? 'archived' : 'all')
    refreshComments()
  }

  const renderShowArchivedCheckbox = () => {
    if (!isProjectAdmin(details) && !isGlobalAdmin(user)) return null

    return (
      <div className={Style.radioButton}>
        <Radio
          items={ArchivedCommentEnumOptions}
          selectedItem={
            showCommentOption === 'archived'
              ? ArchivedCommentEnum.ONLY_ARCHIVED.toString()
              : ArchivedCommentEnum.ALL.toString()
          }
          onItemSelect={handleChangeArchivedComments}
          horizontal
          labelInline
        />
      </div>
    )
  }

  return (
    <div className={Style.comments}>
      <div className={Style.sticky}>
        <div className={Style.title}>
          <h3>{details && `${details.title} / ${details.phaseName}`}</h3>
          {renderShowArchivedCheckbox()}
        </div>
        <div className={Style.controls}>
          <CommentsFilters />
          <DownloadComments comments={comments} categoryList={categoryNamesAndColours} />
          <Switcher
            default={true}
            size='small'
            items={[
              { id: 'tiles', icon: 'apps', title: 'Tile view' },
              { id: 'list', icon: 'list', title: 'List view' },
            ]}
            selectedItem={viewMode}
            onSelectItem={(item) => setViewMode(item as ViewType)}
          />
        </div>
        <CommentsPills />
      </div>

      <div className={Style.contents}>
        {comments.state === 'loading' && <Loader label='Loading comments...' />}
        {comments.state === 'hasValue' && comments.contents && (
          <>
            {viewMode === 'tiles' && (
              <Grid row={true} gap={10}>
                {comments.contents
                  .filter((comment) => !comment.threadId)
                  .map((comment) => (
                    <Grid key={comment.commentId} item sm={3}>
                      <CommentTile
                        comment={comment}
                        comments={comments.contents}
                        categoryList={categoryNamesAndColours}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
            {viewMode === 'list' && (
              <CommentsList comments={comments.contents} categoryList={categoryNamesAndColours} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default CommentsTab
