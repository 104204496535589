import React, { FC, useEffect, useRef, useState } from 'react'
import { Layer, LngLatBoundsLike, Map, MapMouseEvent, PaddingOptions } from 'mapbox-gl'
import { mapboxAccessToken } from '../../config/config'
import { useSetRecoilState } from 'recoil'
import { Bearing, Zoom } from './../../stores/mapStore'
import { randomNumber } from '../../helpers/utils'

let map: Map | null = null

const MAP_ID = `map_${randomNumber().toString().slice(2, 7)}`

interface IMapboxProps {
  width: string
  height: string
  mapStyle?: string
  bounds?: LngLatBoundsLike
  padding?: PaddingOptions
  dragPan?: boolean
  cursor?: string
  onLoad?: () => void
  onClick?: (e: MapMouseEvent) => void
  onRightClick?: (e: MapMouseEvent) => void
  onMouseDown?: (e: MapMouseEvent) => void
  onMouseUp?: (e: MapMouseEvent) => void
  onMouseMove?: (e: MapMouseEvent) => void
}

export const HybridMap: FC<IMapboxProps> = (props) => {
  const {
    width,
    height,
    mapStyle,
    dragPan,
    cursor,
    bounds,
    padding,
    onLoad,
    onClick,
    onRightClick,
    onMouseDown,
    onMouseUp,
    onMouseMove,
  } = props
  const [loaded, setLoaded] = useState(false)
  const [dragging, setDragging] = useState(false)
  const setBearing = useSetRecoilState(Bearing)
  const setZoom = useSetRecoilState(Zoom)
  const firstStyle = useRef(true)

  useEffect(() => {
    if (map) return

    map = new Map({
      container: MAP_ID,
      accessToken: mapboxAccessToken,
      style: mapStyle || 'mapbox://styles/mapbox/streets-v11',
      bounds,
      pitchWithRotate: false,
      fitBoundsOptions: { padding },
    })

    map.on('load', () => {
      setLoaded(true)
      if (onLoad) onLoad()
    })
    map.on('mousedown', () => setDragging(true))
    map.on('mouseup', () => setDragging(false))
  })

  useEffect(() => {
    const asyncEffect = async () => {
      if (!mapStyle) return

      if (firstStyle.current) {
        firstStyle.current = false
        return
      }

      const currentStyle = map!.getStyle()
      const res = await fetch(
        `https://api.mapbox.com/styles/v1/${mapStyle.replace(
          'mapbox://styles/',
          '',
        )}?access_token=${mapboxAccessToken}`,
      )
      if (!res.ok) return
      const newStyle = await res.json()

      newStyle.sources = { ...currentStyle.sources, ...newStyle.sources }

      const appLayers = currentStyle.layers!.filter(
        (el: Layer) => el.source && !(el.source as string).startsWith('mapbox') && el.source != 'composite',
      )

      newStyle.layers = [...newStyle.layers, ...appLayers]

      map!.setStyle(newStyle)
    }
    if (loaded) asyncEffect()
  }, [mapStyle, loaded])

  useEffect(() => {
    const onZoom = () => setZoom(map!.getZoom() || 0)
    map!.on('zoom', onZoom)
    onZoom()
    return () => {
      map!.off('zoom', onZoom)
    }
  }, [setZoom])

  useEffect(() => {
    const onBearing = () => setBearing(map!.getBearing() || 0)
    map!.on('rotate', onBearing)
    onBearing()
    return () => {
      map!.off('rotate', onBearing)
    }
  }, [setBearing])

  useEffect(() => {
    if (onClick) map?.on('click', onClick)
    return () => {
      if (onClick) map?.off('click', onClick)
    }
  }, [onClick])

  useEffect(() => {
    if (onRightClick) map?.on('contextmenu', onRightClick)
    return () => {
      if (onRightClick) map?.off('contextmenu', onRightClick)
    }
  }, [onRightClick])

  useEffect(() => {
    if (onMouseDown) map?.on('mousedown', onMouseDown)
    return () => {
      if (onMouseDown) map?.off('mousedown', onMouseDown)
    }
  }, [onMouseDown])

  useEffect(() => {
    if (onMouseUp) map?.on('mouseup', onMouseUp)
    return () => {
      if (onMouseUp) map?.off('mouseup', onMouseUp)
    }
  }, [onMouseUp])

  useEffect(() => {
    if (onMouseMove) map?.on('mousemove', onMouseMove)
    return () => {
      if (onMouseMove) map?.off('mousemove', onMouseMove)
    }
  }, [onMouseMove])

  useEffect(() => {
    if (dragPan === true || dragPan === undefined) map!.dragPan.enable()
    else map!.dragPan.disable()
  }, [dragPan])

  useEffect(() => {
    map!.getCanvas().style.cursor = cursor || (dragging ? 'grabbing' : 'grab')
  }, [dragging, cursor])

  useEffect(() => {
    return () => {
      map!.remove()
      map = null
    }
  }, [])

  return (
    <div id={MAP_ID} style={{ width: width, height: height }}>
      {loaded ? props.children : null}
    </div>
  )
}

export const getMap = (): Map | null => map
