import React, { FC, useEffect, useMemo, useState } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Button, Container, FormInput, Grid, useToast } from '@aurecon-creative-technologies/styleguide'
import Page from '../components/Page'
import { patchUser } from '../api/users'
import { IPatchUserProps } from '../api/apiprops'
import { isAureconUser } from '../helpers/utils'

import Style from '../styles/Profile.module.sass'

function getPasswordErrorIfAny(password: string) {
  if (password.length < 8) return 'Must be at least 8 characters'
  const hasLowerCase = /[a-z]/.test(password)
  const hasUpperCase = /[A-Z]/.test(password)
  const hasNumber = /[0-9]/.test(password)
  const hasSpecial = password.replace(/[^\W]/g, '').length > 0

  const fails = (hasLowerCase ? 0 : 1) + (hasUpperCase ? 0 : 1) + (hasNumber ? 0 : 1) + (hasSpecial ? 0 : 1)

  if (fails > 1) {
    return 'Must include at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).'
  }

  // Success
  return ''
}

const Profile: FC = () => {
  const toasts = useToast()

  const { user } = useAuth0()

  const [name, setName] = useState({ value: '', error: '' })
  const [oldPassword, setOldPassword] = useState({ value: '', error: '' })
  const [newPassword, setNewPassword] = useState({ value: '', error: '' })
  const [confirmNewPassword, setConfirmNewPassword] = useState({ value: '', error: '' })

  const [editingPassword, setEditingPassword] = useState(false)
  const editingName = name.value !== user.name

  const nameReady = editingName && name.value.length > 0 && name.value.length <= 40
  const passwordReady =
    oldPassword.value &&
    newPassword.value &&
    !getPasswordErrorIfAny(newPassword.value) &&
    newPassword.value === confirmNewPassword.value

  const canCancel = editingPassword || editingName
  const canSave = (nameReady && !editingPassword) || (passwordReady && !editingName) || (passwordReady && nameReady)

  useEffect(() => {
    setName({ value: user.name, error: '' })
  }, [user])

  const updateNewPassword = (password: string) => {
    const passwordError = getPasswordErrorIfAny(password)
    setNewPassword({ value: password, error: passwordError })
    if (confirmNewPassword.value !== '' && password !== confirmNewPassword.value) {
      setConfirmNewPassword((v) => {
        return { ...v, error: 'Passwords do not match' }
      })
    }
  }

  const updateConfirmNewPassword = (password: string) => {
    setConfirmNewPassword({ value: password, error: '' })
    if (confirmNewPassword.value !== '' && newPassword.value !== password) {
      setConfirmNewPassword((v) => {
        return { ...v, error: 'Passwords do not match' }
      })
    }
  }

  const updateName = (text: string) => {
    setName({
      value: text,
      error: !text.length ? 'You must enter a name' : text.length > 40 ? 'Name is too long (40 characters max)' : '',
    })
  }

  const clearPasswordFields = () => {
    setOldPassword({ value: '', error: '' })
    setNewPassword({ value: '', error: '' })
    setConfirmNewPassword({ value: '', error: '' })
    setEditingPassword(false)
  }

  const cancel = () => {
    setName({ value: user.name, error: '' })
    clearPasswordFields()
  }

  const save = async () => {
    const patch: IPatchUserProps = {}
    if (nameReady) {
      patch.name = name.value
    }
    if (passwordReady) {
      patch.password = newPassword.value
    }

    const patchResponse = await patchUser(patch)
    if (patchResponse.success) {
      // TODO refresh the useAuth0 user token so we can have a new name across the app
      toasts.addToast({
        type: 'success',
        message: 'Details successfully updated. Refresh all open pages to view the change.',
        timeout: 5000,
      })

      clearPasswordFields()
    } else {
      toasts.addToast({
        type: 'error',
        message: patchResponse.message || 'Server error',
        timeout: 3000,
      })
    }
  }

  const userIsAurecon = useMemo(() => {
    const email = user.email as string
    return isAureconUser(email)
  }, [user.email])

  return (
    <Page background>
      <Container>
        <Grid row>
          <Grid xs={12} lg={4} />
          <Grid xs={12} lg={4}>
            <div className={Style['wrapper']}>
              <h1>My Account Details</h1>

              <FormInput
                type='text'
                label='Full Name'
                onChange={(v) => updateName(v)}
                value={name.value}
                error={name.error}
                default
                readonly={userIsAurecon}
              />

              <FormInput
                type='text'
                label='Email'
                onChange={() => {
                  return
                }}
                value={user.email}
                default
                readonly
              />

              {editingPassword && (
                <>
                  <FormInput
                    type='password'
                    label='Old Password'
                    onChange={(v) => setOldPassword({ value: v, error: '' })}
                    value={oldPassword.value}
                    default
                  />

                  <FormInput
                    type='password'
                    label='New Password'
                    onChange={(v) => updateNewPassword(v)}
                    value={newPassword.value}
                    error={newPassword.error}
                    default
                  />

                  <FormInput
                    type='password'
                    label='Confirm New Password'
                    onChange={(v) => updateConfirmNewPassword(v)}
                    value={confirmNewPassword.value}
                    error={confirmNewPassword.error}
                    default
                  />
                </>
              )}

              {!userIsAurecon && !editingPassword && (
                <div className={Style['change-password-wrapper']}>
                  <Button label='Change Password' default onClick={() => setEditingPassword(true)} />
                </div>
              )}

              {!userIsAurecon && (
                <div className={Style['buttons']}>
                  <Button type='secondary' label='Cancel' onClick={cancel} disabled={!canCancel} flexible />
                  <Button label='Save' onClick={save} disabled={!canSave} flexible />
                </div>
              )}

              {userIsAurecon && (
                <p>These details are linked to your Aurecon account, and cannot be changed through Align.</p>
              )}
            </div>
          </Grid>
          <Grid xs={12} lg={4} />
        </Grid>
      </Container>
    </Page>
  )
}

const AuthenticationCheck = withAuthenticationRequired(Profile, {
  onRedirecting: () => <p>Redirecting...</p>,
})

AuthenticationCheck.displayName = 'Authentication Check'

export default AuthenticationCheck
