import React, { ReactNode, useCallback } from 'react'
import { atom, selector, useSetRecoilState } from 'recoil'
import CommentsTab from '../components/CommentsTab'
import DesignOptionsTab from '../components/DesignOptionsTab'
import GISLayersTab from '../components/GISLayersTab'
import Section from '../components/Section'
import { NewCommentLocation, SelectedCommentId } from './commentStore'
import { SelectedExternalData } from './externalDataStore'
import { Measuring } from './measureStore'

export const NAV_FRAME_HEIGHT_RATIO = 0.5
export const FIT_BOUNDS_PADDING = 100
export const RIGHT_DRAWER_WIDTH = 368

export const NavFrameCollapsed = atom({
  key: 'navFrameCollapsed',
  default: false,
})

export const RightDrawerOpen = atom({
  key: 'rightDrawerOpen',
  default: false,
})

export const NavFrameHeight = atom({
  key: 'navframeHeight',
  default: window.innerHeight * NAV_FRAME_HEIGHT_RATIO,
})

export const useHandleDrawer = (): ((open: boolean) => void) => {
  const setSelectedCommentId = useSetRecoilState(SelectedCommentId)
  const setSelectedExternalData = useSetRecoilState(SelectedExternalData)
  const setNewCommentLocation = useSetRecoilState(NewCommentLocation)
  const setMeasuring = useSetRecoilState(Measuring)
  const setDrawerOpen = useSetRecoilState(RightDrawerOpen)
  const setNavFrameCollapsed = useSetRecoilState(NavFrameCollapsed)

  return useCallback(
    (open: boolean) => {
      setDrawerOpen(open)
      setNavFrameCollapsed(open)
      if (!open) {
        setTimeout(() => {
          setSelectedCommentId(null)
          setNewCommentLocation(null)
          setSelectedExternalData(null)
          setMeasuring(false)
        }, 600)
      }
    },
    [
      setSelectedCommentId,
      setNewCommentLocation,
      setSelectedExternalData,
      setMeasuring,
      setDrawerOpen,
      setNavFrameCollapsed,
    ],
  )
}

export interface ITab {
  name: string
  icon: string
  content: ReactNode
  title?: string
}

export const tabs: ITab[] = [
  {
    name: 'Design Options',
    icon: 'architecture',
    content: <DesignOptionsTab />,
    title: 'Design options',
  },
  {
    name: 'Section',
    icon: 'power_input',
    content: <Section />,
    title: 'Section',
  },
  {
    name: 'GIS Layers',
    icon: 'layers',
    content: <GISLayersTab />,
    title: 'GIS layers',
  },
  {
    name: 'Comments',
    icon: 'message',
    content: <CommentsTab />,
    title: 'Comments',
  },
]

export const NavFrameTab = atom({
  key: 'navFrameTab',
  default: tabs[0],
})

export const TermsModal = atom({
  key: 'termsModal',
  default: false,
})

export const BoundsPadding = selector({
  key: 'boundsPadding',
  get: ({ get }) => {
    const navFrameHeight = get(NavFrameHeight)
    const rightDrawerOpen = get(RightDrawerOpen)
    return {
      top: FIT_BOUNDS_PADDING / 2,
      bottom: navFrameHeight,
      left: FIT_BOUNDS_PADDING,
      right: rightDrawerOpen ? RIGHT_DRAWER_WIDTH + FIT_BOUNDS_PADDING : FIT_BOUNDS_PADDING,
    }
  },
})
