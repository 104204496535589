import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'

import TopMenu from '../components/TopMenu'
import { TermsModal } from '../stores/uiStore'

import { ReactComponent as TopographyBackground } from '../assets/topography.svg'

import Style from '../styles/Page.module.sass'

export interface IPageProps {
  noMenu?: boolean
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  background?: boolean
}

const Page: FC<IPageProps> = (props) => {
  const termsModal = useRecoilValue(TermsModal)

  const noMenu = props.noMenu || termsModal

  return (
    <div className={Style.page} style={{ marginBottom: termsModal ? '200px' : '0' }}>
      {props.background && (
        <div className={Style.bg}>
          <TopographyBackground />
        </div>
      )}
      {!noMenu && <TopMenu />}
      {props.children}
    </div>
  )
}

export default Page
