export const mapboxAccessToken =
  'pk.eyJ1IjoidW5zaWduZWQtY3NlIiwiYSI6ImNrZm94cXEzNTAyNzcyeHFsM211aHFocDAifQ.ptLi3dNSgKAYvcdWJMwRYw'

export const googleMapsApiKey = 'AIzaSyABirClrmhghThLgRUTN5MO5UUoJfgYyu0'

export const MIN_CHAINAGE = 100

export const MAX_FILE_SIZE = 100 * 1024 * 1024 // 100 MB

export const MAX_AZURE_FILE_SIZE = 2 * 1024 * 1024 // 2 MB

export const MAX_JSON_FILE_SIZE = 1024 * 1024 * 1024 // 1 GB

export const MAX_FILE_NAME = 80

export const TRANSITION_DURATION = 1000

export const SUPPORT_EMAIL = 'align.support@aurecongroup.com'

export const JIRA_SUPPORT_URL = 'https://quartileone.atlassian.net/servicedesk/customer/portal/9'

export const MAPBOX_ACCOUNT = 'unsigned-cse'

const config = {
  AUTH0_DOMAIN: 'auth.aurecongroup.digital',
  AUTH0_CLIENT_ID: 've66rNlOYKX0MEeFY199S2N0ob9EiZDm',
  AUTH0_AUDIENCE: 'https://aureconalign.aurecongroup.digital',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'https://aurecongroup.com/roles',
  AUTH0_GLOBAL_ADMIN_ROLE: 'aureconAlign Global Admin',
}

export default config
