import React, { FC, useState, useEffect, useCallback, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Button, Grid, ISwitcherItemProps, Loader, Switcher } from '@aurecon-creative-technologies/styleguide'

import { ProjectDetails } from '../stores/projectStore'
import { LayerGroups, LayerState } from '../stores/layerStore'
import { LayerGroupTypeEnum } from '../enums/LayerGroupTypeEnum'
import { ILayer } from '../api/layers'
import LayerToggle from './LayerToggle'

import Style from '../styles/DesignOptionsTab.module.sass'

const DesignOptionsTab: FC = () => {
  const details = useRecoilValue(ProjectDetails)
  const layerGroups = useRecoilValueLoadable(LayerGroups)
  const [designGroups, setDesignGroups] = useState<ISwitcherItemProps[]>([])
  const [extraLayers, setExtraLayers] = useState<ILayer[]>([])
  const [commonLayers, setCommonLayers] = useState<ILayer[]>([])
  const [designGroupId, setDesignGroupId] = useState('')
  const [groupLayers, setGroupLayers] = useState<ILayer[]>([])
  const [allLayers, setAllLayers] = useState<ILayer[]>([])

  const [layersState, setLayersState] = useRecoilState(LayerState)

  useEffect(() => {
    if (layerGroups.state !== 'hasValue') return

    const filteredDesignGroups = layerGroups.contents.filter(
      (group) => group.groupType === LayerGroupTypeEnum.DESIGN_OPTION,
    )
    const prepDesignGroups = filteredDesignGroups.map((group) => {
      return {
        id: group.groupId,
        label: group.groupName,
      }
    })
    setDesignGroups(prepDesignGroups)
    setDesignGroupId(prepDesignGroups.length ? prepDesignGroups[0].id : '')

    const layers: ILayer[] = []
    filteredDesignGroups.forEach((group) => {
      group.Layers.forEach((layer) => layers.push(layer))
    })

    const filteredExtraGroups = layerGroups.contents.filter(
      (group) => group.groupType === LayerGroupTypeEnum.DESIGN_OPTION_EXTRA,
    )
    const prepExtraLayers: ILayer[] = []

    filteredExtraGroups.forEach((group) => {
      group.Layers.forEach((layer) => {
        prepExtraLayers.push(layer)
        layers.push(layer)
      })
    })

    setExtraLayers(prepExtraLayers)

    const filteredCommonGroups = layerGroups.contents.filter(
      (group) => group.groupType === LayerGroupTypeEnum.DESIGN_OPTION_COMMON,
    )
    const prepCommonLayers: ILayer[] = []

    filteredCommonGroups.forEach((group) => {
      group.Layers.forEach((layer) => {
        prepCommonLayers.push(layer)
        layers.push(layer)
      })
    })

    setCommonLayers(prepCommonLayers)
    setAllLayers(layers)
  }, [layerGroups, setDesignGroups, setDesignGroupId, setExtraLayers, setCommonLayers, setLayersState])

  useEffect(() => {
    if (layerGroups.state !== 'hasValue') return
    const group = layerGroups.contents.filter((g) => g.groupId === designGroupId)

    if (!group.length) return
    setGroupLayers(group[0].Layers)
  }, [layerGroups, designGroupId, setGroupLayers])

  const handleSetLayers = useCallback(
    (active: boolean, layer: string) => {
      setLayersState((state) => {
        return {
          ...state,
          [layer]: active,
        }
      })
    },
    [setLayersState],
  )

  const handleClearSelection = () => {
    const newState = {}
    allLayers.forEach((layer) => (newState[layer.layerId] = false))
    setLayersState((currentState) => {
      return {
        ...currentState,
        ...newState,
      }
    })
  }

  const activeLayers = useMemo(() => {
    return allLayers.some((layer) => layersState[layer.layerId])
  }, [allLayers, layersState])

  return (
    <div className={Style.designOptionsWrapper}>
      <div className={Style.contents}>
        <Grid row gap={12}>
          <Grid item md={8}>
            <h3>{details && `${details.title} / ${details.phaseName}`}</h3>
          </Grid>
          <Grid item md={4} style={{ justifyContent: 'flex-end', alignContent: 'center' }}>
            <Button
              type='text'
              onClick={handleClearSelection}
              label='Clear all selection'
              cssClass={activeLayers ? 'clear-button' : 'clear-button inactive'}
              icon='layers_clear'
            />
          </Grid>
        </Grid>
        {layerGroups.state === 'loading' && <Loader label='Loading design options...' />}
        {layerGroups.state === 'hasValue' && (
          <>
            <div className={Style.layers}>
              {extraLayers.map((layer) => {
                return (
                  <LayerToggle
                    key={layer.layerId}
                    layer={layer}
                    active={layersState[layer.layerId]}
                    onToggle={handleSetLayers}
                    noSquare={true}
                  />
                )
              })}
            </div>
            {designGroups.length > 1 && (
              <Grid row style={{ margin: '32px 0' }}>
                <Grid item>
                  <Switcher
                    items={designGroups}
                    onSelectItem={(id) => setDesignGroupId(id as string)}
                    selectedItem={designGroupId}
                    size='small'
                    default
                    cssClass='design-options'
                  />
                </Grid>
              </Grid>
            )}
            {designGroups.length <= 1 && extraLayers.length > 0 && <div className={Style.divider} />}
            <div className={Style.layers}>
              {[...groupLayers, ...commonLayers].map((layer) => (
                <LayerToggle
                  key={layer.layerId}
                  layer={layer}
                  active={layersState[layer.layerId]}
                  onToggle={handleSetLayers}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default DesignOptionsTab
