import { TokenExpiryWrapper, EmailScope } from './TokenManager'
import { headersWithToken } from '../helpers/utils'

export interface ISendGridResponse {
  success: boolean
  message: string
}

export interface IRequestAccess {
  phaseIdOrSlug: string
  name: string
  email: string
  reason: string
}

export interface IRequestAccessProps {
  token?: string
  request: IRequestAccess
}

const sendRequestAccessRequest = async (props: IRequestAccessProps): Promise<ISendGridResponse> => {
  const response = await fetch('/projects/requestAccess', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.request),
  })
  if (response.ok) return response.json()
  throw response
}
export const sendRequestAccess = TokenExpiryWrapper(sendRequestAccessRequest, [EmailScope], {
  success: false,
  message: 'Error sending the message',
})

export interface INotifyServiceDesk {
  name: string
  email: string
}

export interface INotifyServiceDeskProps {
  token?: string
  request: INotifyServiceDesk
}

const notifyServiceDeskRequest = async (props: INotifyServiceDeskProps): Promise<ISendGridResponse> => {
  const response = await fetch('/app/declinedTerms', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.request),
  })
  if (response.ok) return response.json()
  throw response
}
export const notifyServiceDesk = TokenExpiryWrapper(notifyServiceDeskRequest, [EmailScope], {
  success: false,
  message: 'Error sending the message',
})
