import React from 'react'
import { Modal, Button, Icon } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/ArchiveCommentModal.module.sass'

interface ArchiveCommentModalProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  isArchiving: boolean
}

const iconColor = '#1619c0'

const ArchiveCommentModal = ({ isOpen, onConfirm, onCancel, isArchiving }: ArchiveCommentModalProps) => {
  const handleCloseModal = () => {
    onCancel()
  }

  return (
    <Modal
      isShowing={isOpen}
      onClose={handleCloseModal}
      isCloseButton={false}
      isOverlay={true}
      cssClass='archiveModalWrapper'
    >
      <div className={Style.closeButtonArchiveModal}>
        <Icon type='close' onClick={handleCloseModal} colour={iconColor} />
      </div>
      <div className={Style.archiveModal}>
        <h2>{isArchiving ? 'Archive comment' : 'Restore comment'}</h2>
        <p>{`This will ${isArchiving ? 'archive' : 'restore'} the comment thread. Are you sure?`}</p>
        <div className={Style.archiveModalButtons}>
          <Button label='Cancel' type='secondary' onClick={onCancel} />
          <Button label='Yes' onClick={onConfirm} />
        </div>
      </div>
    </Modal>
  )
}

export default ArchiveCommentModal
