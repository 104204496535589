import React, { FC, ReactText, useCallback, useEffect, useState } from 'react'
import Style from '../styles/AddEditComment.module.sass'
import { addComment, IComment, ICommentAddProps, updateComment } from '../api/comments'
import { CommentState, EMPTY_COMMENT } from './AddEditComment'
import FileUpload from './FileUpload'
import { IProjectCategory } from '../api/categories'
import { CommentUploading, SelectedCommentId, useRefreshComments } from '../stores/commentStore'
import { FormInput, Dropdown, Checkbox, Icon, Button, useToast } from '@aurecon-creative-technologies/styleguide'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { useUploadAttachments } from '../api/storage'
import { truncateFileName } from '../helpers/utils'
import { ISelectedExternalDataPoint } from '../stores/externalDataStore'
import { useFormValidation } from '../validators/useFormValidation'
import { replyCommentFormSchema } from '../validators/AddEditCommentForm'
import { ProjectDetails } from '../stores/projectStore'
import { isGlobalAdmin, isProjectAdmin } from '../helpers/appRoles'
import { useAuth0 } from '@auth0/auth0-react'
import ArchiveButton from './ArchiveButton'

type TabType = 'comment' | 'assign' | 'attachments'
interface IReplyComment {
  comment?: IComment
  externalData: ISelectedExternalDataPoint | null
  categoryList: IProjectCategory[] | null
  authorName?: string
  title: string
  phaseId: string
  onClose: () => void
  setCommented: () => void
  setCommentState: (state: CommentState) => void
}

const ReplyComment: FC<IReplyComment> = (props) => {
  const { addToast } = useToast()
  const [tempComment, setTempComment] = useState<IComment>(EMPTY_COMMENT)
  const [selectedTab, setSelectedTab] = useState<TabType>('comment')
  const [commentUploading, setCommentUploading] = useRecoilState(CommentUploading)
  const setSelectedCommentId = useSetRecoilState(SelectedCommentId)
  const [files, setFiles] = useState<File[]>([])
  const { uploadProgress, uploadFile } = useUploadAttachments()
  const refreshComments = useRefreshComments()
  const [errors, setErrors] = useState<{ content?: string }>({ content: '' })
  const validator = useFormValidation({})
  const { user } = useAuth0()
  const details = useRecoilValue(ProjectDetails)

  useEffect(() => {
    if (props.comment) {
      setTempComment({
        ...props.comment,
        title: `RE: ${props.comment.title}`,
        content: '',
        commentId: '',
        attachments: [],
        threadId: props.comment.commentId,
      })
      setSelectedTab('comment')
    } else if (props.externalData) {
      setTempComment({
        ...EMPTY_COMMENT,
        phaseId: props.externalData.phaseId,
        title: `RE: ${props.externalData.dataName}`,
        threadId: props.externalData.id,
        lat: props.externalData.lat,
        lng: props.externalData.long,
      })
      setSelectedTab('comment')
    }
  }, [props.comment, props.externalData])

  const handleContentChange = (content: string) => {
    setTempComment({
      ...tempComment,
      content: content,
    })
  }

  const handleCategorySelection = (categoryId: ReactText) => {
    setTempComment({
      ...tempComment,
      categoryId: categoryId === 'no category' ? null : categoryId,
      assignee: null,
    })
  }

  const handleAssignee = (selected: boolean) => {
    if (!category) return

    setTempComment({
      ...tempComment,
      assignee: selected ? category.categoryLead : null,
      assigneeName: category.categoryUserName ? category.categoryUserName : null,
    })
  }

  const removeExistingAttachment = useCallback((index: number) => {
    setTempComment((temp) => {
      return {
        ...temp,
        attachments: temp.attachments.filter((f, i) => i !== index),
      }
    })
  }, [])

  const saveComment = async () => {
    const validationErrors = validator.validate(
      {
        content: tempComment.content,
      },
      replyCommentFormSchema,
    )

    if (validationErrors) {
      setErrors(() => ({ ...validationErrors }))
      return
    }

    setCommentUploading(true)
    setErrors({})

    const fileNames = files.map((file) => truncateFileName(file.name))

    const request: ICommentAddProps = {
      phaseId: props.phaseId,
      comment: {
        ...tempComment,
        phaseId: props.phaseId,
        threadId: tempComment.threadId || null,
        attachments: fileNames,
      },
    }

    const newComment = !tempComment.commentId ? await addComment(request) : await updateComment(request)

    if (!newComment) {
      addToast({
        type: 'error',
        message: 'There was an issue saving the comment.',
        timeout: 4000,
      })

      setCommentUploading(false)
      return
    }

    if (files.length) {
      const result = await uploadFile({
        commentId: newComment.commentId,
        phaseId: props.phaseId,
        files: files,
        fileNames,
      })
      if (!result)
        addToast({
          type: 'error',
          message: 'There was an issue uploading the comment attachments.',
          timeout: 3000,
        })
    }

    setCommentUploading(false)
    setSelectedCommentId(newComment.commentId)
    props.setCommentState('exisiting')
    props.setCommented()
    refreshComments()
  }

  const categories = props.categoryList
    ? props.categoryList.map((cat) => {
        return { id: cat.id ? cat.id : '', label: cat.categoryName }
      })
    : []

  const category = props.categoryList?.filter((cat) => cat.id === tempComment.categoryId)[0]

  const categoryLeadName =
    category && category.categoryUserName
      ? `Assign to Category Lead: ${category.categoryUserName}`
      : 'No Category Lead Assigned'
  const categoryLeadDisable = !(category && category.categoryUserName)
  const categoryLeadCheck = category?.categoryLead === tempComment.assignee && !categoryLeadDisable

  const isArchived = !!props.comment?.archived
  const showArchiveButton = isProjectAdmin(details) || isGlobalAdmin(user)

  return (
    <div className={Style.reply}>
      <h3>Reply to {props.authorName}</h3>
      <p>RE: {props.title}</p>
      <div className={Style.switcher}>
        <button
          className={selectedTab === 'comment' ? Style.active : undefined}
          onClick={() => setSelectedTab('comment')}
          title='Add Comment'
        >
          Comment
        </button>
        <button
          className={selectedTab === 'assign' ? Style.active : undefined}
          onClick={() => setSelectedTab('assign')}
          title='Assign Category Lead'
        >
          Assign
        </button>
        <button
          className={selectedTab === 'attachments' ? Style.active : undefined}
          onClick={() => setSelectedTab('attachments')}
          title='Add attachments'
        >
          Attachments
        </button>
      </div>
      {selectedTab === 'comment' && (
        <div className={Style.replyBox}>
          <FormInput
            label=''
            placeholder='Type in a comment...'
            multiline
            default
            onChange={handleContentChange}
            value={tempComment.content}
            multilineLimit={240}
            error={errors.content}
          />
        </div>
      )}
      {selectedTab === 'assign' && (
        <div className={Style.replyBox}>
          <Dropdown
            items={categories}
            placeholder='Select a category...'
            selectedItem={tempComment && tempComment.categoryId ? tempComment.categoryId : ''}
            onSelectItem={handleCategorySelection}
            default
            cssClass={Style.dropdown + ' is-aal-dropdown is-active'}
          />
          <Checkbox
            label={categoryLeadName}
            disabled={!tempComment.categoryId || categoryLeadDisable}
            onChange={handleAssignee}
            checked={categoryLeadCheck}
          />
        </div>
      )}
      {selectedTab === 'attachments' && (
        <>
          <FileUpload
            files={files}
            setFiles={setFiles}
            existingFiles={tempComment.attachments}
            progress={commentUploading ? uploadProgress : null}
            removeExisting={removeExistingAttachment}
            loading={commentUploading}
            disabled={isArchived}
          />
          <p className={Style.replyInfo}>
            <Icon type='info_outline' size='20px' /> You must add a comment to reply and upload attachments.
          </p>
        </>
      )}
      <div className={Style.drawerActionsComment}>
        <Button
          label='Reply'
          onClick={saveComment}
          disabled={!tempComment.content.length || isArchived}
          loading={commentUploading}
          title='Add reply'
        />
        <Button
          label='Cancel'
          type='secondary'
          title='Cancel reply'
          disabled={commentUploading}
          onClick={props.onClose}
        />
        {showArchiveButton && props.comment && <ArchiveButton size='medium' comment={props.comment} />}
      </div>
    </div>
  )
}

export default ReplyComment
