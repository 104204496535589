import React, { ChangeEvent, FC, useRef } from 'react'
import { Button, Icon, useToast } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/FileUpload.module.sass'
import { MAX_FILE_SIZE } from '../config/config'

interface IFileUploadProps {
  existingFiles: string[]
  files: File[]
  progress: { [filename: string]: number } | null
  setFiles: (files: File[]) => void
  removeExisting: (index: number) => void
  loading: boolean
  disabled?: boolean
}

const FileUpload: FC<IFileUploadProps> = (props) => {
  const fileInput = useRef<HTMLInputElement>(null)
  const { addToast } = useToast()

  const validateAttachments = (fileName: string): boolean => {
    if (!fileName) return false
    const ext = fileName.split('.').pop()
    if (!ext) return false
    return ['jpg', 'jpeg', 'png', 'csv', 'xlsx'].includes(ext.toLowerCase())
  }

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = [...props.files]
    if (e.currentTarget.files) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        const file = (e.currentTarget.files as FileList)[i]
        if (
          !newFiles.find((f) => f.name === file.name) &&
          !props.existingFiles.find((exFile) => exFile.includes(file.name))
        )
          if (file.size > MAX_FILE_SIZE)
            addToast({
              type: 'warning',
              timeout: 3000,
              message: `${file.name} is too large. Max file size: ${MAX_FILE_SIZE / Math.pow(1024, 2)} MB.`,
            })
          else if (!validateAttachments(file.name))
            addToast({
              type: 'warning',
              timeout: 5000,
              message: `${file.name} is not valid. Acceptance files with extensions(.jpg, .jpeg, .png, .csv, .xlsx).`,
            })
          else newFiles.push(file)
      }
    }
    props.setFiles(newFiles)
  }

  const renderExFileRow = (file: string, index: number) => {
    return (
      <tr key={file}>
        <td>{file.split('/').slice(-1)[0]}</td>
        <td>
          {!props.progress && (
            <button title='Remove attachment' onClick={() => props.removeExisting(index)}>
              <Icon type='close' />
            </button>
          )}
        </td>
      </tr>
    )
  }

  const renderNewFileRow = (file: File, index: number) => {
    return (
      <tr key={file.name}>
        <td>
          {file.name}
          {props.progress && (
            <div className={Style.progress}>
              <div style={{ transform: `scaleX(${props.progress[file.name] / file.size})` }} />
            </div>
          )}
        </td>
        <td>
          {!props.progress && (
            <button
              title='Remove attachment'
              onClick={() => props.setFiles(props.files.filter((f2, j) => index !== j))}
            >
              <Icon type='close' />
            </button>
          )}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <Button
        label='Upload Attachments'
        flexible
        size='small'
        type='default'
        onClick={() => fileInput.current?.click()}
        disabled={props.loading || props.disabled}
      />
      <input
        ref={fileInput}
        className={Style.fileInput}
        type='file'
        multiple={true}
        value=''
        onChange={onFileChange}
        accept='.jpg,.jpeg,.png,.csv,.xlsx'
      />
      <p className={Style.fileTypesInfo}>Accepted file types: image, document and spreadsheet.</p>
      {Math.max(props.files.length, props.existingFiles.length) > 0 && (
        <div className={Style.attachments}>
          <div>
            <Icon type='attach_file' />
            <span>{props.files.length + props.existingFiles.length}</span>
          </div>
          <div>
            <table className={Style.files}>
              <tbody>
                {props.existingFiles.map((f, i) => renderExFileRow(f, i))}
                {props.files.map((f, i) => renderNewFileRow(f, i))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default FileUpload
