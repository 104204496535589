import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { getLuminance } from 'polished'
import { getMap, HybridMap } from '../components/HybridMap/HybridMap'
import { HybridMarker } from '../components/HybridMap/HybridMarker'
import { HybridLayer } from '../components/HybridMap/HybridLayer'
import { LngLat, LngLatBoundsLike, MapMouseEvent } from 'mapbox-gl'
import { MapStyle, MarkerVisibility } from '../stores/mapStore'
import { FilteredComments } from '../stores/commentStore'
import { ProjectCategories } from '../stores/projectStore'
import { LayerState, MapSources, PhaseSources } from '../stores/layerStore'
import { Measuring, MeasureMode, CurrentMeasurement } from '../stores/measureStore'
import { ExternalData, ISelectedExternalData } from '../stores/externalDataStore'
import { NavFrameTab, BoundsPadding } from '../stores/uiStore'
import { IExternalDataPoint } from '../api/storage'

import MapControls from '../components/MapControls'
import StreetView from '../components/Streetview'
import { ILocation } from '../components/AddEditComment'
import SectionHighlight from '../components/SectionHighlight'
import SectionVideo from '../components/SectionVideo'
import MapLegend from '../components/MapLegend'
import { useMeasurements, useMoveSection } from '../helpers/mapUtils'
import MapMeasure, { BaseMeasureLayerId } from '../components/MapMeasure'
import PropertiesPopup, { IPropertiesPopup } from '../components/PropertiesPopup'

import { ReactComponent as PinIcon } from '../assets/marker.svg'
import { ReactComponent as CommentIcon } from '../assets/commentIcon.svg'
import { ReactComponent as DefaultMarkerIcon } from '../assets/defaultMarkerIcon.svg'
import { ReactSVG } from 'react-svg'

import { ReverseSourceTypeEnum } from '../enums/SourceTypeEnum'

import 'mapbox-gl/dist/mapbox-gl.css'
import Style from '../styles/Map.module.sass'
import classNames from 'classnames'

export const mainMapStyle = 'mapbox://styles/unsigned-cse/ckfoxh8nc0jhm1apqlh9vlyg8'
const satelliteMapStyle = 'mapbox://styles/mapbox/satellite-v9'

export interface IMapProps {
  startLocation: LngLatBoundsLike
  onMarkerClick: (commentId: string) => void
  onExternalMarkerClick: (field: IExternalDataPoint) => void
  onRightClick: (props: ILocation) => void
  selectedCommentId: string | null
  selectedExternalData: ISelectedExternalData | null
  newCommentLocation: ILocation | null
}

const Map: FC<IMapProps> = (props) => {
  const [loaded, setLoaded] = useState(false)
  const mapStyle = useRecoilValue(MapStyle)
  const comments = useRecoilValueLoadable(FilteredComments)
  const externalData = useRecoilValueLoadable(ExternalData)
  const markersVisible = useRecoilValue(MarkerVisibility)
  const [svPosition, setSVPosition] = useState<LngLat>()
  const [propertiesPopup, setPropertiesPopup] = useState<IPropertiesPopup>()
  const projectCategories = useRecoilValue(ProjectCategories)
  const allSources = useRecoilValueLoadable(PhaseSources)
  const mapSources = useRecoilValueLoadable(MapSources)
  const layerState = useRecoilValue(LayerState)
  const navFrameTab = useRecoilValue(NavFrameTab)
  const boundsPadding = useRecoilValue(BoundsPadding)
  const measureMode = useRecoilValue(MeasureMode)
  const measuring = useRecoilValue(Measuring)
  const setCurrentMeasurement = useSetRecoilState(CurrentMeasurement)
  const zoomToMeasure = useMeasurements()
  const {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    mouseDownOnSection,
    mouseOverSection,
    mouseOverLayer,
    measureDragIndex,
    measureHover,
    mouseDownPoint,
    setMeasureHover,
  } = useMoveSection(loaded)

  const { selectedCommentId, selectedExternalData, onMarkerClick, onExternalMarkerClick } = props

  useEffect(() => {
    if (measureMode) setSVPosition(undefined)
  }, [measureMode])

  const handlePropertiesPopup = (e: MapMouseEvent): boolean => {
    if (allSources.state !== 'hasValue' || !allSources.contents) return false

    let hasProps = false
    const popup: IPropertiesPopup = { location: e.lngLat, properties: {} }

    const features = getMap()?.queryRenderedFeatures(e.point)
    if (features) {
      for (const feature of features) {
        const sourceId = feature.source
        const source = allSources.contents.find((source) => source.sourceId === sourceId)
        if (source && source.popup) {
          try {
            const sourcePopup = JSON.parse(source.popup)
            if (sourcePopup.active !== true) continue

            if (sourcePopup.displayAll === true) {
              popup.properties[source.name] = { ...feature.properties }
              hasProps = true
            }

            if (sourcePopup.properties) {
              // For every prop to render from DB
              for (const prop of Object.entries(sourcePopup.properties) as [string, string][]) {
                // Check if prop is present on the feature
                if (feature.properties ? feature.properties[prop[0]] : undefined) {
                  // Add this source to the list if not already
                  if (!popup.properties[source.name]) popup.properties[source.name] = {}
                  // Add the key and value for this prop
                  popup.properties[source.name][prop[1]] = feature.properties![prop[0]]
                  if (sourcePopup.displayAll === true) {
                    delete popup.properties[source.name][prop[0]]
                  }
                  hasProps = true
                }
              }
            }
          } catch (error) {
            console.error(`source ${source.name} popup: ${error}`)
          }
        }
      }
    }

    setPropertiesPopup(hasProps ? popup : undefined)

    return hasProps || !!propertiesPopup
  }

  const onLeftClick = (e: MapMouseEvent) => {
    if ((e.originalEvent.target as HTMLElement).className !== 'mapboxgl-canvas') return

    if (measuring && measureMode) {
      setCurrentMeasurement((m) => [...m, [e.lngLat.lng, e.lngLat.lat]])
    } else if (measureHover) {
      zoomToMeasure(measureHover.id)
      setMeasureHover(null)
    } else if (!measuring) {
      const showingPopup = handlePropertiesPopup(e)
      if (!showingPopup) setSVPosition(e.lngLat)
    }
  }

  const onRightClick = (e: MapMouseEvent) => {
    if ((e.originalEvent.target as HTMLElement).className !== 'mapboxgl-canvas' || !e.point.equals(mouseDownPoint))
      return

    if (measuring && measureMode) {
      setCurrentMeasurement([])
    } else if (!measuring) {
      props.onRightClick({ ...e.lngLat, zoom: getMap()?.getZoom() || 0 })
    } else {
      setSVPosition(undefined)
    }
  }

  const closeStreetView = useCallback(() => setSVPosition(undefined), [])

  const streetView = useMemo(() => {
    return <StreetView position={svPosition} close={closeStreetView} />
  }, [svPosition, closeStreetView])

  const renderSources = useMemo(() => {
    if (mapSources.state !== 'hasValue') return null

    return mapSources.contents.map((sourceDetails) => {
      const source = sourceDetails.source
      const visible = source.Layers.length && source.Layers.every((layer) => layerState[layer.layerId])

      let paint = {}
      try {
        paint = JSON.parse(source.paint)
      } catch {
        console.log(`Source ${source.sourceId} has malformed paint.`)
      }

      const layerProps = {
        id: source.sourceId,
        type: ReverseSourceTypeEnum[source.type].type,
        paint: paint,
        layout: { visibility: visible ? 'visible' : 'none' },
        beforeId: BaseMeasureLayerId,
        sourceLayer: source.sourceLayer,
      } as any //eslint-disable-line

      if (sourceDetails.mapbox) {
        return <HybridLayer key={source.sourceId} {...layerProps} url={sourceDetails.url} />
      } else if (sourceDetails.data) {
        return <HybridLayer key={source.sourceId} {...layerProps} data={sourceDetails.data} />
      } else return null
    })
  }, [mapSources, layerState])

  const commentMarkers = useMemo(() => {
    if (comments.state !== 'hasValue' || !comments.contents) return

    return comments.contents
      .filter((comment) => !comment.threadId)
      .map((comment) => {
        const markerClass = selectedCommentId === comment.commentId ? Style.selectedMarker : Style.commentMarker
        const category = projectCategories?.find((cat) => cat.id === comment.categoryId)
        const markerColour = category ? `is-colour-${category.colour}` : ''

        return (
          <HybridMarker
            key={comment.commentId}
            lngLat={comment}
            className={classNames(Style.mapboxCommentMarker, { [Style.archived]: comment.archived })}
          >
            <CommentIcon
              title='Project comment'
              className={`${markerClass} ${Style[markerColour]}`}
              onClick={() => onMarkerClick(comment.commentId)}
            />
          </HybridMarker>
        )
      })
  }, [comments, selectedCommentId, projectCategories, onMarkerClick])

  const externalDataMarkers = useMemo(() => {
    if (externalData.state !== 'hasValue' || !externalData.contents) return null

    return externalData.contents.map((set) => {
      return set.data.map((d) => {
        let colourField = 'gray'

        d.fields.forEach((field) => {
          if (set.colourFieldId === field.id && typeof field.value2 === 'string') colourField = field.value2
        })

        const textColour = getLuminance(colourField) > 0.2 ? 'black' : 'white'
        const active =
          selectedExternalData && selectedExternalData.id === d.rowKey && selectedExternalData.dataId === d.partitionKey

        const markerClass = active ? Style.selectedMarker : Style.commentMarker

        if (!set.dataIcon)
          return (
            <HybridMarker key={d.rowKey} lngLat={new LngLat(d.long, d.lat)} className={Style.mapboxCommentMarker}>
              <DefaultMarkerIcon
                title='External Data Point'
                className={markerClass}
                onClick={() => onExternalMarkerClick(d)}
              />
            </HybridMarker>
          )

        return (
          <HybridMarker key={d.rowKey} lngLat={new LngLat(d.long, d.lat)} className={Style.mapboxCommentMarker}>
            <ReactSVG
              src={set.dataIcon}
              beforeInjection={(svg) => {
                const shapeEl = svg.getElementsByClassName('shape')
                const textEl = svg.getElementsByClassName('text')
                if (shapeEl[0] && textEl[0]) {
                  svg.setAttribute('width', active ? '40' : '30')
                  svg.setAttribute('height', active ? '40' : '30')
                  shapeEl[0].setAttribute('fill', colourField)
                  shapeEl[0].setAttribute('stroke', textColour)
                  textEl[0].setAttribute('fill', textColour)
                }
              }}
              className={Style.commentMarker}
              onClick={() => onExternalMarkerClick(d)}
            />
          </HybridMarker>
        )
      })
    })
  }, [externalData, selectedExternalData, onExternalMarkerClick])

  const cursor = useMemo(() => {
    if (measureHover?.mode === 'line') return 'pointer'
    else if (measuring && measureMode) return 'crosshair'
    else if (mouseOverLayer) return 'pointer'
    else if (mouseOverSection) return 'pointer'
    else return 'grab'
  }, [measureHover, measuring, measureMode, mouseOverLayer, mouseOverSection])

  return (
    <div className={Style.mapContainer}>
      <HybridMap
        mapStyle={mapStyle === 'map' ? mainMapStyle : satelliteMapStyle}
        onLoad={() => setLoaded(true)}
        width='100%'
        height='100%'
        bounds={props.startLocation}
        padding={boundsPadding}
        dragPan={mouseDownOnSection === 'none' && measureDragIndex === -1}
        cursor={cursor}
        onClick={onLeftClick}
        onRightClick={onRightClick}
        onMouseDown={(e) => onMouseDown(e)}
        onMouseMove={(e) => onMouseMove(e)}
        onMouseUp={onMouseUp}
      >
        <MapMeasure movingIndex={measureDragIndex} measureHover={measureHover} />
        {markersVisible && commentMarkers}
        {markersVisible && externalDataMarkers}
        {svPosition && (
          <HybridMarker className={Style.streetViewMarker} lngLat={svPosition}>
            <PinIcon />
          </HybridMarker>
        )}
        {props.newCommentLocation && (
          <HybridMarker lngLat={props.newCommentLocation} className={Style.mapboxCommentMarker}>
            <CommentIcon title='Project comment' className={Style.newComment} />
          </HybridMarker>
        )}
        {propertiesPopup && <PropertiesPopup popup={propertiesPopup} onClose={() => setPropertiesPopup(undefined)} />}
        {renderSources}
        {navFrameTab.name === 'Section' && <SectionHighlight />}
      </HybridMap>
      <MapLegend />
      <MapControls startLocation={props.startLocation} />
      {streetView}
      {navFrameTab.name === 'Section' && <SectionVideo />}
    </div>
  )
}

export default Map
