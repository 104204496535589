import { RoleType } from '../enums/UserRolesEnum'
import { headersWithToken, IResponse } from '../helpers/utils'
import { categoryColours, IProjectCategory } from './categories'
import { TokenExpiryWrapper, ProjectReadScope } from './TokenManager'
import {
  IRequestTokenProps,
  IRequestProjectPhaseProps,
  IRequestPhaseProps,
  IRequestCreateProjectProps,
  IRequestUpdateProjectProps,
  IRequestProjectMemberProps,
  IRequestMyProject,
  IRequestAllProject,
} from './apiprops'
import { ProjectSortModeEnum } from '../enums/ProjectSortModeEnum'
import { Bounds } from '../helpers/mapUtils'
import { ReactText } from 'react'

export interface IProjectPhase {
  client: string
  displayOrder: number
  phaseId: string
  phaseName: string
  projectId: string
  title: string
}

const getProjectsRequest = async (props: IRequestTokenProps): Promise<IProjectPhase[] | null> => {
  const response = await fetch('/projects', {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const getProjects = TokenExpiryWrapper(getProjectsRequest, [ProjectReadScope], null)

export interface IProjectData {
  id: string
  title: string
  client: string
  status: number
  Phases: IPhaseData[]
}

export interface IProjectSorting {
  sortMode: ProjectSortModeEnum
  title?: string
  selected: boolean
}

export interface IProjectFilter {
  textSearch: string
  sortMode: ProjectSortModeEnum
}

export interface IPhaseData {
  id: string
  phaseName: string
  displayOrder: number
  slug?: string
  ProjectMembers: IProjectMember[]
  Categories: ICategoryData[]
}

export interface ICategoryData {
  id: string
  categoryName: string
  colour: number
}

const getMyProjectsRequest = async (props: IRequestMyProject): Promise<IProjectData[] | null> => {
  const response = await fetch(`/projects/my`, {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const getMyProjects = TokenExpiryWrapper(getMyProjectsRequest, [ProjectReadScope], null)

const getAllProjectsRequest = async (props: IRequestAllProject): Promise<IProjectData[] | null> => {
  const response = await fetch(`/projects/all`, {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const getAllProjects = TokenExpiryWrapper(getAllProjectsRequest, [ProjectReadScope], null)

export interface IProjectDetails {
  projectId: string
  phaseId: string
  client: string
  title: string
  slug?: string
  phaseName: string
  projectCode: string
  bounds: Bounds
  userRole?: number
  commentsVisibility?: number
}

export interface IProjectDetailsResponse {
  details: IProjectDetails
  currentMemberId: string | null
}

export const getProjectDetailsByUserRequest = async (
  props: IRequestProjectPhaseProps,
): Promise<IProjectDetailsResponse | null> => {
  const url = `/projects/details/${props.phaseIdOrSlug}`
  const response = await fetch(url, {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const getProjectDetailsByUser = TokenExpiryWrapper(getProjectDetailsByUserRequest, [ProjectReadScope], null)

export const categoryMaxColours = 12

export interface IGlobalAdminProjectCategory {
  id?: string
  categoryColour: categoryColours
  name: string
  categoryLead: string
  phaseId: string
}

export interface IUser {
  userId?: string
  name: string
  email: string
}

export interface IProjectUser {
  projectMemberId: string
  name: string
  email: string
  nickname: string
  userId: string
  userRole: RoleType
  pending: boolean
  categoryLead?: string
  phaseId?: string
  categoryId?: ReactText
}

export interface IGlobalAdminProjectCategory {
  id?: string
  categoryColour: categoryColours
  name: string
  categoryLead: string
  phaseId: string
}

export const getProjectUsersRequest = async (props: IRequestPhaseProps): Promise<IProjectUser[] | []> => {
  const url = `/projects/users/${props.phaseId}`
  const response = await fetch(url, {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const getProjectUsers = TokenExpiryWrapper(getProjectUsersRequest, [ProjectReadScope], [])

export interface IAccessLevel {
  userRole: RoleType
}

const getPhaseAccessLevelRequest = async (props: IRequestPhaseProps): Promise<IAccessLevel> => {
  const url = `/projects/access/${props.phaseId}`
  const response = await fetch(url, {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const getPhaseAccessLevel = TokenExpiryWrapper(getPhaseAccessLevelRequest, [ProjectReadScope], { userRole: 0 })

export interface IRequestCreateProject {
  project: {
    title: string
    status: number
  }
  phase: {
    slug?: string
    projectCode: string
    phaseName: string
    bounds: Bounds
    commentsVisibility: number
  }
  categories: IProjectCategory[]
  projectMembers: IProjectMember[]
}

export const createProjectDetailsRequest = async (props: IRequestCreateProjectProps): Promise<IResponse> => {
  const response = await fetch('/projects/details', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.project),
  })
  const content = await response.json()
  return {
    success: response.ok,
    message: content.message,
    data: content,
  }
}
export const createProjectDetails = TokenExpiryWrapper(createProjectDetailsRequest, [ProjectReadScope], {
  success: false,
  message: 'Error creating the project details',
})

export interface IRequestUpdateProject {
  projectId: string
  phaseId: string
  projectName: string
  slug?: string
  projectCode: string
  phaseName: string
  bounds: Bounds
  commentsVisibility: number
}

export const updateProjectDetailsRequest = async (props: IRequestUpdateProjectProps): Promise<IResponse> => {
  const response = await fetch('/projects/details', {
    method: 'put',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.project),
  })
  const content = await response.json()
  return {
    success: response.ok,
    message: content.message,
  }
}
export const updateProjectDetails = TokenExpiryWrapper(updateProjectDetailsRequest, [ProjectReadScope], {
  success: false,
  message: 'Error updating the project details',
})

export interface IProjectMember {
  projectMemberId: string
  userId: string
  phaseId: string
  userRole: RoleType
  pendingUserEmail: string
  name?: string
}

const updateProjectMemberRequest = async (props: IRequestProjectMemberProps): Promise<IResponse> => {
  const response = await fetch('/projects/user/update', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.user),
  })
  if (response.ok) return response.json()
  throw response
}
export const updateProjectMember = TokenExpiryWrapper(updateProjectMemberRequest, [ProjectReadScope], {
  success: false,
  message: 'Error updating the project details',
})
