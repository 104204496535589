import React, { useEffect, useState, FC, useCallback } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValueLoadable } from 'recoil'
import { useParams, Redirect } from 'react-router-dom'
import { Drawer, useToast } from '@aurecon-creative-technologies/styleguide'
import NavigationFrame from '../components/NavigationFrame'
import Page from '../components/Page'
import AddEditComment, { CommentState, ILocation } from '../components/AddEditComment'
import ViewExternalData from '../components/ViewExternalData'
import Measurement from '../components/Measurement'
import { getPhaseAccessLevel, getProjectDetailsByUser, IProjectDetails } from '../api/projects'
import { getProjectCategories } from '../api/categories'
import { SelectedCommentId, NewCommentLocation, Comments, useRefreshComments } from '../stores/commentStore'
import { SelectedExternalData } from '../stores/externalDataStore'
import { NavFrameHeight, NAV_FRAME_HEIGHT_RATIO } from '../stores/uiStore'
import { ProjectDetails, ProjectCategories, CurrentProjectContext } from '../stores/projectStore'
import { LayerState, LayerGroups, SelectedGISGroup, OnlyShowGroupLayers } from '../stores/layerStore'
import { RightDrawerOpen, useHandleDrawer, tabs, NavFrameTab } from '../stores/uiStore'
import { Measuring } from '../stores/measureStore'
import Map from './Map'

import Style from '../styles/Project.module.sass'
import LoadingScreen from '../components/LoadingScreen'
import { UserRolesEnum } from '../enums/UserRolesEnum'
import { SocketIoEnum } from '../enums/SocketIoEnum'
import { useAuth0 } from '@auth0/auth0-react'
import { isGlobalAdmin } from '../helpers/appRoles'

import { socket } from '../api/socket'
import { IExternalDataPoint, IField } from '../api/storage'
import ImagePreview from '../components/ImagePreview'
import { UserDetails } from '../stores/authStore'

interface IParams {
  phaseId: string
  slug: string
}

const Project: FC = () => {
  const { user } = useAuth0()
  const { phaseId, slug } = useParams<IParams>()
  const [userDetails, setUserDetails] = useRecoilState(UserDetails)
  const comments = useRecoilValueLoadable(Comments)
  const [myMemberId, setMyMemberId] = useState<string | null>(null)
  const [drawerOpen, setDrawerOpen] = useRecoilState(RightDrawerOpen)
  const [measuring] = useRecoilState(Measuring)
  const [projectDetails, setProjectDetails] = useRecoilState(ProjectDetails)
  const setCurrentProjectContext = useSetRecoilState(CurrentProjectContext)
  const [projectCategories, setProjectCategories] = useRecoilState(ProjectCategories)
  const [selectedCommentId, setSelectedCommentId] = useRecoilState(SelectedCommentId)
  const [selectedExternalData, setSelectedExternalData] = useRecoilState(SelectedExternalData)
  const [commentState, setCommentState] = useState<CommentState>('new')
  const [commented, setCommented] = useState(false)
  const setLayersState = useSetRecoilState(LayerState)
  const setSelectedGISGroup = useSetRecoilState(SelectedGISGroup)
  const setOnlyShowGroupLayers = useSetRecoilState(OnlyShowGroupLayers)
  const layerGroups = useRecoilValueLoadable(LayerGroups)
  const [loading, setLoading] = useState(true)
  const [newCommentLocation, setNewCommentLocation] = useRecoilState(NewCommentLocation)
  const setMeasuring = useSetRecoilState(Measuring)
  const { addToast } = useToast()
  const handleDrawer = useHandleDrawer()
  const setNavFrameHeight = useSetRecoilState(NavFrameHeight)
  const setActiveTab = useSetRecoilState(NavFrameTab)
  const [hasAccess, setHasAccess] = useState(false)
  const [connected, setConnected] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [image, setImage] = useState<IField | null>(null)
  const refreshComments = useRefreshComments()

  const globalAdmin = isGlobalAdmin(user)

  useEffect(() => {
    if (userDetails.name !== user.name) setUserDetails({ name: user.name })
  }, [user, userDetails, setUserDetails])

  useEffect(() => {
    setMeasuring(false)
    setSelectedCommentId(null)
    setSelectedExternalData(null)
    handleDrawer(false)
    setActiveTab(tabs[0])
    setNavFrameHeight(window.innerHeight * NAV_FRAME_HEIGHT_RATIO)
  }, [setMeasuring, setSelectedCommentId, setSelectedExternalData, handleDrawer, setNavFrameHeight, setActiveTab])

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true)
      setDrawerOpen(false)

      const details = await getProjectDetailsByUser({ phaseIdOrSlug: slug || phaseId })

      setProjectDetails(details?.details || null)

      if (details) {
        const phaseId = details.details.phaseId

        const access = await getPhaseAccessLevel({ phaseId: phaseId })
        setHasAccess(globalAdmin || access.userRole !== UserRolesEnum.NO_ACCESS)

        const categories = await getProjectCategories({ phaseId })
        setProjectDetails(details?.details || null)
        setCurrentProjectContext('project')
        setMyMemberId(details?.currentMemberId || null)
        setProjectCategories(categories)
      }

      setLoading(false)
    }
    asyncEffect()
  }, [setCurrentProjectContext, setProjectCategories, setProjectDetails, setDrawerOpen, slug, phaseId, globalAdmin])

  useEffect(() => {
    socket.on(SocketIoEnum.NEW_COMMENT_REPLY_EVENT, () => {
      addToast({
        type: 'info',
        message: `You have a new reply to your comment.`,
        timeout: 4000,
      })

      refreshComments()
    })

    setConnected(true)

    return () => {
      socket.off(SocketIoEnum.NEW_COMMENT_REPLY_EVENT, () => setConnected(false))
    }
  }, [addToast, refreshComments])

  useEffect(() => {
    if (!myMemberId || comments.state !== 'hasValue' || !comments.contents || subscribed) return

    const isThreadOwner = comments.contents.some((comment) => comment.userId === myMemberId && !comment.threadId)
    if (isThreadOwner && connected) {
      socket.emit(SocketIoEnum.SUBSCRIBE_COMMENT_REPLY_EVENT, myMemberId)
      setSubscribed(true)
    }
  }, [comments, connected, myMemberId, subscribed])

  useEffect(() => {
    if (!myMemberId || comments.state !== 'hasValue' || !comments.contents) return

    const newComments = comments.contents.filter((comment) => comment.assignee === myMemberId && comment.seen !== true)
    if (newComments.length) {
      addToast({
        type: 'info',
        timeout: 4000,
        message: `You have ${newComments.length} new comment${newComments.length > 1 ? 's' : ''} assigned to you.`,
      })
    }
  }, [comments, myMemberId, addToast])

  useEffect(() => {
    if (layerGroups.state !== 'hasValue' || !layerGroups.contents) return

    // Turn on initial active layers
    const state = {}
    layerGroups.contents.forEach((groups) =>
      groups.Layers.forEach((layer) => {
        state[layer.layerId] = layer.active
      }),
    )
    setLayersState(state)
    setSelectedGISGroup('')
    setOnlyShowGroupLayers(false)
  }, [layerGroups, setLayersState, setSelectedGISGroup, setOnlyShowGroupLayers])

  const addComment = useCallback(
    (location: ILocation) => {
      setMeasuring(false)
      setNewCommentLocation(location)
      setSelectedCommentId(null)
      setSelectedExternalData(null)
      handleDrawer(true)
      setCommentState('new')
    },
    [setNewCommentLocation, setSelectedCommentId, setSelectedExternalData, handleDrawer, setMeasuring],
  )

  const handleMarkerClick = useCallback(
    (commentId: string) => {
      setMeasuring(false)
      setNewCommentLocation(null)
      setSelectedCommentId(commentId)
      setSelectedExternalData(null)
      handleDrawer(true)
      setCommentState('exisiting')
      setCommented(false)
    },
    [setNewCommentLocation, setSelectedCommentId, setSelectedExternalData, handleDrawer, setMeasuring],
  )

  const handleExternalMarkerClick = useCallback(
    (field: IExternalDataPoint) => {
      setMeasuring(false)
      setNewCommentLocation(null)
      setSelectedCommentId(null)
      setSelectedExternalData({ dataId: field.partitionKey, id: field.rowKey })
      handleDrawer(true)
      setCommentState('exisiting')
      setCommented(false)
    },
    [setNewCommentLocation, setSelectedCommentId, setSelectedExternalData, handleDrawer, setMeasuring],
  )

  const closeDrawer = useCallback(() => {
    handleDrawer(false)
  }, [handleDrawer])

  const handleCloseImage = () => {
    setImage(null)
  }

  const renderDrawerContent = () => {
    if (selectedCommentId || newCommentLocation)
      return (
        <AddEditComment
          commentState={commentState}
          setCommentState={setCommentState}
          commented={commented}
          setCommented={setCommented}
          newCommentLocation={selectedCommentId ? null : newCommentLocation}
          removeNewCommentLocation={() => setNewCommentLocation(null)}
          phaseId={(projectDetails as IProjectDetails).phaseId}
          myMemberId={myMemberId}
          categoryList={projectCategories}
          onClose={closeDrawer}
          commentsVisibility={(projectDetails as IProjectDetails).commentsVisibility}
        />
      )
    else if (selectedExternalData)
      return (
        <ViewExternalData
          myMemberId={myMemberId}
          categoryList={projectCategories}
          onClose={closeDrawer}
          onImagePreview={setImage}
          setCommentState={setCommentState}
          setCommented={() => setCommented(true)}
        />
      )
    else if (measuring) return <Measurement />
    else return null
  }

  if (loading) {
    return <LoadingScreen text='Loading project...' />
  }

  if (projectDetails?.client === undefined || !hasAccess)
    return <Redirect to={`/noaccess/${projectDetails?.phaseId || slug || phaseId || ''}`} />

  return (
    <Page>
      <div className={Style.mainProjectWrapper}>
        <Drawer open={drawerOpen} onClose={closeDrawer} cssClass='main-drawer' closeTitle='Close'>
          {renderDrawerContent()}
        </Drawer>
        <Map
          startLocation={projectDetails.bounds}
          onRightClick={addComment}
          onMarkerClick={handleMarkerClick}
          onExternalMarkerClick={handleExternalMarkerClick}
          selectedCommentId={selectedCommentId}
          selectedExternalData={selectedExternalData}
          newCommentLocation={newCommentLocation}
        />
      </div>
      <NavigationFrame />
      <ImagePreview image={image} onClose={handleCloseImage} />
    </Page>
  )
}

export default Project
