import React, { useState } from 'react'
import { Button, useToast, IButtonProps } from '@aurecon-creative-technologies/styleguide'
import ArchiveCommentModal from './ArchiveCommentModal'
import { IComment, ICommentArchiveProps, archiveComment } from '../api/comments'
import { useRefreshComments, CommentUploading } from '../stores/commentStore'
import { useRecoilState } from 'recoil'
interface ArchiveButtonProps {
  comment: IComment
  size?: IButtonProps['size']
}
const ArchiveButton: React.FC<ArchiveButtonProps> = ({ comment, size }) => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [commentUploading, setCommentUploading] = useRecoilState(CommentUploading)
  const refreshComments = useRefreshComments()
  const { addToast } = useToast()

  const handleArchiveModal = () => setIsArchiveModalOpen(true)

  const handleCancelArchive = () => setIsArchiveModalOpen(false)

  const handleArchiveComment = async () => {
    if (!comment) {
      addToast({
        type: 'error',
        message: 'Invalid comment data. Unable to archive / restore.',
        timeout: 4000,
      })
      setCommentUploading(false)
      setIsArchiveModalOpen(false)
      return
    }

    setCommentUploading(true)
    const request: ICommentArchiveProps = {
      phaseId: comment.phaseId,
      commentId: comment.commentId,
      archived: !comment.archived,
    }

    await archiveComment(request)
    setCommentUploading(false)
    setIsArchiveModalOpen(false)
    refreshComments()
  }

  const archiveLabel = comment.archived ? 'Restore' : 'Archive'

  return (
    <>
      <Button
        label={archiveLabel}
        type='secondary'
        size={size}
        loading={commentUploading}
        onClick={handleArchiveModal}
      />
      <ArchiveCommentModal
        isOpen={isArchiveModalOpen}
        onConfirm={handleArchiveComment}
        onCancel={handleCancelArchive}
        isArchiving={!comment.archived}
      />
    </>
  )
}

export default ArchiveButton
