import { Loader } from '@aurecon-creative-technologies/styleguide'
import React, { FC, useEffect, useState } from 'react'

const ImageLoader: FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ src, ...props }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const img = new Image()

    const setImageWrapper = () => {
      setLoading(false)
    }

    const setErrorImageWrapper = () => {
      setError(true)
    }

    img.src = src as string

    img.addEventListener('load', setImageWrapper)
    img.addEventListener('error', setErrorImageWrapper)

    return () => {
      img.removeEventListener('load', setImageWrapper)
      img.removeEventListener('error', setErrorImageWrapper)
    }
  }, [src])

  if (error) return <p>Error loading image...</p>

  if (loading) return <Loader label='Loading image...' />

  return <img {...props} src={src} />
}

export default ImageLoader
