import { Loader } from '@aurecon-creative-technologies/styleguide'
import React, { FC } from 'react'
import { ReactComponent as AlignLogo } from '../assets/align_logo.svg'
import Style from '../styles/Login.module.sass'

interface ILoadingScreenProps {
  text?: string
}

const LoadingScreen: FC<ILoadingScreenProps> = (props) => {
  return (
    <div className={Style.loader}>
      <AlignLogo height={40} />
      <br />
      <Loader label={props.text || 'Loading...'} />
    </div>
  )
}

export default LoadingScreen
