import { useCallback, useEffect, useState, useMemo } from 'react'
import { useRecoilValueLoadable, useRecoilStateLoadable, useRecoilValue, useRecoilState } from 'recoil'
import { SectionSize, Sections } from '../stores/sectionStore'
import { NavFrameTab, BoundsPadding } from '../stores/uiStore'
import { MIN_CHAINAGE } from '../config/config'
import { CurrentMeasurement, Measuring, Measurements } from '../stores/measureStore'
import { MeasureHoverProps } from '../components/MapMeasure'

import { bbox, lineString } from '@turf/turf'
import { LngLat, Point, MapMouseEvent, Map } from 'mapbox-gl'
import { PhaseSources } from '../stores/layerStore'
import { getMap } from '../components/HybridMap/HybridMap'

type MouseDown = 'none' | 'left' | 'centre' | 'right'

export type Bounds = [[number, number], [number, number]]

const distanceLngLatSq = (p1: number[], p2: number[]) => {
  return Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)
}

export const getClosestIndexToHighlight = (data: number[][], start: number, end: number, pt: LngLat): number => {
  // Determine closest point on highlight line to mouse
  let minDist = 10000
  let closestIndex = -1

  for (let i = start; i < end; i++) {
    const dist = distanceLngLatSq([pt.lng, pt.lat], data[i])
    if (dist < minDist) {
      minDist = dist
      closestIndex = i
    }
  }

  return closestIndex
}

interface IMoveSection {
  onMouseDown: (e: MapMouseEvent) => void
  onMouseMove: (e: MapMouseEvent) => void
  onMouseUp: () => void
  mouseDownOnSection: MouseDown
  mouseOverSection: boolean
  mouseOverLayer: boolean
  measureDragIndex: number
  measureHover: MeasureHoverProps | null
  mouseDownPoint: Point
  setMeasureHover: (o: MeasureHoverProps | null) => void
}

export const useMoveSection = (loaded: boolean): IMoveSection => {
  const [mouseIndex, setMouseIndex] = useState(-1)
  const [measureDragIndex, setMeasureDragIndex] = useState(-1)
  const [mouseOverSection, setMouseOverSection] = useState(false)
  const [mouseOverLayer, setMouseOverLayer] = useState(false)
  const [mouseDownOnSection, setMouseDownOnSection] = useState<MouseDown>('none')
  const [measureHover, setMeasureHover] = useState<MeasureHoverProps | null>(null)
  const [sectionSize, setSectionSize] = useRecoilStateLoadable(SectionSize)
  const [mouseDownPoint, setMouseDownPoint] = useState<Point>(new Point(0, 0))
  const navFrameTab = useRecoilValue(NavFrameTab)
  const measuring = useRecoilValue(Measuring)
  const [currentMeasurement, setCurrentMeasurement] = useRecoilState(CurrentMeasurement)
  const sections = useRecoilValueLoadable(Sections)
  const allSources = useRecoilValueLoadable(PhaseSources)

  const interactiveSources = useMemo(() => {
    if (allSources.state !== 'hasValue' || !allSources.contents) return []

    return allSources.contents.filter((s) => s.popup !== '{}').map((s) => s.sourceId)
  }, [allSources])

  useEffect(() => {
    if (currentMeasurement.length === 0) setMeasureHover(null)
  }, [currentMeasurement])

  const changeSectionSize = useCallback(
    (left: boolean, startDragIndex: number, closestIndex: number) => {
      if (sections.state !== 'hasValue' || sectionSize.state !== 'hasValue' || navFrameTab.name !== 'Section')
        return false

      const mainSection = sections.contents.find((section) => section.isMain)
      if (!mainSection) return false

      if (closestIndex !== startDragIndex) {
        const diff = closestIndex - startDragIndex
        const coords = mainSection.data.features[0].geometry.coordinates
        const [startLength, endLength] = sectionSize.contents

        const newStart = Math.max(0, startLength + (left ? diff : 0))
        const startChainage = coords[newStart][3]

        const newEnd = Math.min(coords.length - 1, endLength + (!left ? diff : 0))
        const endChainage = coords[newEnd][3]

        if (endChainage - startChainage >= MIN_CHAINAGE) {
          setSectionSize([newStart, newEnd])
          return true
        }
      }

      return false
    },
    [sections, sectionSize, setSectionSize, navFrameTab],
  )

  const onMouseDown = useCallback(
    (e: MapMouseEvent) => {
      setMouseDownPoint(e.point)
      if (e.originalEvent.button !== 0) return

      const map = getMap()
      if (!map) return

      if (measuring) {
        const query = map.queryRenderedFeatures(e.point, { layers: ['measure-circles'] })
        if (query.length > 0) {
          const index = query[0].properties?.index
          if (index >= 0) {
            setMeasureDragIndex(index)
            setMeasureHover(null)
          }
        }
      } else if (sections.state === 'hasValue' && sectionSize.state === 'hasValue' && navFrameTab.name === 'Section') {
        const mainSection = sections.contents.find((section) => section.isMain)
        if (!mainSection) return

        const query = map.queryRenderedFeatures(e.point, { layers: ['section', 'section-left', 'section-right'] })
        if (query.length) {
          setMouseIndex(
            getClosestIndexToHighlight(
              mainSection.data.features[0].geometry.coordinates,
              sectionSize.contents[0],
              sectionSize.contents[1],
              e.lngLat,
            ),
          )
          const ids = query.map((q) => q.layer.id)
          if (ids.indexOf('section-left') >= 0) setMouseDownOnSection('left')
          else if (ids.indexOf('section-right') >= 0) setMouseDownOnSection('right')
          else setMouseDownOnSection('centre')
        }
      }
    },
    [sections, sectionSize, navFrameTab, measuring, setMeasureDragIndex],
  )

  const moveSection = useCallback(
    (map: Map, e: MapMouseEvent) => {
      if (sections.state !== 'hasValue' || sectionSize.state !== 'hasValue') return

      const mainSection = sections.contents.find((section) => section.isMain)
      if (!mainSection) return

      const query = map.queryRenderedFeatures(e.point, { layers: ['section', 'section-left', 'section-right'] })
      setMouseOverSection(query.length > 0)

      if (mouseDownOnSection !== 'none') {
        const coords = mainSection.data.features[0].geometry.coordinates
        const newIndex = getClosestIndexToHighlight(coords, 0, coords.length, e.lngLat)

        if (mouseDownOnSection === 'centre') {
          if (newIndex !== mouseIndex) {
            let diff = newIndex - mouseIndex
            const [startLength, endLength] = sectionSize.contents

            // Ensure index isn't out of bounds
            if (startLength + diff < 0) {
              diff = -startLength
            } else if (endLength + diff >= coords.length) {
              diff = coords.length - 1 - endLength
            }

            setSectionSize([startLength + diff, endLength + diff])
            setMouseIndex(newIndex)
          }
        } else {
          const moved = changeSectionSize(mouseDownOnSection === 'left', mouseIndex, newIndex)
          if (moved) setMouseIndex(newIndex)
        }
      }
    },
    [mouseIndex, sections, sectionSize, mouseDownOnSection, setSectionSize, changeSectionSize],
  )

  const queryMeasurementLayers = (map: Map, e: MapMouseEvent) => {
    const query = map.queryRenderedFeatures(e.point, {
      layers: [
        'measure-fill',
        'measure-circles',
        'measure-line',
        'existingMeasurementAreas',
        'existingMeasurementLines',
      ],
    })
    if (query.length > 0) {
      if (query[0].layer.id === 'measure-fill') {
        setMeasureHover({
          id: -1,
          pos: e.lngLat,
          mode: 'area',
        })
      } else if (query[0].layer.id === 'measure-line' || query[0].layer.id === 'measure-circles') {
        setMeasureHover({
          id: -1,
          pos: e.lngLat,
          mode: 'line',
        })
      } else if (query[0].layer.id === 'existingMeasurementAreas') {
        setMeasureHover({
          id: query[0].properties?.id,
          pos: e.lngLat,
          mode: 'area',
        })
      } else if (query[0].layer.id === 'existingMeasurementLines') {
        setMeasureHover({
          id: query[0].properties?.id,
          pos: e.lngLat,
          mode: 'line',
        })
      }
    } else setMeasureHover(null)
  }

  const onMouseMove = useCallback(
    (e: MapMouseEvent) => {
      const map = getMap()
      if (!map || !loaded) return

      if (measureDragIndex >= 0) {
        setCurrentMeasurement((m) =>
          m.map((pt, i) => {
            return i === measureDragIndex ? [e.lngLat.lng, e.lngLat.lat] : pt
          }),
        )
      } else if (navFrameTab.name !== 'Section' && !measuring) {
        let features = map.queryRenderedFeatures(e.point)
        features = features.filter((f) => interactiveSources.includes(f.source))
        setMouseOverLayer(features.length > 0)
      } else if (navFrameTab.name === 'Section' && !measuring) {
        moveSection(map, e)
      } else {
        queryMeasurementLayers(map, e)
      }
    },
    [navFrameTab, measuring, measureDragIndex, setCurrentMeasurement, loaded, moveSection, interactiveSources],
  )

  const onMouseUp = useCallback(() => {
    setMouseDownOnSection('none')
    setMeasureDragIndex(-1)
  }, [])

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    mouseDownOnSection,
    mouseOverSection,
    mouseOverLayer,
    measureDragIndex,
    measureHover,
    mouseDownPoint,
    setMeasureHover,
  }
}

type UseMeasurements = (id: number) => void

export const useMeasurements = (): UseMeasurements => {
  const measurements = useRecoilValue(Measurements)
  const boundsPadding = useRecoilValue(BoundsPadding)

  return useCallback(
    (id: number) => {
      const measure = measurements.find((m) => m.id === id)
      if (!measure) return

      const shape = lineString(measure.points)
      const bounds = bbox(shape) as [number, number, number, number]
      getMap()?.fitBounds(bounds, { padding: boundsPadding })
    },
    [boundsPadding, measurements],
  )
}
