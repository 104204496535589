import React, { FC, useMemo } from 'react'
import { bearing, LineString, point, transformTranslate } from '@turf/turf'
import { HighlightIndex, Sections, SectionSize } from '../stores/sectionStore'
import { useRecoilValueLoadable, useRecoilValue } from 'recoil'
import { Feature } from 'geojson'
import { HybridLayer } from './HybridMap/HybridLayer'
import { Zoom } from '../stores/mapStore'

const HIGHLIGHT_ENDS_LENGTH = 0.15
const HIGHLIGHT_ENDS_WIDTH = 6
const HIGHLIGHT_POWER_FACTOR = 3.2
const HIGHLIGHT_MAX_ZOOM = 25.5
const HIGHLIGHT_ANGLE = 90

const highlightEndPaint = { 'line-color': 'rgb(132, 189, 20)', 'line-width': HIGHLIGHT_ENDS_WIDTH }
const highlightLinePaint = { 'line-color': 'rgba(132, 189, 0, 0.7)', 'line-width': HIGHLIGHT_ENDS_WIDTH * 2 }
const hoverLinePaint = { 'line-color': 'rgb(112, 169, 0)', 'line-width': HIGHLIGHT_ENDS_WIDTH / 2 }

const SectionHighlight: FC = () => {
  const sectionSize = useRecoilValueLoadable(SectionSize)
  const sections = useRecoilValueLoadable(Sections)
  const highlightIndex = useRecoilValue(HighlightIndex)
  const zoom = useRecoilValue(Zoom)

  const sectionData = useMemo(() => {
    if (sections.state === 'hasValue') {
      const mainSection = sections.contents.find((section) => section.isMain)
      if (mainSection) return mainSection.data.features[0]
    }

    return null
  }, [sections])

  const sectionElements = useMemo(() => {
    if (sectionData !== null && sectionSize.state === 'hasValue') {
      const data = { ...sectionData, type: 'LineString' }
      data.geometry = {
        coordinates: data.geometry.coordinates.slice(sectionSize.contents[0], sectionSize.contents[1] + 1),
      }

      const lineLength = Math.pow(HIGHLIGHT_MAX_ZOOM - zoom, HIGHLIGHT_POWER_FACTOR) * HIGHLIGHT_ENDS_LENGTH

      const leftEndCoords: number[][] = []
      const rightEndCoords: number[][] = []
      if (data.geometry.coordinates.length > 1) {
        const firstSegmentBearing = bearing(data.geometry.coordinates[0], data.geometry.coordinates[1])
        leftEndCoords.push(
          transformTranslate(point(data.geometry.coordinates[0]), lineLength, firstSegmentBearing + HIGHLIGHT_ANGLE, {
            units: 'metres',
          }).geometry!.coordinates,
        )
        leftEndCoords.push(
          transformTranslate(point(data.geometry.coordinates[0]), lineLength, firstSegmentBearing - HIGHLIGHT_ANGLE, {
            units: 'metres',
          }).geometry!.coordinates,
        )

        const lastSegmentBearing = bearing(
          data.geometry.coordinates[data.geometry.coordinates.length - 2],
          data.geometry.coordinates[data.geometry.coordinates.length - 1],
        )
        rightEndCoords.push(
          transformTranslate(
            point(data.geometry.coordinates[data.geometry.coordinates.length - 1]),
            lineLength,
            lastSegmentBearing + HIGHLIGHT_ANGLE,
            { units: 'metres' },
          ).geometry!.coordinates,
        )
        rightEndCoords.push(
          transformTranslate(
            point(data.geometry.coordinates[data.geometry.coordinates.length - 1]),
            lineLength,
            lastSegmentBearing - HIGHLIGHT_ANGLE,
            { units: 'metres' },
          ).geometry!.coordinates,
        )
      }

      const highlightLine: Feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: data.geometry.coordinates,
        },
      }

      const leftHighlightEnd: Feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: leftEndCoords,
        },
      }

      const rightHighlightEnd: Feature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: rightEndCoords,
        },
      }

      return (
        <>
          <HybridLayer
            data={leftHighlightEnd}
            id='section-left'
            type='line'
            paint={highlightEndPaint}
            sourceLayer={null}
          />
          <HybridLayer
            data={rightHighlightEnd}
            id='section-right'
            type='line'
            paint={highlightEndPaint}
            sourceLayer={null}
          />
          <HybridLayer data={highlightLine} id='section' type='line' paint={highlightLinePaint} sourceLayer={null} />
        </>
      )
    } else return null
  }, [sectionData, sectionSize, zoom])

  const hoverHighlight = useMemo(() => {
    const hoverLine: Feature<LineString> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    }

    if (highlightIndex.index >= 0 && sectionData) {
      const coords = sectionData.geometry.coordinates

      const index = Math.max(0, Math.min(coords.length - 2, highlightIndex.index))

      let lineBearing = 0
      if (index > 0) lineBearing = bearing(coords[index - 1], coords[index])
      else lineBearing = bearing(coords[index], coords[index + 1])

      const lineLength = Math.pow(HIGHLIGHT_MAX_ZOOM - zoom, HIGHLIGHT_POWER_FACTOR) * HIGHLIGHT_ENDS_LENGTH * 1.1

      hoverLine.geometry.coordinates.push(
        transformTranslate(point(coords[index]), lineLength, lineBearing + HIGHLIGHT_ANGLE, {
          units: 'metres',
        }).geometry!.coordinates,
      )
      hoverLine.geometry.coordinates.push(
        transformTranslate(point(coords[index]), lineLength, lineBearing - HIGHLIGHT_ANGLE, {
          units: 'metres',
        }).geometry!.coordinates,
      )
    }

    return <HybridLayer data={hoverLine} id='section-hover' type='line' paint={hoverLinePaint} sourceLayer={null} />
  }, [sectionData, highlightIndex, zoom])

  return (
    <>
      {sectionElements}
      {hoverHighlight}
    </>
  )
}

export default SectionHighlight
