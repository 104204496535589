import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { ProjectSortModeEnum } from '../enums/ProjectSortModeEnum'
import { useSetRecoilState } from 'recoil'
import { IProjectFilter, IProjectSorting } from '../api/projects'
import { FilterProjectState } from '../stores/projectStore'
import { Button, FormInput, Grid } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/SearchMyProjectComponent.module.sass'

const SearchMyProjectComponent: FC = () => {
  const sorts = [
    {
      sortMode: ProjectSortModeEnum.UnSort,
      title: 'No Sort',
      selected: true,
    },
    {
      sortMode: ProjectSortModeEnum.AlphabetAsc,
      title: 'Alphabetically',
      selected: false,
    },
  ]

  const setFilteredProjectContext = useSetRecoilState(FilterProjectState)
  const [filter, setFilter] = useState<IProjectFilter>()
  const [projectSorts] = useState<IProjectSorting[]>(sorts)
  const [textSearch, setTextSearch] = useState<string>()
  const [searchChanged, setSearchChanged] = useState<boolean>(false)
  const [sortMode, setSortMode] = useState<ProjectSortModeEnum>(ProjectSortModeEnum.UnSort)

  useEffect(() => {
    setFilteredProjectContext(null)
  }, [setFilteredProjectContext])

  const onSearchChange = (textSearch: string) => {
    setTextSearch(textSearch)
    setSearchChanged(true)
    setFilter({
      textSearch,
      sortMode,
    })
  }

  const onSearchKeydown = (key: string) => {
    if (key === 'Enter') onSearch()
  }

  const onSearch = () => {
    setSearchChanged(false)
    setFilteredProjectContext(filter as IProjectFilter)
  }

  const onSorting = useCallback(
    (sortMode: ProjectSortModeEnum) => {
      setSortMode(sortMode)
      setFilter({
        textSearch: textSearch ? textSearch : '',
        sortMode: sortMode,
      })
    },
    [textSearch, setSortMode, setFilter],
  )

  const onSortClick = useCallback(
    async (projectSorting: IProjectSorting) => {
      await onSorting(projectSorting.sortMode)

      if (filter?.sortMode !== projectSorting.sortMode || searchChanged) {
        setSearchChanged(false)
        setFilteredProjectContext({
          textSearch: textSearch ? textSearch : '',
          sortMode: projectSorting.sortMode,
        } as IProjectFilter)
      }
    },
    [searchChanged, filter, textSearch, onSorting, setFilteredProjectContext],
  )

  const sortButtons = useMemo(() => {
    return projectSorts.map((sort) => (
      <Button
        key={sort.sortMode}
        type={sort.sortMode === sortMode ? 'primary' : 'secondary'}
        cssClass={Style.sortButton}
        label={sort.title}
        onClick={() => onSortClick(sort)}
      />
    ))
  }, [projectSorts, sortMode, onSortClick])

  return (
    <Grid row gap={6}>
      <Grid item xs={11} lg={3}>
        <FormInput
          type='text'
          placeholder='Search for a project'
          onChange={onSearchChange}
          onKeyDown={(key) => onSearchKeydown(key)}
          value={filter?.textSearch}
          cssClass={Style.searchInput}
        />
      </Grid>
      <Grid xs={1} item style={{ alignContent: 'flex-end' }}>
        <Button type='icon-square' icon='search' size='small' onClick={onSearch} />
      </Grid>
      <Grid item xs={12} lg={6} style={{ alignContent: 'flex-end' }}>
        <span className={Style.sortLabel}>Sort:</span>
        {sortButtons}
      </Grid>
    </Grid>
  )
}

export default SearchMyProjectComponent
