import React, { FC } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { isGlobalAdmin } from '../helpers/appRoles'

const AdminRoute: FC<RouteProps> = (props) => {
  const { user } = useAuth0()

  if (!user || !isGlobalAdmin(user)) return <Redirect to='/' push />

  return <Route {...props} />
}

export default AdminRoute
