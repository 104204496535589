import React, { FC, useState, ReactText, useMemo } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { ProjectCategories } from '../stores/projectStore'
import { useRefreshComments, CommentFilters, NO_FILTERS } from '../stores/commentStore'
import { Button, Dropdown, Icon, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { DateRange } from 'react-date-range'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import addYears from 'date-fns/addYears'
import subYears from 'date-fns/subYears'

import { CommentFiltersEnum } from '../enums/CommentFiltersEnum'

import Style from '../styles/CommentsFilter.module.sass'

const DEFAULT_OPTION = '-1'
const SELECTED_OPTION = '0'

export const VISIBILITY_OPTIONS = [
  { id: DEFAULT_OPTION, label: 'All' },
  { id: CommentFiltersEnum.VISIBILITY_INTERNAL, label: 'Internal team' },
  { id: CommentFiltersEnum.VISIBILITY_PUBLIC, label: 'Public' },
]

export const ASSIGNED_OPTIONS = [
  { id: DEFAULT_OPTION, label: 'All' },
  { id: '-2', label: 'Assigned to me' },
]

export const ATTACHMENT_OPTIONS = [
  { id: DEFAULT_OPTION, label: 'All' },
  { id: '-2', label: 'Yes' },
  { id: '-3', label: 'No' },
]

export const CATEGORY_OPTIONS = [{ id: DEFAULT_OPTION, label: 'All' }]

export const STATUS_OPTIONS = [
  { id: DEFAULT_OPTION, label: 'All' },
  { id: '-2', label: 'Assigned' },
  { id: '-3', label: 'Unassigned' },
]

interface IOptions {
  id: string
  label: string
}

const CommentsFilters: FC = () => {
  const projectCategories = useRecoilValue(ProjectCategories)
  const [filters, setFilters] = useRecoilState(CommentFilters)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const refreshComments = useRefreshComments()

  const categoryOptions = useMemo(() => {
    const uniqueCategories = new Set<IOptions>(CATEGORY_OPTIONS)
    projectCategories?.forEach((cat) => {
      if (cat.id) uniqueCategories.add({ id: cat.id, label: cat.categoryName })
    })
    return Array.from(uniqueCategories)
  }, [projectCategories])

  // eslint-disable-next-line
  const handleDateRange = (item: any) => {
    setFilters({
      ...filters,
      dateFilter: true,
      dateRange: [item.selection],
    })
  }

  const handleFilterChange = (filterName: string, id: ReactText) => {
    if (id === DEFAULT_OPTION) {
      setFilters({
        ...filters,
        [filterName]: [],
      })
      return
    }

    if (!filters[filterName].includes(id)) {
      setFilters({
        ...filters,
        [filterName]: [...filters[filterName], id],
      })
    }
  }

  const dropdownLabel = (title: string, filterName: string, dropdown: IDropdownItemProps[]) => {
    if (filters[filterName].length) return <span className={Style.activeFilterSelection}>{title}</span>

    const option = dropdown.filter((item) => item.id === DEFAULT_OPTION)
    return (
      <span className={Style.inactiveFilterSelection}>
        {title} <strong>{option[0].label}</strong>
      </span>
    )
  }

  const dropdownItems = (filterName: string, items: IDropdownItemProps[]) => {
    return items.map((item) => {
      return {
        ...item,
        disabled: filters[filterName].includes(item.id),
      }
    })
  }

  const DATE_FORMAT = 'dd/MM/yyyy'

  const renderDates = () => {
    const dr = filters.dateRange && filters.dateRange[0]
    if (!dr || !filters.dateFilter) return 'Select dates'
    if (!dr.endDate) return format(dr.startDate, DATE_FORMAT)
    if (dr && isSameDay(dr.startDate, dr.endDate)) return format(dr.startDate, DATE_FORMAT)
    return `${format(dr.startDate, DATE_FORMAT)} - ${format(dr.endDate, DATE_FORMAT)}`
  }

  const handleClearFilters = () => {
    setFilters(NO_FILTERS)
    refreshComments()
  }

  const handleClearDateRange = () => {
    setFilters({
      ...filters,
      dateFilter: false,
    })
  }

  return (
    <div className={Style.filterWrapper}>
      <p>Filter:</p>
      <Dropdown
        items={[
          { id: SELECTED_OPTION, label: dropdownLabel('Visibility', 'visibility', VISIBILITY_OPTIONS) },
          ...dropdownItems('visibility', VISIBILITY_OPTIONS),
        ]}
        onSelectItem={(id) => handleFilterChange('visibility', id)}
        cssClass='is-aal-filter'
        selectedItem={SELECTED_OPTION}
        itemToHide={SELECTED_OPTION}
      />
      <Dropdown
        items={[
          { id: SELECTED_OPTION, label: dropdownLabel('Assigned', 'assigned', ASSIGNED_OPTIONS) },
          ...dropdownItems('assigned', ASSIGNED_OPTIONS),
        ]}
        onSelectItem={(id) => handleFilterChange('assigned', id)}
        cssClass='is-aal-filter'
        selectedItem={SELECTED_OPTION}
        itemToHide={SELECTED_OPTION}
      />
      <Dropdown
        items={[
          { id: '0', label: dropdownLabel('Attachments', 'attachments', ATTACHMENT_OPTIONS) },
          ...dropdownItems('attachments', ATTACHMENT_OPTIONS),
        ]}
        onSelectItem={(id) => handleFilterChange('attachments', id)}
        cssClass='is-aal-filter'
        selectedItem={SELECTED_OPTION}
        itemToHide={SELECTED_OPTION}
      />
      <Dropdown
        items={[
          { id: SELECTED_OPTION, label: dropdownLabel('Category', 'category', categoryOptions) },
          ...dropdownItems('category', categoryOptions),
        ]}
        onSelectItem={(id) => handleFilterChange('category', id)}
        cssClass='is-aal-filter'
        selectedItem={SELECTED_OPTION}
        itemToHide={SELECTED_OPTION}
      />
      <Dropdown
        items={[
          { id: SELECTED_OPTION, label: dropdownLabel('Status', 'status', STATUS_OPTIONS) },
          ...dropdownItems('status', STATUS_OPTIONS),
        ]}
        onSelectItem={(id) => handleFilterChange('status', id)}
        cssClass='is-aal-filter'
        selectedItem={SELECTED_OPTION}
        itemToHide={SELECTED_OPTION}
      />
      <div className={Style.datePickerWrapper}>
        <Button
          label={renderDates()}
          icon='calendar_today'
          size='small'
          default
          cssClass='is-date'
          onClick={() => setShowDatePicker(true)}
          title='Open calendar'
        />
        {filters.dateFilter && (
          <Button
            type='icon-square'
            icon='clear'
            size='small'
            default
            cssClass='is-date'
            onClick={handleClearDateRange}
          />
        )}
        {showDatePicker && (
          <div className={Style.datePickerLayer}>
            <div className={Style.datePickercloseButton}>
              <Icon type='close' onClick={() => setShowDatePicker(false)} />
            </div>
            <div className={Style.datePickerOverlay} onClick={() => setShowDatePicker(false)} />
            <DateRange
              className='date-picker-wrapper'
              editableDateInputs={false}
              moveRangeOnFirstSelection={false}
              onChange={handleDateRange}
              ranges={filters.dateRange}
              rangeColors={['#1916C0']}
              maxDate={addYears(new Date(), 5)}
              minDate={subYears(new Date(), 5)}
            />
          </div>
        )}
      </div>
      <p className={Style.clearFilters}>
        <button onClick={handleClearFilters}>Clear Filters</button>
      </p>
    </div>
  )
}

export default CommentsFilters
