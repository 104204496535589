import { TokenExpiryWrapper, ProjectReadScope } from './TokenManager'
import { headersWithToken, IResponse } from '../helpers/utils'
import {
  IRequestTokenProps,
  IRequestEmailProps,
  IRequestUserProps,
  IRequestRemoveUserProps,
  IPatchUserProps,
  IResendInviteProps,
} from './apiprops'

export interface IUser {
  projectMemberId: string
  user_id: string
  name: string
  email: string
}

export interface IAddAdminResponse {
  success: boolean
  reason: string
}

const getGlobalAdminsRequest = async (props: IRequestTokenProps): Promise<IUser[] | null> => {
  const response = await fetch('/users/admins', {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const getGlobalAdmins = TokenExpiryWrapper(getGlobalAdminsRequest, [ProjectReadScope], null)

const addAdminRequest = async (props: IRequestEmailProps): Promise<IAddAdminResponse> => {
  const response = await fetch('/users/admins', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ email: props.email }),
  })

  if (!response.ok) {
    throw response
  }

  return {
    success: true,
    reason: `An email has been sent to ${props.email} and they have been added as a global admin.`,
  }
}
export const addAdmin = TokenExpiryWrapper(addAdminRequest, [ProjectReadScope], {
  success: false,
  reason: 'Add user request could not be completed. Please wait 30 seconds and try again.',
})

const removeAdminRequest = async (props: IRequestEmailProps): Promise<IAddAdminResponse> => {
  const response = await fetch('/users/admins/', {
    method: 'delete',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ email: props.email }),
  })

  if (!response.ok) {
    throw response
  }

  return {
    success: true,
    reason: `An email has been sent to ${props.email} and they have been removed as a global admin.`,
  }
}
export const removeAdmin = TokenExpiryWrapper(removeAdminRequest, [ProjectReadScope], {
  success: false,
  reason: 'Remove admin request could not be completed. Please wait 30 seconds and try again.',
})

export const queryUsersRequest = async (props: IRequestEmailProps): Promise<IUser[] | null> => {
  const response = await fetch(`/users/query/${props.email}`, {
    headers: headersWithToken(props.token),
  })
  if (response.ok) return response.json()
  throw response
}
export const queryUsers = TokenExpiryWrapper(queryUsersRequest, [ProjectReadScope], null)

const addUserToPhaseRequest = async (props: IRequestUserProps): Promise<boolean | null> => {
  const { email, userId, phaseId, userRole, name, categoryId = null } = props
  const response = await fetch(`/users/phase`, {
    method: 'POST',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ email, userId, phaseId, userRole, name, categoryId }),
  })
  if (response.ok) return true
  throw response
}
export const addUserToPhase = TokenExpiryWrapper(addUserToPhaseRequest, [ProjectReadScope], null)

const removeUserFromPhaseRequest = async (props: IRequestRemoveUserProps): Promise<IResponse> => {
  const { projectMemberId, phaseId } = props
  const response = await fetch(`/users/phase`, {
    method: 'DELETE',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ projectMemberId, phaseId }),
  })
  if (response.ok) return { success: true, message: '' }
  else return response.json()
}

export const removeUserFromPhase = TokenExpiryWrapper(removeUserFromPhaseRequest, [ProjectReadScope], {
  success: false,
  message: 'Error removeUserFromPhase - token error',
})

const resendInviteRequest = async (props: IResendInviteProps): Promise<boolean | null> => {
  const { email, userId, phaseId, name } = props
  const response = await fetch(`/users/resend-invite`, {
    method: 'POST',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ email, userId, phaseId, name }),
  })
  return response.ok
}
export const resendInvite = TokenExpiryWrapper(resendInviteRequest, [], null)

const patchUserRequest = async (props: IPatchUserProps): Promise<IResponse> => {
  const { token, ...body } = props
  const response = await fetch(`/users`, {
    method: 'PATCH',
    headers: headersWithToken(token),
    body: JSON.stringify(body),
  })
  if (response.ok) return { success: true, message: '' }
  else return { success: false, message: 'Error patchUserRequest' }
}

export const patchUser = TokenExpiryWrapper(patchUserRequest, [], {
  success: false,
  message: 'Error patchUser - token error',
})
