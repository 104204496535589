import React, { FC, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Grid, Loader, Pagination } from '@aurecon-creative-technologies/styleguide'

import { ProjectDetails } from '../stores/projectStore'
import ProjectCard from './ProjectCard'
import { IProjectData, IPhaseData } from '../api/projects'
import PageError from './PageError'

const PAGE_SIZE = 12

interface IMyProjectCardProps {
  projects: IProjectData[]
  state: 'loading' | 'hasValue' | 'hasError'
  error: string
}

interface IPhaseDataTitle extends IPhaseData {
  projectTitle: string
}

const ProjectTileView: FC<IMyProjectCardProps> = (props) => {
  const projectDetails = useRecoilValue(ProjectDetails)
  const [allPhases, setAllPhases] = useState<IPhaseDataTitle[]>([])
  const [phases, setPhases] = useState<IPhaseDataTitle[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(1)

  useEffect(() => {
    const phaseList: IPhaseDataTitle[] = []
    props.projects.forEach((project) => {
      project.Phases.forEach((phase) => {
        phaseList.push({
          ...phase,
          projectTitle: project.title,
        })
      })
    })

    setAllPhases(phaseList)
    setPage((page) => Math.max(1, Math.min(page, Math.ceil(phaseList.length / PAGE_SIZE))))
  }, [props.projects])

  useEffect(() => {
    const pCount = allPhases.length > 0 ? Math.ceil(allPhases.length / PAGE_SIZE) : 1
    setPageCount(pCount)

    const start = (page - 1) * PAGE_SIZE
    const end = start + PAGE_SIZE
    const list = allPhases.slice(start, end)
    setPhases(list)
  }, [allPhases, page])

  if (props.state === 'loading') {
    return (
      <Grid gap={10} row style={{ marginTop: '24px' }}>
        <div style={{ margin: '0 auto', width: '160px' }}>
          <Loader label='Loading projects...' />
        </div>
      </Grid>
    )
  }

  if (allPhases.length === 0) {
    return (
      <Grid gap={10} row style={{ marginTop: '24px' }}>
        <div style={{ margin: '0 auto', width: '160px' }}>
          <p>There are no projects</p>
        </div>
      </Grid>
    )
  }

  return (
    <>
      {props.state === 'hasError' && (
        <Grid gap={10} row style={{ marginTop: '24px' }}>
          <PageError message='Failed to load projects' code='101' error={props.error} />
        </Grid>
      )}

      <Grid gap={10} row style={{ marginTop: '24px' }}>
        {phases.map((phase) => (
          <Grid item key={phase.id} xs={12} sm={6} md={6} lg={3} style={{ flexDirection: 'column' }}>
            <ProjectCard
              phase={phase}
              projectTitle={phase.projectTitle}
              slug={phase.slug}
              active={projectDetails?.phaseId === phase.id}
            />
          </Grid>
        ))}
      </Grid>
      <Grid row gap={12}>
        <Grid item style={{ justifyContent: 'center', marginTop: '32px' }}>
          <Pagination page={page} pageCount={pageCount} onChange={(i) => setPage(i)} />
        </Grid>
      </Grid>
    </>
  )
}

export default ProjectTileView
