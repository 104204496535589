import { Button, Container, Grid } from '@aurecon-creative-technologies/styleguide'
import React, { FC, useLayoutEffect } from 'react'
import Page from './Page'

import Style from '../styles/ContentPage.module.sass'

const backToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

interface IContentPage {
  hideBackToTop?: boolean
}

const ContentPage: FC<IContentPage> = (props) => {
  useLayoutEffect(() => {
    backToTop()
  }, [props.children])

  return (
    <Page background>
      <Container>
        <Grid row>
          <Grid xs={12} lg={2} />
          <Grid xs={12} lg={8}>
            <div className={Style['content']}>{props.children}</div>
          </Grid>
          <Grid xs={12} lg={2} />
        </Grid>
        {!props.hideBackToTop && (
          <div className={Style['back-to-top']}>
            <Button type='secondary' label='Back to top' onClick={backToTop} />
          </div>
        )}
      </Container>
    </Page>
  )
}

export default ContentPage
