import {
  CirclePaint,
  FillPaint,
  LinePaint,
  RasterPaint,
  LineLayer,
  FillLayer,
  CircleLayer,
  RasterLayer,
  VectorSource,
  GeoJSONSource,
  GeoJSONSourceRaw,
  AnyLayout,
} from 'mapbox-gl'
import { FC, useEffect, useRef } from 'react'
import { getMap } from './HybridMap'

interface IHybridLayerProps {
  id: string
  type: 'line' | 'circle' | 'fill' | 'raster'
  url?: string
  data?: any //eslint-disable-line
  layout?: AnyLayout
  paint?: LinePaint | CirclePaint | FillPaint | RasterPaint
  beforeId?: string
  sourceLayer: string | null
}

export const HybridLayer: FC<IHybridLayerProps> = (props) => {
  const layer = useRef<FillLayer | LineLayer | CircleLayer | RasterLayer>()

  useEffect(() => {
    if (layer.current) return

    const map = getMap()
    if (!map) return

    const isRaster = props.type === 'raster'

    layer.current = {
      id: props.id,
      type: props.type,
      source: {
        type: isRaster ? 'raster' : 'vector',
      },
    } as FillLayer | LineLayer | CircleLayer | RasterLayer

    if (props.url) {
      layer.current['source-layer'] = props.sourceLayer || 'default'
      const source = layer.current.source as VectorSource
      source.url = props.url
    } else if (props.data) {
      const source = layer.current.source as GeoJSONSourceRaw
      source.type = 'geojson'
      source.data = props.data
    }

    if (props.paint) layer.current.paint = props.paint

    if (props.layout) layer.current.layout = props.layout

    map.addLayer(layer.current, props.beforeId)
  })

  useEffect(() => {
    if (layer.current && props.data) {
      const map = getMap()
      if (map) {
        const source = map.getSource(layer.current.id) as GeoJSONSource
        source.setData(props.data)
      }
    }
  }, [props.data])

  useEffect(() => {
    if (layer.current && props.paint) {
      const map = getMap()
      if (map) {
        Object.entries(props.paint).forEach(([key, value]) => {
          map.setPaintProperty(layer.current!.id, key, value)
        })
      }
    }
  }, [props.paint])

  useEffect(() => {
    if (layer.current && props.layout) {
      const map = getMap()
      if (map) {
        Object.entries(props.layout).forEach(([key, value]) => {
          map.setLayoutProperty(layer.current!.id, key, value)
        })
      }
    }
  }, [props.layout])

  useEffect(() => {
    return () => {
      if (layer.current) {
        const map = getMap()
        if (map) {
          map.removeLayer(layer.current.id)
          map.removeSource(layer.current.id)
        }
      }
    }
  }, [])

  return null
}
