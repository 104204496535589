import React, { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Pill, Icon } from '@aurecon-creative-technologies/styleguide'
import { Link } from 'react-router-dom'
import { SizeMe } from 'react-sizeme'

import { ICategoryData, IPhaseData } from '../api/projects'
import { UserRolesEnum } from '../enums/UserRolesEnum'
import { isGlobalAdmin } from '../helpers/appRoles'

import Style from '../styles/ProjectCard.module.sass'

interface IMyProjectCardProps {
  projectTitle: string
  slug?: string
  phase: IPhaseData
  active: boolean
}

type CategoryColour = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const MAX_ADMIN_CHARACTER_COUNT = 50

const ProjectCard: FC<IMyProjectCardProps> = (props) => {
  const { user } = useAuth0()
  const phase = props.phase

  const getAdmins = () => {
    let chars = 0
    const list = []

    for (let i = 0; i < phase.ProjectMembers.length; i++) {
      const { name = '', userId, userRole } = phase.ProjectMembers[i]

      if (userRole !== UserRolesEnum.PROJECT_ADMIN) continue

      if (chars + name.length > MAX_ADMIN_CHARACTER_COUNT) {
        const shortName = `${name.slice(0, MAX_ADMIN_CHARACTER_COUNT - chars)}...`
        list.push(<span key={userId}>{shortName}</span>)
        break
      } else {
        chars += name.length
        list.push(<span key={userId}>{name}</span>)
      }
    }

    return list
  }

  const isUserProjectAdmin = () => {
    if (isGlobalAdmin(user)) return true

    return phase.ProjectMembers.some((member) => {
      return (
        member.userId === user.sub &&
        (member.userRole === UserRolesEnum.PROJECT_ADMIN || member.userRole === UserRolesEnum.DATA_MANAGER)
      )
    })
  }

  const handleEditProjectClick = () => {
    window.location.hash = props.slug ? `/admin/project/${props.slug}` : `/admin/project/${props.phase.id}`
  }

  const cardClass = `${Style.card} ${props.active ? Style.activeCard : ''}`

  return (
    <>
      <div className={cardClass} style={{ marginBottom: isUserProjectAdmin() ? '3px' : '43px' }}>
        <Link
          className={Style.open}
          to={props.slug ? `/project/${props.slug}` : `/project/${phase.id}`}
          title='Open project'
        >
          <Icon type='launch' />
        </Link>
        <h4>
          {props.projectTitle} / {phase.phaseName}
        </h4>
        <div className={Style.admins}>
          <span>Project admin{phase.ProjectMembers.length === 1 ? '' : 's'}:</span>
          {phase.ProjectMembers.filter((member) => member.userRole === UserRolesEnum.PROJECT_ADMIN).length > 0 ? (
            getAdmins()
          ) : (
            <span>No Admins</span>
          )}
        </div>
        {phase.Categories.length > 0 && <CategoryList categories={phase.Categories} />}
      </div>
      {isUserProjectAdmin() && (
        <button className={Style.edit} onClick={handleEditProjectClick}>
          Edit Project Info
        </button>
      )}
    </>
  )
}

interface ICategoryListProps {
  categories: ICategoryData[]
}

const CategoryList: FC<ICategoryListProps> = ({ categories }) => {
  const EXPECTED_PILL_SIZE = 70 //Pill size for an average pill

  return (
    <SizeMe>
      {({ size }) => {
        const categoryCount = size.width ? Math.floor(size.width / EXPECTED_PILL_SIZE) : categories.length
        const visibleCategories = categories.slice(0, categoryCount)

        return (
          <div className={Style.categories}>
            <span>Categories:</span>
            {visibleCategories.map((cat: ICategoryData) => (
              <Pill key={cat.id} size='small' colour={cat.colour as CategoryColour} cssClass={Style.pill}>
                {cat.categoryName}
              </Pill>
            ))}
            {categoryCount < categories.length ? '...' : ''}
          </div>
        )
      }}
    </SizeMe>
  )
}

export default ProjectCard
