export const SourceTypeEnum = {
  CIRCLE: 0,
  LINE: 1,
  FILL: 2,
  RASTER: 3,
  RASTERLAYER: 4,
  HEATMAP: 5,
}

export const ReverseSourceTypeEnum = {
  0: { type: 'circle', label: 'point' },
  1: { type: 'line', label: 'line' },
  2: { type: 'fill', label: 'fill' },
  3: { type: 'raster', label: 'raster' },
  4: { type: 'raster', label: 'raster' },
  5: { type: 'heatmap', label: 'heatmap' },
}
