import { atom, selector, useSetRecoilState } from 'recoil'
import { getMyProjects, getAllProjects, getProjects, IProjectDetails, IProjectFilter } from '../api/projects'
import { getUniqueObjects } from '../helpers/utils'
import { IProjectPhaseWithCategories } from '../pages/Admin'
import { useCallback } from 'react'
import { getAppVersion } from '../api/app'
import { IProjectCategory } from '../api/categories'
import { ProjectSortModeEnum } from '../enums/ProjectSortModeEnum'

const groupCategoriesAndAdmins = (response: IProjectPhaseWithCategories[]) => {
  const projectPhases = getUniqueObjects(response, 'phaseId')
  return projectPhases.map((p) => {
    //Get aggregated phases with categories and users
    const categoryUsers = response.filter((ph) => ph.phaseId === p.phaseId)
    // Get unique list of categories
    const categories = getUniqueObjects(
      categoryUsers.filter((ph) => ph.categoryName && ph.categoryColour),
      'categoryName',
    ).map((ph) => {
      return {
        name: ph.categoryName as string,
        categoryColour: ph.categoryColour as number,
      }
    })
    // Get unique list of admins
    const admins = getUniqueObjects(
      categoryUsers.filter((ph) => ph.userId),
      'userId',
    ).map((ph) => ph.userId as string)
    // Return merged phase
    return {
      ...p,
      categories,
      admins,
    }
  })
}

const Refresh = atom({
  key: 'refresh_projects',
  default: 0,
})

export const useRefreshProjects = (): (() => void) => {
  const setRefresh = useSetRecoilState(Refresh)

  return useCallback(() => {
    setRefresh((val) => val + 1)
  }, [setRefresh])
}

export const Projects = selector({
  key: 'projects',
  get: async ({ get }) => {
    get(Refresh)
    const projs = (await getProjects({})) as IProjectPhaseWithCategories[]

    if (projs) {
      const groupedProjects = groupCategoriesAndAdmins(projs)
      return [...groupedProjects].sort((a, b) => a.title.localeCompare(b.title))
    }
    throw new Error()
  },
})

export const AllProjects = selector({
  key: 'allProjects',
  get: async ({ get }) => {
    get(Refresh)
    const projects = await getAllProjects({})

    if (!projects) throw new Error()

    return projects
  },
})

export const MyProjects = selector({
  key: 'myProjects',
  get: async ({ get }) => {
    get(Refresh)
    const projects = await getMyProjects({})

    if (!projects) throw new Error()

    return projects
  },
})

export const ProjectDetails = atom({
  key: 'projectDetails',
  default: null as IProjectDetails | null,
})

export const ProjectCategories = atom({
  key: 'projectCategories',
  default: null as IProjectCategory[] | null,
})

export const FilterProjectState = atom({
  key: 'filterProjectState',
  default: {
    textSearch: '',
    sortMode: ProjectSortModeEnum.UnSort,
  } as IProjectFilter | null,
})

export const AppVersion = selector({
  key: 'version',
  get: async () => {
    const response = await getAppVersion()
    return response.version
  },
})

export type ProjectContext = 'my_projects' | 'project' | 'global_admin_panel' | null

export const CurrentProjectContext = atom({
  key: 'currentProjectContext',
  default: null as ProjectContext,
})
