import React, { FC } from 'react'
import { Icon } from '@aurecon-creative-technologies/styleguide'
import CompassIcon from '../assets/compass.png'
import { ReactComponent as EarthIcon } from '../assets/earth.svg'
import { ReactComponent as PlusIcon } from '../assets/plus.svg'
import { ReactComponent as MinusIcon } from '../assets/minus.svg'
import { ReactComponent as CommentsVisibleIcon } from '../assets/comments_visible.svg'
import { ReactComponent as CommentsHiddenIcon } from '../assets/comments_hidden.svg'
import { ReactComponent as MeasureIcon } from '../assets/measure.svg'
import { MapStyle, MarkerVisibility } from '../stores/mapStore'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { RightDrawerOpen, useHandleDrawer, BoundsPadding } from '../stores/uiStore'
import { Measuring, MeasureMode } from '../stores/measureStore'
import { SelectedCommentId, NewCommentLocation, CommentUploading } from '../stores/commentStore'
import { Bearing, Zoom } from './../stores/mapStore'

import Style from '../styles/Map.module.sass'
import { getMap } from './HybridMap/HybridMap'
import { LngLatBoundsLike } from 'mapbox-gl'
import { SelectedExternalData } from '../stores/externalDataStore'

interface IMapControlsProps {
  startLocation: LngLatBoundsLike
}

const MapControls: FC<IMapControlsProps> = (props) => {
  const zoom = useRecoilValue(Zoom)
  const bearing = useRecoilValue(Bearing)
  const [mapStyle, setMapStyle] = useRecoilState(MapStyle)
  const [measuring, setMeasuring] = useRecoilState(Measuring)
  const [measuringMode, setMeasuringMode] = useRecoilState(MeasureMode)
  const drawerOpen = useRecoilValue(RightDrawerOpen)
  const boundsPadding = useRecoilValue(BoundsPadding)
  const [markerVisibility, setMarkerVisibility] = useRecoilState(MarkerVisibility)
  const setSelectedCommentId = useSetRecoilState(SelectedCommentId)
  const setNewCommentLocation = useSetRecoilState(NewCommentLocation)
  const setSelectedExternalData = useSetRecoilState(SelectedExternalData)
  const handleDrawer = useHandleDrawer()
  const commentUploading = useRecoilValue(CommentUploading)

  const onMeasureClick = () => {
    if (commentUploading) return

    if (!measuring) {
      setMeasuring(true)
      if (!measuringMode) setMeasuringMode('line')
    }

    handleDrawer(!measuring)
    setSelectedCommentId(null)
    setNewCommentLocation(null)
    setSelectedExternalData(null)
  }

  const onBearingClick = () => {
    getMap()?.rotateTo(0)
  }

  const onInitialLocationClick = () => {
    getMap()?.fitBounds(props.startLocation, { padding: boundsPadding })
  }

  return (
    <div className={`${Style.controls} ${drawerOpen ? Style.drawerOpen : ''}`}>
      <button title='Reset bearing' onClick={onBearingClick}>
        <img src={CompassIcon} alt='Compass' style={{ transform: `rotate(${-bearing}deg)` }} />
      </button>
      <button
        title='Toggle satellite view'
        onClick={() => setMapStyle(mapStyle === 'map' ? 'satellite' : 'map')}
        className={mapStyle === 'satellite' ? Style.satellite : undefined}
      >
        <EarthIcon fill={mapStyle === 'satellite' ? 'white' : 'black'} />
      </button>
      <div className={Style.zoom}>
        <button title='Zoom in' onClick={() => getMap()?.setZoom(zoom + 1)}>
          <PlusIcon />
        </button>
        <input
          type='range'
          min='1.2'
          max='21.8'
          step='0.1'
          value={zoom}
          onChange={(e) => getMap()?.setZoom(Number(e.currentTarget.value))}
        />
        <button title='Zoom out' onClick={() => getMap()?.setZoom(zoom - 1)}>
          <MinusIcon />
        </button>
      </div>
      <button title='Go to project location' className={Style.projectButton} onClick={onInitialLocationClick}>
        <Icon type='center_focus_strong' />
      </button>
      <button
        title={markerVisibility ? 'Hide comments' : 'Show comments'}
        className={Style.commentsButton}
        onClick={() => setMarkerVisibility((visible) => !visible)}
      >
        {markerVisibility ? <CommentsHiddenIcon /> : <CommentsVisibleIcon />}
      </button>
      <button
        title='Measure tools'
        className={`${Style.measureButton} ${measuring ? Style.active : ''}`}
        onClick={onMeasureClick}
      >
        <MeasureIcon />
      </button>
    </div>
  )
}

export default MapControls
