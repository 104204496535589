import { distance } from '@turf/turf'
import { atom, selector, waitForAll } from 'recoil'
import { ILayer } from '../api/layers'
import { MIN_CHAINAGE } from '../config/config'
import { LayerGroups, PhaseSources, SourceQuery, SelectedLayerId } from './layerStore'

export const HighlightIndex = atom({
  key: 'sectionHighlightIndex',
  default: { index: 0, seek: true },
})

export const VideoPlaying = atom({
  key: 'videoPlaying',
  default: false,
})

export const Sections = selector({
  key: 'sections',
  get: ({ get }) => {
    const layerGroups = get(LayerGroups)
    const layers = layerGroups.reduce((groupLayers, group) => {
      return [...groupLayers, ...group.Layers]
    }, [] as ILayer[])

    // Get list of source ids that are on sections
    const sourceIds: string[] = []
    layers.forEach((layer) =>
      layer.Sections.forEach((section) => {
        sourceIds.push(section.sourceId)
      }),
    )

    const sources = get(PhaseSources)
    if (!sources) return []

    const sectionSources = sources.filter((source) => sourceIds.indexOf(source.sourceId) >= 0)
    // Download all source data
    const sourceData = get(
      waitForAll(sectionSources.map((source) => SourceQuery({ phaseId: source.phaseId, filename: source.location }))),
    )

    const layer = layers.find((layer) => layer.layerId === get(SelectedLayerId))
    if (layer) {
      return layer.Sections.filter((section) => {
        const source = sources.find((source) => source.sourceId === section.sourceId)
        if (source) {
          const data = sourceData.find((data) => data.url === `${source.phaseId}/${source.location}`)?.data
          if (data) return true
        }
        return false
      }).map((section) => {
        const source = sources.find((source) => source.sourceId === section.sourceId)
        const data = sourceData.find((data) => data.url === `${source!.phaseId}/${source!.location}`)!.data!

        // Get chainage and append to coordinates array
        const coords = data.features[0].geometry.coordinates
        let chainage = 0
        const newCoords = coords.map((pos: number[], i: number) => {
          if (i === 0) {
            return [...pos, 0]
          } else {
            chainage += distance([coords[i - 1][0], coords[i - 1][1]], [coords[i][0], coords[i][1]], {
              units: 'meters',
            })
            return [...pos, chainage]
          }
        })

        // Merge in new coordinates
        return {
          ...section,
          data: {
            ...data,
            features: [
              {
                ...data.features[0],
                geometry: {
                  ...data.features[0].geometry,
                  coordinates: newCoords,
                },
              },
            ],
          },
        }
      })
    }

    return []
  },
})

export const SectionSize = atom({
  key: 'section_size',
  default: selector({
    key: 'section_size/default',
    get: ({ get }) => {
      const sections = get(Sections)
      if (sections[0]) {
        const coords = sections[0].data.features[0].geometry.coordinates
        for (let i = 0; i < coords.length; i++) {
          if (coords[i][3] > MIN_CHAINAGE) {
            return [0, i]
          }
        }
      }

      return [0, 100]
    },
  }),
})
