import { TokenExpiryWrapper } from './TokenManager'
import { headersWithToken } from '../helpers/utils'
import { ISourceShort } from './sources'
import { IDeleteLayer } from './apiprops'

export interface IFeatureCollection {
  features: {
    geometry: {
      coordinates: number[][]
    }
  }[]
}

export interface ILayer {
  layerId: string
  layerName: string
  colour: string
  active: boolean
  Sections: ISection[]
  LayerGroupLayers: {
    displayOrder: number
  }
}

export interface ISection {
  sectionId: string | null
  sectionName: string
  format: string
  isMain: boolean
  direction?: number
  sourceId: string
  Source: ISourceShort
}

export interface ILayerGroup {
  phaseId: string
  groupId: string
  groupName: string
  groupType: number
  displayOrder: number
  Layers: ILayer[]
}

export interface IRequest {
  token?: string
  phaseId: string
}

const getPhaseGroupsRequest = async (props: IRequest): Promise<ILayerGroup[] | null> => {
  const apiUrl = `/layers/group/${props.phaseId}`
  const response = await fetch(apiUrl, {
    headers: headersWithToken(props.token),
  })

  if (!response.ok) throw response
  return response.json()
}
export const getPhaseGroups = TokenExpiryWrapper(getPhaseGroupsRequest, [], null)

export interface IAllLayer {
  phaseId: string
  layerId: string
  layerName: string
  colour: string | null
  active: boolean
  LayerGroups: IAllLayerGroup[]
  Sources: IAllLayerSource[]
  Sections: ISection[]
}

export interface IAllLayerGroup {
  groupId: string
  groupName: string
  displayOrder: number
}

export interface IAllLayerSource {
  sourceId: string
  name: string
}

const getAllLayersRequest = async (props: IRequest): Promise<IAllLayer[] | null> => {
  const apiUrl = `/layers/all/${props.phaseId}`
  const response = await fetch(apiUrl, {
    headers: headersWithToken(props.token),
  })

  if (!response.ok) throw response
  return response.json()
}
export const getAllLayers = TokenExpiryWrapper(getAllLayersRequest, [], null)

export interface IRequestCreateLayer {
  token?: string
  layer: IAllLayer
}

const addLayerRequest = async (props: IRequestCreateLayer): Promise<IAllLayer | null> => {
  const response = await fetch('/layers', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ ...props.layer }),
  })

  if (response.ok) return response.json()
  return null
}
export const addLayer = TokenExpiryWrapper(addLayerRequest, [], null)

export interface IUpdateLayer {
  token?: string
  layer: {
    phaseId: string
    layerId: string
    layerName?: string
    colour?: string | null
    active?: boolean
  }
}

const updateLayerRequest = async (props: IUpdateLayer): Promise<IAllLayer | null> => {
  const response = await fetch('/layers', {
    method: 'put',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ ...props.layer }),
  })

  if (response.ok) return response.json()
  return null
}
export const updateLayer = TokenExpiryWrapper(updateLayerRequest, [], null)

export interface ILayerVideo {
  layerId: string
  videoId: string
  videoName: string
  type: number
  fileLocation: string
  SectionVideoTimes: IVideoTime[]
}

interface IVideoTime {
  index: number
  time: number
}

const getSectionVideosRequest = async (props: IRequest): Promise<ILayerVideo[] | null> => {
  const apiUrl = `/layers/video/${props.phaseId}`
  const response = await fetch(apiUrl, {
    headers: headersWithToken(props.token),
  })

  if (!response.ok) throw response
  return response.json()
}
export const getSectionVideos = TokenExpiryWrapper(getSectionVideosRequest, [], null)

const deleteLayerRequest = async (props: IDeleteLayer): Promise<boolean> => {
  const { phaseId, layerId } = props

  const response = await fetch('/layers', {
    method: 'delete',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ layerId, phaseId }),
  })
  return response.ok
}
export const deleteLayer = TokenExpiryWrapper(deleteLayerRequest, [], false)
