import React, { FC, useState } from 'react'
import Style from '../styles/NoAccess.module.sass'
import { Container, FormInput, Grid, Button } from '@aurecon-creative-technologies/styleguide'
import { ReactComponent as AlignLogo } from '../assets/align_logo.svg'

import Page from '../components/Page'
import { IRequestAccess, sendRequestAccess } from '../api/sendgrid'
import { useParams } from 'react-router-dom'
import LoadingScreen from '../components/LoadingScreen'
import { useFormValidation } from '../validators/useFormValidation'
import noAccessFormSchema from '../validators/NoAccessForm'

interface IRequestParams {
  phaseId: string
}

const NoAccess: FC = () => {
  const { phaseId } = useParams<IRequestParams>()
  const [requestResponse, setRequestResponse] = useState({ sent: false, success: false, message: '' })
  const [loading, setLoading] = useState(false)

  const initialValues = {
    name: '',
    email: '',
    reason: `I'd like an access, please.`,
  }

  const { handleChange, handleTouched, errors, values, touched, isValid } = useFormValidation({
    initialValues,
    validationSchema: noAccessFormSchema,
  })

  const handleSendRequest = async () => {
    if (!isValid) return

    setLoading(true)
    const data: IRequestAccess = {
      phaseIdOrSlug: phaseId,
      name: values.name,
      email: values.email,
      reason: values.reason,
    }

    // Send request here
    const response = await sendRequestAccess({ request: data })
    setRequestResponse({ sent: true, ...response })
    setLoading(false)
  }

  const handleGoBack = () => {
    window.location.hash = '/'
  }

  if (loading) return <LoadingScreen text='Sending email...' />

  return (
    <Page>
      <Container>
        <Grid row>
          <Grid xs={12} lg={3} />
          <Grid xs={12} lg={6}>
            <div className={Style.wrapper}>
              <p>
                <AlignLogo height={50} />
              </p>
              {!requestResponse.sent && <p>Please enter your details below to request for access.</p>}
              {requestResponse.sent && requestResponse.success && (
                <>
                  <h2>
                    <b>Thank you.</b>
                  </h2>
                  <p style={{ marginBottom: '48px' }}>
                    <b>Your request has been sent to the owner of the project. You should be contacted shortly.</b>
                  </p>
                  <Button label='Go back to main page' type='default' onClick={handleGoBack} />
                </>
              )}
              {requestResponse.sent && !requestResponse.success && <h2>There was an issue sending the request.</h2>}
              {!requestResponse.sent && (
                <>
                  <h1>You don't have permission to access this project.</h1>
                  <FormInput
                    type='text'
                    label='Full Name'
                    onBlur={() => handleTouched('name')}
                    onChange={(value) => handleChange('name', value)}
                    value={values.name}
                    error={touched.name ? errors?.name : ''}
                    multilineLimit={100}
                    default
                    disabled={requestResponse.success}
                  />
                  <FormInput
                    type='text'
                    label='Email'
                    onBlur={() => handleTouched('email')}
                    onChange={(value) => handleChange('email', value)}
                    value={values.email}
                    error={touched.email ? errors?.email : ''}
                    default
                    disabled={requestResponse.success}
                  />
                  <FormInput
                    type='text'
                    label='Reason'
                    onBlur={() => handleTouched('reason')}
                    onChange={(value) => handleChange('reason', value)}
                    value={values.reason}
                    error={touched.reason ? errors?.reason : ''}
                    multilineLimit={200}
                    multiline
                    default
                    disabled={requestResponse.success}
                  />
                  <Button label='Request Access' onClick={handleSendRequest} disabled={!isValid} />
                </>
              )}
            </div>
          </Grid>
          <Grid xs={12} lg={3} />
        </Grid>
      </Container>
    </Page>
  )
}

export default NoAccess
