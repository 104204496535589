import React, { FC, useEffect, useState } from 'react'
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Container } from '@aurecon-creative-technologies/styleguide'

import Page from '../components/Page'
import { MyProjects as MyProjectData, CurrentProjectContext, FilterProjectState } from '../stores/projectStore'

import Style from '../styles/MyProjects.module.sass'
import ProjectTileView from '../components/ProjectTileView'
import SearchMyProjectComponent from '../components/SearchMyProjectComponent'
import { IProjectData } from '../api/projects'
import { filterProjects } from '../helpers/utils'

const MyProjects: FC = () => {
  const myProjects = useRecoilValueLoadable(MyProjectData)
  const setCurrentProjectContext = useSetRecoilState(CurrentProjectContext)
  const filter = useRecoilValue(FilterProjectState)
  const [projects, setProjects] = useState<IProjectData[]>([])

  useEffect(() => {
    setCurrentProjectContext('my_projects')
  }, [setCurrentProjectContext])

  useEffect(() => {
    if (myProjects.state !== 'hasValue') return

    const filtered = filterProjects(myProjects.contents, filter)
    setProjects(filtered)
  }, [myProjects, filter, setProjects])

  const errorMessage = myProjects.state === 'hasError' ? myProjects.contents.message : ''

  return (
    <Page background>
      <Container>
        <div className={Style.header}>
          <h1>My Projects</h1>
        </div>
        <SearchMyProjectComponent />

        <ProjectTileView projects={projects} state={myProjects.state} error={errorMessage} />
      </Container>
    </Page>
  )
}

export default MyProjects
