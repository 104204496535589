import React, { FC } from 'react'
import { Toggle } from '@aurecon-creative-technologies/styleguide'
import { ILayer } from '../api/layers'

import Style from '../styles/LayerToggle.module.sass'

interface ILayerToggle {
  layer: ILayer
  active: boolean
  onToggle: (active: boolean, layerId: string) => void
  noSquare?: boolean
}

const LayerToggle: FC<ILayerToggle> = (props) => {
  const { layer, active, onToggle, noSquare } = props

  return (
    <div className={`${Style.toggle} ${noSquare ? Style.short : ''}`}>
      <Toggle
        label={
          <label className='toggle-label'>
            {!noSquare && <div className={Style.square} style={{ backgroundColor: layer.colour }} />}
            <span>{layer.layerName}</span>
          </label>
        }
        onChange={(active) => onToggle(active, layer.layerId)}
        value={active}
        hideValue
        default
        labelPosition='right'
      />
    </div>
  )
}

export default LayerToggle
