import React, { FC, useMemo } from 'react'
import { Loadable, useRecoilValue, useRecoilState } from 'recoil'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { CSVLink } from 'react-csv'
import isSameDay from 'date-fns/isSameDay'
import format from 'date-fns/format'

import { IComment } from '../api/comments'
import { CategoryNameColour } from './CommentsTab'

import { unixDateTimeToString } from '../helpers/utils'
import { ProjectDetails } from '../stores/projectStore'
import { CommentFilters, ShowCommentOption } from '../stores/commentStore'

import Style from '../styles/DownloadComments.module.sass'

const CSV_HEADERS = [
  'id',
  'author',
  'date',
  'title',
  'comment',
  'category',
  'assignee',
  'attachments',
  'longitude',
  'latitude',
  'replyId',
  'commentState',
]

export interface IDownloadCommentsProps {
  comments: Loadable<IComment[]>
  categoryList: CategoryNameColour
}

interface ICsvData {
  id: string
  author: string
  date: string
  title: string
  comment: string
  category: string
  assignee: string | null
  attachments: string
  longitude: number
  latitude: number
  replyId: string
  commentState: string
}

const DownloadComments: FC<IDownloadCommentsProps> = (props) => {
  const project = useRecoilValue(ProjectDetails)
  const filters = useRecoilValue(CommentFilters)
  const [showCommentOption] = useRecoilState(ShowCommentOption)
  const commentsAvailable = props.comments.state === 'hasValue'

  const getCSVData = useMemo(() => {
    if (props.comments.state !== 'hasValue') return []

    const data: ICsvData[] = []
    const isArchivedView = showCommentOption === 'archived'

    const commentsList = props.comments.contents
    const mainComments = commentsList.filter((comment) => !comment.threadId)
    const replies = commentsList.filter((comment) => comment.threadId)

    const formatCommentData = (comment: IComment): ICsvData => {
      const matchingCategory = comment.categoryId ? props.categoryList[comment.categoryId] : null
      const commentFormatted = comment.content.replace(/\r\n/g, ' ~').replace(/\n/g, ' ~').replace(/"/g, "'")
      return {
        id: comment.commentId,
        author: comment.authorName || comment.userId,
        date: unixDateTimeToString(comment.createdAt),
        title: comment.title,
        comment: commentFormatted,
        category: matchingCategory ? matchingCategory.name : '',
        assignee: comment.assignee || '',
        attachments: comment.attachments.join(';'),
        longitude: comment.lng,
        latitude: comment.lat,
        replyId: comment.threadId || '',
        commentState: comment.archived ? 'Archived' : 'Active',
      }
    }

    mainComments.forEach((mainComment) => {
      data.push(formatCommentData(mainComment))
      replies
        .filter((reply) => reply.threadId === mainComment.commentId)
        .forEach((reply) => {
          if (isArchivedView && !reply.archived) return
          data.push(formatCommentData(reply))
        })
    })

    return data
  }, [props.comments, props.categoryList, showCommentOption])

  const date = useMemo(() => {
    if (!filters.dateFilter) return 'all-dates'
    const { startDate, endDate } = filters.dateRange[0]
    if (!endDate || isSameDay(startDate, endDate)) return format(startDate, 'dd/MM/yyyy')

    return `${format(startDate, 'dd/MM/yyyy')}-${format(endDate, 'dd/MM/yyyy')}`
  }, [filters])

  return (
    <div className={Style.downloadCommentsWrapper}>
      <CSVLink
        data={getCSVData}
        headers={CSV_HEADERS}
        filename={`${project?.title}-${project?.phaseName}-${date}.csv`}
        target='_blank'
      >
        <Button
          type='icon-square'
          icon='download'
          size='small'
          default
          cssClass='is-date'
          disabled={!commentsAvailable}
          title='Download comments'
        />
      </CSVLink>
    </div>
  )
}

export default DownloadComments
