import { TokenExpiryWrapper } from './TokenManager'
import { headersWithToken } from '../helpers/utils'
import { IDeleteSource, IRequestMapboxJobStatus } from './apiprops'

export interface ILayerShort {
  layerId: string
  layerName: string
}

export interface ISourceShort {
  sourceId: string
  name: string
}

export interface ILegend {
  active: boolean
  legend: {
    name: string
    bg: string
    line: string
  }[]
  heatmap: {
    minName: string
    maxName: string
  } | null
}

export interface ILegendSource extends ILegend {
  name: string
  type: number
  paint: string
}

export interface ISource {
  sourceId: string
  phaseId: string
  name: string
  type: number
  paint: string
  location: string
  size: number
  jobId?: string
  popup?: string
  Layers: ILayerShort[]
  Sections: string[]
  legend: string | null
  sourceLayer: string | null
}

export interface IRequestLayerGroup {
  token?: string
  phaseId: string
}

export interface IRequestCreateSource {
  token?: string
  source: {
    sourceId?: string
    phaseId: string
    name: string
    type: number
    paint: string
    location: string
    size: number
    jobId?: string
  }
}

export interface IRequestUpdateSource {
  token?: string
  source: {
    sourceId: string
    phaseId: string
    name?: string
    type?: number
    paint?: string
    location?: string
    size?: number
    jobId?: string
  }
}

export type IRequestSourceLayer =
  | {
      token?: string
      phaseId: string
      sourceId?: string
      layerId: string
    }
  | {
      token?: string
      phaseId: string
      sourceId: string
      layerId?: string
    }

export type StatusType = 'queued' | 'processing' | 'success' | 'failed'

const getPhaseSourcesRequest = async (props: IRequestLayerGroup): Promise<ISource[] | null> => {
  const apiUrl = `/sources/phase/${props.phaseId}`
  const response = await fetch(apiUrl, {
    headers: headersWithToken(props.token),
  })

  if (!response.ok) throw response

  return response.json()
}
export const getPhaseSources = TokenExpiryWrapper(getPhaseSourcesRequest, [], null)

const createSourceRequest = async (props: IRequestCreateSource): Promise<ISource | null> => {
  const response = await fetch('/sources', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ ...props.source }),
  })
  if (response.ok) return response.json()
  return null
}
export const createSource = TokenExpiryWrapper(createSourceRequest, [], null)

const updateSourceRequest = async (props: IRequestUpdateSource): Promise<ISource | null> => {
  const response = await fetch('/sources', {
    method: 'put',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ ...props.source }),
  })
  if (response.ok) return response.json()
  return null
}
export const updateSource = TokenExpiryWrapper(updateSourceRequest, [], null)

const createSourceLayerRequest = async (props: IRequestSourceLayer): Promise<boolean> => {
  const { layerId, sourceId, phaseId } = props

  const response = await fetch('/sources/layer', {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ layerId, sourceId, phaseId }),
  })
  if (response.ok) return true
  return false
}
export const createSourceLayer = TokenExpiryWrapper(createSourceLayerRequest, [], false)

const deleteSourceLayerRequest = async (props: IRequestSourceLayer): Promise<boolean> => {
  const { layerId, sourceId, phaseId } = props

  const response = await fetch('/sources/layer', {
    method: 'delete',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ layerId, sourceId, phaseId }),
  })
  if (response.ok) return true
  return false
}
export const deleteSourceLayer = TokenExpiryWrapper(deleteSourceLayerRequest, [], false)

const deleteSourceRequest = async (props: IDeleteSource): Promise<boolean> => {
  const { phaseId, sourceId } = props

  const response = await fetch('/sources', {
    method: 'delete',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ sourceId, phaseId }),
  })
  return response.ok
}
export const deleteSource = TokenExpiryWrapper(deleteSourceRequest, [], false)

const getMapboxJobStatusRequest = async (props: IRequestMapboxJobStatus): Promise<{ status: StatusType } | null> => {
  try {
    const response = await fetch(`/sources/status`, {
      method: 'post',
      headers: headersWithToken(props.token),
      body: JSON.stringify({ phaseId: props.phaseId, sourceId: props.sourceId }),
    })
    if (response.ok) return response.json()
    else return null
  } catch {
    return null
  }
}
export const getMapboxJobStatus = TokenExpiryWrapper(getMapboxJobStatusRequest, [], null)
