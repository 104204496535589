import React, { FC, useCallback, useMemo } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Button, Grid, Loader, Toggle } from '@aurecon-creative-technologies/styleguide'

import { ProjectDetails } from '../stores/projectStore'
import { LayerGroups, LayerState, SelectedGISGroup, OnlyShowGroupLayers } from '../stores/layerStore'
import { LayerGroupTypeEnum } from '../enums/LayerGroupTypeEnum'
import { ILayerGroup } from '../api/layers'
import LayerToggle from './LayerToggle'

import Style from '../styles/GISLayersTab.module.sass'

const GISLayersTab: FC = () => {
  const details = useRecoilValue(ProjectDetails)
  const layerGroups = useRecoilValueLoadable(LayerGroups)
  const [layersState, setLayersState] = useRecoilState(LayerState)
  const [selectedGISGroup, setSelectedGISGroup] = useRecoilState(SelectedGISGroup)
  const [onlyGroupLayers, setOnlyGroupLayers] = useRecoilState(OnlyShowGroupLayers)

  const GISGroups = useMemo(() => {
    if (layerGroups.state !== 'hasValue') return []
    return layerGroups.contents.filter(
      (group) => group.groupType === LayerGroupTypeEnum.GIS || group.groupType === LayerGroupTypeEnum.GIS_COMMON,
    )
  }, [layerGroups])

  const filteredGISGroups = useMemo(() => {
    return GISGroups.filter((group) => group.groupType === LayerGroupTypeEnum.GIS)
  }, [GISGroups])

  const layers = useMemo(() => {
    return GISGroups.flatMap((group) => group.Layers).filter(
      (layer, index, array) => array.findIndex((layer2) => layer.layerId === layer2.layerId) === index,
    )
  }, [GISGroups])

  const selectedGroup = useMemo(() => {
    return filteredGISGroups.find((group) => group.groupId === selectedGISGroup)
  }, [filteredGISGroups, selectedGISGroup])

  const toggleGroup = useCallback(
    (group: ILayerGroup) => {
      if (layerGroups.state !== 'hasValue') return

      setSelectedGISGroup(group.groupId)

      if (onlyGroupLayers) return

      const newState = {}
      layers.forEach((layer) => {
        newState[layer.layerId] = group.Layers.some((l) => layer.layerId === l.layerId)
      })

      setLayersState((currentState) => {
        return {
          ...currentState,
          ...newState,
        }
      })
    },
    [onlyGroupLayers, layers, layerGroups, setLayersState, setSelectedGISGroup],
  )

  const isActive = useCallback(
    (group: ILayerGroup) => {
      if (onlyGroupLayers && selectedGISGroup === group.groupId) return true
      else {
        return layers.every(
          (layer) => layersState[layer.layerId] === group.Layers.some((l) => layer.layerId === l.layerId),
        )
      }
    },
    [layers, layersState, selectedGISGroup, onlyGroupLayers],
  )

  const handleSetLayers = useCallback(
    (active: boolean, layer: string) => {
      setLayersState((state) => {
        return {
          ...state,
          [layer]: active,
        }
      })
    },
    [setLayersState],
  )

  const handleClearSelection = () => {
    const newState = {}
    layers.forEach((layer) => (newState[layer.layerId] = false))
    setLayersState((currentState) => {
      return {
        ...currentState,
        ...newState,
      }
    })
  }

  const handleToggleOnlyGroupLayers = (active: boolean) => {
    setOnlyGroupLayers(active)
    if (active) {
      let newActiveGroup = false
      for (let i = 0; i < filteredGISGroups.length; i++)
        if (isActive(filteredGISGroups[i])) {
          setSelectedGISGroup(filteredGISGroups[i].groupId)
          newActiveGroup = true
          break
        }

      if (!newActiveGroup && !selectedGISGroup) setSelectedGISGroup(filteredGISGroups[0]?.groupId)
    }
  }

  const activeLayers = useMemo(() => {
    let active = false
    layers.forEach((layer) => {
      if (layersState[layer.layerId]) active = true
    })
    return active
  }, [layers, layersState])

  return (
    <div className={Style.gisWrapper}>
      <div className={Style.contents}>
        <Grid row gap={12}>
          <Grid item md={8}>
            <h3 className={Style.title}>{details && `${details.title} / ${details.phaseName}`}</h3>
          </Grid>
          <Grid item md={4} style={{ justifyContent: 'flex-end' }}>
            <Button
              type='text'
              onClick={handleClearSelection}
              label='Clear all selection'
              cssClass={activeLayers ? 'clear-button' : 'clear-button inactive'}
              icon='layers_clear'
            />
            <Toggle
              label='Only view group layers'
              onLabel=''
              offLabel=''
              labelPosition='right'
              cssClass={Style.toggle}
              value={onlyGroupLayers}
              onChange={handleToggleOnlyGroupLayers}
            />
          </Grid>
        </Grid>
        {layerGroups.state === 'loading' && <Loader label='Loading design options...' />}
        {layerGroups.state === 'hasValue' && (
          <>
            {filteredGISGroups.length > 0 && (
              <Grid row style={{ margin: '20px 0' }}>
                <Grid item>
                  {filteredGISGroups.map((group) => (
                    <Button
                      key={group.groupId}
                      label={group.groupName}
                      size='small'
                      default
                      cssClass={`${Style.group} ${isActive(group) ? Style.active : ''}`}
                      onClick={() => toggleGroup(group)}
                    />
                  ))}
                </Grid>
              </Grid>
            )}
            {filteredGISGroups.length > 1 && layers.length > 0 && <div className={Style.divider} />}
            <div className={Style.layers}>
              {layers
                .filter(
                  (layer) =>
                    !onlyGroupLayers ||
                    (selectedGroup && selectedGroup.Layers.find((l) => l.layerId === layer.layerId)),
                )
                .map((layer) => {
                  return (
                    <div key={layer.layerId}>
                      <LayerToggle layer={layer} active={layersState[layer.layerId]} onToggle={handleSetLayers} />
                    </div>
                  )
                })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default GISLayersTab
