import React, { FC, ReactElement } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Pill } from '@aurecon-creative-technologies/styleguide'

import { VISIBILITY_OPTIONS, ASSIGNED_OPTIONS, ATTACHMENT_OPTIONS, STATUS_OPTIONS } from './CommentsFilters'

import { CommentFilters } from '../stores/commentStore'
import { ProjectCategories } from '../stores/projectStore'

import Style from '../styles/CommentsPills.module.sass'

const CLOSE_ICONS: [string, string] = ['close', 'cancel']

const CommentsPills: FC = () => {
  const projectCategories = useRecoilValue(ProjectCategories)
  const [filters, setFilters] = useRecoilState(CommentFilters)

  const handleRemove = (filter: string, id: string | undefined) => {
    if (!id) return

    const index = filters[filter].indexOf(id)
    const newFilter = [...filters[filter]]

    newFilter.splice(index, 1)

    setFilters({
      ...filters,
      [filter]: newFilter,
    })
  }

  const categoryPills = () => {
    if (!filters.category.length) return null

    const pills: ReactElement[] = []

    filters.category.forEach((filter) => {
      projectCategories?.forEach((category) => {
        if (filter === category.id)
          pills.push(
            <Pill
              key={category.id}
              colour={16}
              onClose={() => handleRemove('category', category.id)}
              closeIcons={CLOSE_ICONS}
              cssClass='filter-pills'
            >
              Category: {category.categoryName}
            </Pill>,
          )
      })
    })

    return pills
  }

  const visibilityPills = () => {
    if (!filters.visibility.length) return null

    const pills: ReactElement[] = []

    filters.visibility.forEach((filter) => {
      const label = VISIBILITY_OPTIONS.filter((v) => v.id === filter)
      pills.push(
        <Pill
          key={filter}
          colour={16}
          onClose={() => handleRemove('visibility', filter)}
          closeIcons={CLOSE_ICONS}
          closeTitle='Delete filter'
          cssClass='filter-pills'
        >
          Visibility: {label[0].label}
        </Pill>,
      )
    })

    return pills
  }

  const assignedPills = () => {
    if (!filters.assigned.length) return null

    const pills: ReactElement[] = []

    filters.assigned.forEach((filter) => {
      const label = ASSIGNED_OPTIONS.filter((v) => v.id === filter)
      pills.push(
        <Pill
          key={filter}
          colour={16}
          onClose={() => handleRemove('assigned', filter)}
          closeIcons={CLOSE_ICONS}
          cssClass='filter-pills'
        >
          Assigned: {label[0].label}
        </Pill>,
      )
    })

    return pills
  }

  const attachmentPills = () => {
    if (!filters.attachments.length) return null

    const pills: ReactElement[] = []

    filters.attachments.forEach((filter) => {
      const label = ATTACHMENT_OPTIONS.filter((v) => v.id === filter)
      pills.push(
        <Pill
          key={filter}
          colour={16}
          onClose={() => handleRemove('attachments', filter)}
          closeIcons={CLOSE_ICONS}
          cssClass='filter-pills'
        >
          Attachments: {label[0].label}
        </Pill>,
      )
    })

    return pills
  }

  const statusPills = () => {
    if (!filters.status.length) return null

    const pills: ReactElement[] = []

    filters.status.forEach((filter) => {
      const label = STATUS_OPTIONS.filter((v) => v.id === filter)
      pills.push(
        <Pill
          key={filter}
          colour={16}
          onClose={() => handleRemove('status', filter)}
          closeIcons={CLOSE_ICONS}
          cssClass='filter-pills'
        >
          Status: {label[0].label}
        </Pill>,
      )
    })

    return pills
  }

  return (
    <div className={Style.filterPillsWrapper}>
      {visibilityPills()}
      {assignedPills()}
      {attachmentPills()}
      {categoryPills()}
      {statusPills()}
    </div>
  )
}

export default CommentsPills
