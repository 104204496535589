import React, { FC } from 'react'
import { Container } from '@aurecon-creative-technologies/styleguide'

import Page from '../components/Page'

const NotFound: FC = () => {
  return (
    <Page>
      <Container>
        <h1>404</h1>
        <h3>Not Found</h3>
      </Container>
    </Page>
  )
}

export default NotFound
