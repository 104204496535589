import React, { FC } from 'react'
import { LngLatLike } from 'mapbox-gl'
import { HybridPopup } from './HybridMap/HybridPopup'
import Style from '../styles/Map.module.sass'
import { Button } from '@aurecon-creative-technologies/styleguide'

export interface IPropertiesPopup {
  location: LngLatLike
  properties: {
    [key: string]: {
      [key: string]: string
    }
  }
}

interface IPropertiesPopupProps {
  popup: IPropertiesPopup
  onClose: () => void
}

const PropertiesPopup: FC<IPropertiesPopupProps> = (props) => {
  return (
    <HybridPopup
      lngLat={props.popup.location}
      closeOnClick={true}
      closeButton={false}
      onClose={props.onClose}
      className={Style.propertiesPopup}
      maxWidth='320px'
    >
      <Button cssClass={Style.closeButton} icon='close' type='secondary' onClick={props.onClose} />
      <div className={Style.sources}>
        {Object.entries(props.popup.properties).map((sourceProps) => (
          <div key={sourceProps[0]}>
            <h4>{sourceProps[0]}</h4>
            <table>
              <tbody>
                {Object.entries(sourceProps[1]).map((prop) => (
                  <tr key={prop[0]}>
                    <td>{prop[0]}</td>
                    <td>{prop[1]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </HybridPopup>
  )
}

export default PropertiesPopup
