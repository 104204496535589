import { atom } from 'recoil'

export const MapStyle = atom<'map' | 'satellite'>({
  key: 'map_style',
  default: 'map',
})

export const MarkerVisibility = atom<boolean>({
  key: 'markerVisibility',
  default: true,
})

export const Zoom = atom({
  key: 'map_zoom',
  default: 10,
})

export const Bearing = atom({
  key: 'map_bearing',
  default: 0,
})
