import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Style from '../styles/NavigationFrame.module.sass'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { ReactComponent as ExpandIcon } from '../assets/expand.svg'
import { clamp } from '../helpers/utils'
import {
  NavFrameCollapsed,
  RightDrawerOpen,
  NavFrameHeight,
  tabs,
  ITab,
  NavFrameTab,
  useHandleDrawer,
} from '../stores/uiStore'

export const TAB_MIN_HEIGHT = 63 // Tab height: 55, padding: 8

const NavigationFrame: FC = () => {
  const [activeTab, setActiveTab] = useRecoilState(NavFrameTab)
  const [dragging, setDragging] = useState(false)
  const [mouseYOffset, setMouseYOffset] = useState(0)
  const [navFrameHeight, setNavFrameHeight] = useRecoilState(NavFrameHeight)
  const [collapsed, setCollapsed] = useRecoilState(NavFrameCollapsed)
  const drawerOpen = useRecoilValue(RightDrawerOpen)
  const handleDrawer = useHandleDrawer()

  useEffect(() => {
    const onMouseUp = () => {
      setDragging(false)
    }
    window.addEventListener('mouseup', onMouseUp)
    window.addEventListener('touchend', onMouseUp)
    return () => {
      window.removeEventListener('mouseup', onMouseUp)
      window.removeEventListener('touchend', onMouseUp)
    }
  }, [])

  useEffect(() => {
    if (collapsed || drawerOpen) {
      setNavFrameHeight(TAB_MIN_HEIGHT)
      setCollapsed(true)
    }
  }, [collapsed, drawerOpen, setCollapsed, setNavFrameHeight])

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (dragging) {
        setNavFrameHeight(clamp(window.innerHeight - e.pageY + mouseYOffset, TAB_MIN_HEIGHT, window.innerHeight))
      }
    }
    const onTouchMove = (e: TouchEvent) => {
      if (dragging) {
        setNavFrameHeight(
          clamp(window.innerHeight - e.touches[0].pageY + mouseYOffset, TAB_MIN_HEIGHT, window.innerHeight),
        )
      }
    }
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('touchmove', onTouchMove)
    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('touchmove', onTouchMove)
    }
  }, [dragging, mouseYOffset, setNavFrameHeight])

  const isTabOpen = (): boolean => {
    return navFrameHeight > TAB_MIN_HEIGHT
  }

  const setOffset = (btn: HTMLButtonElement, pageY: number) => {
    const rects = btn.getBoundingClientRect()
    setMouseYOffset(pageY - rects.top)
    setDragging(true)
  }

  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOffset(e.currentTarget, e.pageY)
  }

  const onTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
    setOffset(e.currentTarget, e.touches[0].pageY)
  }

  const onExpand = () => {
    if (navFrameHeight > TAB_MIN_HEIGHT) {
      setNavFrameHeight(TAB_MIN_HEIGHT)
    } else {
      setNavFrameHeight(window.innerHeight * 0.5)
      handleDrawer(false)
    }
  }

  const onTabClick = useCallback(
    (tab: ITab) => {
      if (navFrameHeight <= TAB_MIN_HEIGHT) {
        setNavFrameHeight(window.innerHeight * 0.5)
        handleDrawer(false)
      }
      setActiveTab(tab)
    },
    [navFrameHeight, setNavFrameHeight, setActiveTab, handleDrawer],
  )

  const tabButtons = useMemo(() => {
    return tabs.map((tab) => (
      <Button
        key={tab.name}
        cssClass={activeTab.name === tab.name ? Style.active : ''}
        onClick={() => onTabClick(tab)}
        label={tab.name}
        icon={tab.icon}
        type='default'
        title={tab.title}
      />
    ))
  }, [activeTab, onTabClick])

  const tabContent = useMemo(() => {
    return activeTab.content
  }, [activeTab])

  return (
    <div className={Style.frame} style={{ height: navFrameHeight, transition: dragging ? 'none' : undefined }}>
      <div className={Style.top}>
        {tabButtons}
        <button
          className={`${Style.button} ${Style.drag}`}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          disabled={collapsed}
          title='Adjust menu height'
        >
          <ExpandIcon />
        </button>
        <Button
          cssClass={isTabOpen() ? Style.expanded : ''}
          icon='expand_less'
          type='icon-square'
          title={isTabOpen() ? 'Hide menu' : 'Open menu'}
          onClick={onExpand}
        />
      </div>
      {tabContent}
    </div>
  )
}

export default NavigationFrame
