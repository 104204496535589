/* eslint-disable @typescript-eslint/no-explicit-any  */

import Joi, { ObjectSchema } from '@hapi/joi'
import { useEffect } from 'react'
import { useRecoilCallback, useRecoilState } from 'recoil'
import { FormValues, FormErrors, FormTouched } from '../stores/formValidationStore'

interface IUseFormValidationProps {
  initialValues?: any
  validationSchema?: ObjectSchema
}

export const useFormValidation = (props: IUseFormValidationProps) => {
  const { initialValues, validationSchema } = props
  const [formValues, setFormValues] = useRecoilState(FormValues)
  const [formErrors, setFormErrors] = useRecoilState(FormErrors)
  const [formTouched, setFormTouched] = useRecoilState(FormTouched)

  useEffect(() => {
    setFormValues(initialValues)
    setFormTouched({})
    // need to run this once to set the initial state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (name: string, value: any) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }))
  }

  const handleTouched = (name: string) => {
    setFormTouched((prevValues) => ({ ...prevValues, [name]: true }))
  }

  const validate = useRecoilCallback(
    () =>
      (
        values: {
          [key: string]: any
        },
        schema: ObjectSchema,
      ) => {
        const { error } = schema.validate(values, { abortEarly: false })
        if (!error) {
          setFormErrors({})
          return
        }
        const newErrors = {}
        for (const item of error.details) {
          newErrors[item.path[0]] = item.message
        }
        return newErrors
      },
    [],
  )

  useEffect(() => {
    const error = validate(formValues, validationSchema ?? Joi.object())

    if (!error) {
      setFormErrors({})
      return
    }

    setFormErrors(error)
  }, [formValues, validate, validationSchema, setFormErrors])

  const resetForm = (newValues = initialValues) => {
    const newErrors = validate(newValues, validationSchema ?? Joi.object())
    setFormValues(newValues)
    setFormErrors(newErrors ?? {})
    setFormTouched({})
  }

  const setFieldTouched = (name: string, value: boolean) => {
    setFormTouched((prevValues) => ({ ...prevValues, [name]: value }))
  }

  const setFieldValue = (name: string, value: any) => {
    setFormValues({ ...formValues, [name]: value })
  }

  return {
    validate,
    values: formValues,
    errors: formErrors,
    touched: formTouched,
    isValid: formErrors && Object.keys(formErrors).length === 0,
    handleChange,
    handleTouched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  }
}
