import React, { FC, useEffect, useRef, useState } from 'react'
import { useRecoilValue, useRecoilValueLoadable, useRecoilState } from 'recoil'
import ReactPlayer from 'react-player'

import { ILayerVideo } from '../api/layers'

import { NavFrameHeight } from '../stores/uiStore'
import { SectionVideos, SelectedLayerId } from '../stores/layerStore'
import { HighlightIndex, VideoPlaying } from '../stores/sectionStore'

import Style from '../styles/SectionVideo.module.sass'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { VideoTypeEnum } from '../enums/VideoTypeEnum'
import { getLayerVideo } from '../api/storage'

interface IPlayerOnProgress {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

const VIDEO_AR = 16 / 9

const SectionVideo: FC = () => {
  const frameHeight = useRecoilValue(NavFrameHeight)
  const sectionVideos = useRecoilValueLoadable(SectionVideos)
  const selectedLayerId = useRecoilValue(SelectedLayerId)
  const [highlightIndex, setHighlightIndex] = useRecoilState(HighlightIndex)
  const [videoPlaying, setVideoPlaying] = useRecoilState(VideoPlaying)
  const [video, setVideo] = useState<ILayerVideo | null>(null)
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false)
  const [smallVideo, setSmallVideo] = useState<boolean>(true)
  const player = useRef<ReactPlayer>(null)
  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    if (sectionVideos.state !== 'hasValue' || !sectionVideos.contents) return

    const layerVideos = sectionVideos.contents.filter((layer) => {
      return layer.layerId === selectedLayerId
    })

    let currentVideo = null

    layerVideos.forEach(async (video) => {
      const indices = video.SectionVideoTimes.map((time) => time.index)
      const min = Math.min(...indices)
      const max = Math.max(...indices)
      if (highlightIndex.index >= min && highlightIndex.index <= max) currentVideo = video
    })

    setVideo(currentVideo)
  }, [sectionVideos, selectedLayerId, highlightIndex])

  useEffect(() => {
    if (!player.current || !isPlayerReady || !video || !highlightIndex.seek) return

    video.SectionVideoTimes.some((currentTime, i) => {
      const prevTime = i > 0 ? video.SectionVideoTimes[i - 1] : video.SectionVideoTimes[i]
      const index = highlightIndex.index

      if (index >= prevTime.index && index < currentTime.index) {
        const step = (currentTime.time - prevTime.time) / (currentTime.index - prevTime.index)
        const seekTime = prevTime.time + (index - prevTime.index) * step
        player.current?.seekTo(seekTime, 'seconds')
        return true
      }
      return false
    })
  }, [highlightIndex, isPlayerReady, video])

  useEffect(() => {
    setUrl(video?.fileLocation ?? '')
    if (video?.type === VideoTypeEnum.AZURE) {
      const [phaseId, filename] = video.fileLocation.split('/')
      getLayerVideo({ phaseId, filename }).then((res) => {
        if (res) {
          setUrl(res)
        }
      })
    }
  }, [video])

  const handleOnProgress = (e: IPlayerOnProgress) => {
    if (!player || !isPlayerReady || !video) return

    video.SectionVideoTimes.some((currentTime, i) => {
      const prevTime = i > 0 ? video.SectionVideoTimes[i - 1] : video.SectionVideoTimes[i]
      const playedTime = e.playedSeconds

      if (playedTime >= prevTime.time && playedTime < currentTime.time) {
        const step = (currentTime.time - prevTime.time) / (currentTime.index - prevTime.index)
        const diff = playedTime - prevTime.time
        const offset = Math.floor(diff / step)
        const newIndex = prevTime.index + offset

        setHighlightIndex({ index: newIndex, seek: false })
        return true
      }

      return false
    })
  }

  if (!video) return null

  const videoHeight = smallVideo ? 200 : 400
  const videoWidth = videoHeight * VIDEO_AR

  const videoStyle = {
    bottom: `${frameHeight}px`,
    height: `${videoHeight}px`,
    width: `${videoWidth}px`,
  }

  return (
    <div className={Style.sectionVideoWrapper} style={videoStyle}>
      <ReactPlayer
        ref={player}
        url={url}
        playing={videoPlaying}
        controls={true}
        width='100%'
        height='100%'
        progressInterval={1000}
        onProgress={handleOnProgress}
        onReady={() => setIsPlayerReady(true)}
        onPlay={() => setVideoPlaying(true)}
        onPause={() => setVideoPlaying(false)}
      />
      <div className={Style.sizeIcon}>
        <Button
          type='icon-square'
          icon={smallVideo ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          size='small'
          cssClass='video-button'
          onClick={() => setSmallVideo(!smallVideo)}
        />
      </div>
    </div>
  )
}

export default SectionVideo
