import { FC, useEffect } from 'react'
import { useToast } from '@aurecon-creative-technologies/styleguide'

export interface IPageError {
  message: string
  error: string
  code: string
  long?: boolean
  timeout?: number
}

/** A component which reports errors on initial load of a page */
const PageError: FC<IPageError> = (props) => {
  const { message, code, timeout = 5000, long = true } = props //defaults
  const { addToast } = useToast()

  useEffect(() => {
    addToast({
      type: 'error',
      message: `${code}:${message}`,
      long,
      timeout,
    })
  }, [message, code, long, timeout, addToast])
  return null
}

export default PageError
