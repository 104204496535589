import { atom } from 'recoil'

export const consentedState = atom({
  key: 'consented',
  default: false,
})

export interface IUserDetails {
  name: string
}

export const UserDetails = atom({
  key: 'userDetails',
  default: {
    name: '',
  } as IUserDetails,
})
