import React, { FC, useMemo } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Icon, Loader, Pill, Text } from '@aurecon-creative-technologies/styleguide'
import format from 'date-fns/format'
import { getLuminance } from 'polished'

import { ExternalDataPoint, SelectedExternalData } from '../stores/externalDataStore'
import { ExternalDataLabelPositionEnum } from '../enums/ExternalDataLabelPositionEnum'
import { ExternalDataTypeEnum } from '../enums/ExternalDataTypeEnum'
import { IField } from '../api/storage'

import Style from '../styles/AddEditComment.module.sass'
import ImageLoader from './ImageLoader'
import ReplyComment from './ReplyComment'
import { IProjectCategory } from '../api/categories'
import SideComment from './SideComment'
import { Comments, SelectedCommentId } from '../stores/commentStore'
import { CommentState } from './AddEditComment'

interface IViewExternalDataProps {
  categoryList: IProjectCategory[] | null
  onImagePreview: (field: IField) => void
  onClose: () => void
  myMemberId: string | null
  setCommentState: (state: CommentState) => void
  setCommented: () => void
}

const ViewExternalData: FC<IViewExternalDataProps> = (props) => {
  const externalData = useRecoilValueLoadable(ExternalDataPoint)
  const comments = useRecoilValueLoadable(Comments)
  const setSelectedCommentId = useSetRecoilState(SelectedCommentId)
  const setSelectedExternalData = useSetRecoilState(SelectedExternalData)

  const replies = useMemo(() => {
    if (
      externalData.state === 'hasValue' &&
      externalData.contents &&
      comments.state === 'hasValue' &&
      comments.contents
    ) {
      return comments.contents.filter((comment) => comment.threadId && comment.threadId === externalData.contents?.id)
    } else return []
  }, [externalData, comments])

  if (externalData.state === 'loading') return <Loader label='Loading data...' />
  if (externalData.state === 'hasError' || !externalData.contents)
    return (
      <div className={Style.commentEditWrapper}>
        <Text type='h2'>There was an issue getting data...</Text>
      </div>
    )

  const { dataName, date, fields, colourFieldId, phaseId } = externalData.contents

  const setEdit = (id: string) => {
    setSelectedExternalData(null)
    setSelectedCommentId(id)
    props.setCommentState('edit')
  }

  return (
    <>
      <div className={Style.existingComments}>
        <p className={Style.dateIcon}>
          <Icon type='chat' /> {format(date, 'dd/MM/yyyy, h:mm aaa')}
        </p>
        <Text type='h2'>{dataName}</Text>
        {fields.map((field, index) => {
          let colourField = 'gray'
          if (colourFieldId === field.id && typeof field.data[0].value2 === 'string') colourField = field.data[0].value2
          const textColour = getLuminance(colourField) > 0.2 ? 'black' : 'white'

          return (
            <div key={index}>
              {field.labelPosition === ExternalDataLabelPositionEnum.ABOVE && (
                <p className={Style.fieldAboveLabel}>{field.name}</p>
              )}
              <div className={Style.fieldValue}>
                {field.labelPosition === ExternalDataLabelPositionEnum.INLINE && (
                  <span className={Style.fieldInlineLabel}>{field.name}: </span>
                )}
                {!field.data.length && field.type === ExternalDataTypeEnum.IMAGE && <i>No images</i>}
                {!field.data.length && field.type !== ExternalDataTypeEnum.IMAGE && <i>No data</i>}
                {field.data.map((d, i) => {
                  return (
                    <span key={`${d.id}=${i}`}>
                      {field.type === ExternalDataTypeEnum.TEXT && d.value}
                      {field.type === ExternalDataTypeEnum.TEXT_AREA && d.value}
                      {field.type === ExternalDataTypeEnum.PILL && (
                        <Pill colour={1} style={{ backgroundColor: colourField, color: textColour }} size='small'>
                          {d.value}
                        </Pill>
                      )}
                    </span>
                  )
                })}
                {field.type === ExternalDataTypeEnum.IMAGE && (
                  <div className={Style.thumbnailWrapper}>
                    {field.data.map((d) => {
                      return (
                        <ImageLoader
                          key={d.value}
                          src={d.value?.toString()}
                          className={Style.thumbnail}
                          onClick={() => props.onImagePreview(d)}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          )
        })}
        {replies.map((reply) => (
          <SideComment
            phaseId={phaseId}
            key={reply.commentId}
            comment={reply}
            categoryList={props.categoryList}
            onCancel={props.onClose}
            onEdit={() => setEdit(reply.commentId)}
            canEdit={props.myMemberId === reply.userId}
            displayActions={false}
          />
        ))}
      </div>
      <ReplyComment
        externalData={externalData.contents}
        categoryList={props.categoryList}
        authorName={dataName}
        title={dataName}
        phaseId={externalData.contents.phaseId}
        onClose={props.onClose}
        setCommented={props.setCommented}
        setCommentState={props.setCommentState}
      />
    </>
  )
}

export default ViewExternalData
