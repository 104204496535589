export const LayerGroupTypeEnum = {
  DESIGN_OPTION: 0,
  DESIGN_OPTION_EXTRA: 1,
  DESIGN_OPTION_COMMON: 2,
  GIS: 3,
  GIS_COMMON: 4,
}

export const ReverseLayerGroupTypeEnum = {
  0: 'Options',
  1: 'Conditional Options',
  2: 'Common Options',
  3: 'GIS',
  4: 'Common GIS',
}
