import { Feature, FeatureCollection, LineString, Point, Polygon } from 'geojson'
import { LngLatLike } from 'mapbox-gl'
import React, { FC, useMemo, CSSProperties } from 'react'
import { useRecoilValue } from 'recoil'
import {
  MeasureMode,
  MeasureType,
  Measurements,
  CurrentMeasurement,
  getArea,
  getDistance,
  IMeasurement,
} from '../stores/measureStore'
import Style from '../styles/Map.module.sass'
import { HybridLayer } from './HybridMap/HybridLayer'
import { HybridPopup } from './HybridMap/HybridPopup'

export type MeasureHoverProps = {
  id: number
  pos: LngLatLike
  mode: MeasureType
}

interface IMapMeasureProps {
  movingIndex: number
  measureHover: MeasureHoverProps | null
}

const popupTextStyle: CSSProperties = {
  padding: 8,
  fontSize: 12,
}

export const BaseMeasureLayerId = 'existingMeasurementAreas'

const POPUP_OFFSET = -14

const getLengthElement = (
  hoverId: number,
  measureMode: MeasureType,
  currentMeasurement: number[][],
  measurements: IMeasurement[],
) => {
  const measure = measurements.find((m) => m.id === hoverId)
  const mode = hoverId === -1 ? measureMode : measure?.mode
  const text = mode === 'line' ? 'Length' : 'Perimeter'

  return (
    <p style={popupTextStyle}>
      {text}: {hoverId === -1 ? getDistance(currentMeasurement, measureMode as MeasureType) : measure?.length}
    </p>
  )
}

const MapMeasure: FC<IMapMeasureProps> = (props) => {
  const measureMode = useRecoilValue(MeasureMode)
  const currentMeasurement = useRecoilValue(CurrentMeasurement)
  const measurements = useRecoilValue(Measurements)

  const currentMeasurementLayer = useMemo(() => {
    const circles: FeatureCollection<Point> = {
      type: 'FeatureCollection',
      features: currentMeasurement.map((pt, i) => {
        return {
          type: 'Feature',
          properties: { index: i },
          geometry: {
            type: 'Point',
            coordinates: pt,
          },
        }
      }),
    }

    const line: Feature<LineString> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: currentMeasurement,
      },
    }

    const area: Feature<Polygon> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    }

    if (measureMode === 'area' && currentMeasurement.length > 2) {
      const newCoords = [...currentMeasurement, currentMeasurement[0]]
      line.geometry.coordinates = newCoords
      area.geometry.coordinates = [newCoords]
    }

    return (
      <>
        <HybridLayer
          data={area}
          id='measure-fill'
          type='fill'
          paint={{
            'fill-color': 'rgba(25, 22, 192, 0.5)',
          }}
          sourceLayer={null}
        />
        <HybridLayer
          data={line}
          id='measure-line'
          type='line'
          layout={{ 'line-cap': 'round' }}
          paint={{
            'line-color': 'rgb(25, 22, 192)',
            'line-width': 4,
          }}
          sourceLayer={null}
        />
        <HybridLayer
          data={circles}
          id='measure-circles'
          type='circle'
          paint={{
            'circle-color': ['match', ['get', 'index'], props.movingIndex, 'rgb(14, 12, 140)', 'rgb(25, 22, 192)'],
            'circle-stroke-color': 'rgb(255, 255, 255)',
            'circle-stroke-width': 2,
            'circle-radius': ['match', ['get', 'index'], props.movingIndex, 8, 5],
          }}
          sourceLayer={null}
        />
      </>
    )
  }, [currentMeasurement, measureMode, props.movingIndex])

  const existingMeasurements = useMemo(() => {
    const areas: FeatureCollection<Polygon> = {
      type: 'FeatureCollection',
      features: measurements
        .filter((m) => m.mode === 'area' && m.visible)
        .map((m) => {
          return {
            type: 'Feature',
            properties: { id: m.id },
            geometry: {
              type: 'Polygon',
              coordinates: [[...m.points, m.points[0]]],
            },
          }
        }),
    }

    const lines: FeatureCollection<LineString> = {
      type: 'FeatureCollection',
      features: measurements
        .filter((m) => m.mode === 'line' && m.visible)
        .map((m) => {
          const coords = [...m.points]
          return {
            type: 'Feature',
            properties: { id: m.id },
            geometry: {
              type: 'LineString',
              coordinates: coords,
            },
          }
        }),
    }

    return (
      <>
        <HybridLayer
          data={areas}
          id={BaseMeasureLayerId}
          type='fill'
          paint={{ 'fill-color': 'rgba(25, 22, 192, 0.4)' }}
          sourceLayer={null}
        />
        <HybridLayer
          data={lines}
          id='existingMeasurementLines'
          type='line'
          paint={{
            'line-color': 'rgba(25, 22, 192, 0.6)',
            'line-width': 4,
          }}
          sourceLayer={null}
        />
      </>
    )
  }, [measurements])

  const hoverPopup = useMemo(() => {
    if (props.measureHover === null) return null

    return (
      <HybridPopup
        lngLat={props.measureHover.pos}
        offset={[0, POPUP_OFFSET]}
        closeButton={false}
        tipSize={0}
        className={Style.measurePopup}
      >
        {props.measureHover.mode === 'line' &&
          getLengthElement(props.measureHover.id, measureMode as MeasureType, currentMeasurement, measurements)}
        {props.measureHover.mode === 'area' && (
          <p style={popupTextStyle}>
            Area:{' '}
            {props.measureHover.id === -1
              ? getArea(currentMeasurement)
              : measurements.find((m) => m.id === props.measureHover?.id)?.area}
          </p>
        )}
      </HybridPopup>
    )
  }, [props.measureHover, currentMeasurement, measureMode, measurements])

  return (
    <>
      {existingMeasurements}
      {currentMeasurementLayer}
      {hoverPopup}
    </>
  )
}

export default MapMeasure
