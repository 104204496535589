/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom } from 'recoil'

export const FormValues = atom<{
  [key: string]: any
}>({
  key: 'formValues',
  default: {},
})

export const FormErrors = atom<{
  [key: string]: string
}>({
  key: 'formErrors',
  default: {},
})

export const FormTouched = atom<{
  [key: string]: boolean
}>({
  key: 'formTouched',
  default: {},
})
