import { Button } from '@aurecon-creative-technologies/styleguide'
import React, { FC } from 'react'
import { IField } from '../api/storage'

import Style from '../styles/ImagePreview.module.sass'
import ImageLoader from './ImageLoader'

export interface IImagePreviewProps {
  image: IField | null
  onClose: () => void
}

const ImagePreview: FC<IImagePreviewProps> = (props) => {
  if (!props.image) return null

  let fullImageLink = null

  if (props.image.value2) fullImageLink = props.image.value2.toString()
  else if (props.image.value) fullImageLink = props.image.value.toString()

  if (!fullImageLink) return null

  return (
    <div className={Style.overlay}>
      <div className={Style.closeButton}>
        <Button size='small' icon='close' type='icon-square' onClick={props.onClose} />
      </div>
      <div className={Style.imageWrapper}>
        <ImageLoader src={fullImageLink} />
      </div>
    </div>
  )
}

export default ImagePreview
