import React, { FC, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import getTime from 'date-fns/getTime'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { ReactComponent as Line } from '../assets/line.svg'
import { ReactComponent as Area } from '../assets/area.svg'
import {
  MeasureMode,
  Measurements,
  CurrentMeasurement,
  getArea,
  getDistance,
  MeasureType,
} from '../stores/measureStore'
import { useMeasurements } from '../helpers/mapUtils'

import Style from '../styles/Measurement.module.sass'

const Measurement: FC = () => {
  const [measureMode, setMeasureMode] = useRecoilState(MeasureMode)
  const [measurements, setMeasurements] = useRecoilState(Measurements)
  const [currentMeasurement, setCurrentMeasurement] = useRecoilState(CurrentMeasurement)
  const zoomToMeasure = useMeasurements()

  useEffect(() => {
    return () => {
      setCurrentMeasurement([])
    }
  }, [setMeasureMode, setCurrentMeasurement])

  const onDone = () => {
    setMeasureMode(null)
    setCurrentMeasurement([])
    if (
      (measureMode === 'line' && currentMeasurement.length > 1) ||
      (measureMode === 'area' && currentMeasurement.length > 2)
    )
      setMeasurements((m) => [
        ...m,
        {
          id: getTime(new Date()),
          mode: measureMode as MeasureType,
          points: currentMeasurement,
          area: getArea(currentMeasurement),
          length: getDistance(currentMeasurement, measureMode as MeasureType),
          visible: true,
        },
      ])
  }

  const onToggleVisibility = (id: number) => {
    const measurementsArray = measurements.map((m) => {
      return {
        ...m,
        visible: m.id === id ? !m.visible : m.visible,
      }
    })

    setMeasurements(measurementsArray)
  }

  const removeMeasurement = (id: number) => {
    setMeasurements((measurements) => {
      return measurements.filter((m) => m.id !== id)
    })
  }

  return (
    <div className={Style.measurement}>
      <h2 className={Style.title}>Measure Tools</h2>
      <p>Select a tool and then click on the map to start measuring.</p>
      <p>Right-click to start again.</p>
      <div className={Style.tool}>
        <button
          onClick={() => setMeasureMode('line')}
          className={`button is-default ${Style.button} ${measureMode === 'line' ? Style.active : ''}`}
        >
          <Line /> Line
        </button>
        {measureMode === 'line' && <Button label='Done' onClick={onDone} />}
        <div className={Style.prev}>
          {measurements
            .filter((m) => m.mode === 'line')
            .map((m, i) => (
              <div key={i}>
                <span>Length: {m.length}</span>
                <Button
                  type='icon-square'
                  size='extra small'
                  icon='gps_fixed'
                  cssClass={Style.visibility}
                  onClick={() => zoomToMeasure(m.id)}
                />
                <Button
                  type='icon-square'
                  size='extra small'
                  icon={m.visible ? 'visibility' : 'visibility_off'}
                  cssClass={m.visible ? Style.visibility : Style.visibilityOff}
                  onClick={() => onToggleVisibility(m.id)}
                />
                <Button
                  type='icon-square'
                  size='extra small'
                  icon='close'
                  cssClass={Style.close}
                  onClick={() => removeMeasurement(m.id)}
                />
              </div>
            ))}
        </div>
      </div>
      <div className={Style.tool}>
        <button
          onClick={() => setMeasureMode('area')}
          className={`button is-default ${Style.button} ${measureMode === 'area' ? Style.active : ''}`}
        >
          <Area /> Area
        </button>
        {measureMode === 'area' && <Button label='Done' onClick={onDone} />}
        <div className={Style.prev}>
          {measurements
            .filter((m) => m.mode === 'area')
            .map((m, i) => (
              <div key={i}>
                <div className={Style.prevArea}>
                  <span>Perimeter: {m.length}</span>
                  <span>Area: {m.area}</span>
                </div>
                <Button
                  type='icon-square'
                  size='extra small'
                  icon='gps_fixed'
                  cssClass={Style.visibility}
                  onClick={() => zoomToMeasure(m.id)}
                />
                <Button
                  type='icon-square'
                  size='extra small'
                  icon={m.visible ? 'visibility' : 'visibility_off'}
                  cssClass={m.visible ? Style.visibility : Style.visibilityOff}
                  onClick={() => onToggleVisibility(m.id)}
                />
                <Button
                  type='icon-square'
                  size='extra small'
                  icon='close'
                  cssClass={Style.close}
                  onClick={() => removeMeasurement(m.id)}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Measurement
