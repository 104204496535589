import React, { FC, ReactText, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Dropdown,
  FormInput,
  Radio,
  Text,
  Loader,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { IProjectCategory } from '../api/categories'
import { IComment, addComment, ICommentAddProps, updateComment } from '../api/comments'
import { useUploadAttachments } from '../api/storage'
import { Comments, useRefreshComments, CommentUploading, SelectedCommentId } from '../stores/commentStore'
import { truncateFileName } from '../helpers/utils'
import SideComment from './SideComment'
import FileUpload from './FileUpload'

import Style from '../styles/AddEditComment.module.sass'
import { useHandleDrawer } from '../stores/uiStore'
import ReplyComment from './ReplyComment'
import { SelectedExternalData } from '../stores/externalDataStore'
import { useFormValidation } from '../validators/useFormValidation'
import { addEditCommentFormSchema } from '../validators/AddEditCommentForm'
import { CommentVisibilityEnum, CommentsVisibilityOptions } from '../enums/CommentsEnum'

export type CommentState = 'new' | 'exisiting' | 'edit'

export interface ILocation {
  lng: number
  lat: number
  zoom: number
}

interface IAddEditCommentProps {
  phaseId: string
  myMemberId: string | null
  categoryList: IProjectCategory[] | null
  commentsVisibility?: number
  newCommentLocation: ILocation | null
  removeNewCommentLocation: () => void
  onClose: () => void
  commentState: CommentState
  setCommentState: (state: CommentState) => void
  commented: boolean
  setCommented: (c: boolean) => void
}

export const EMPTY_COMMENT: IComment = {
  commentId: '',
  phaseId: '',
  threadId: '',
  userId: '',
  categoryId: null,
  title: '',
  content: '',
  onlyVisibleToOwners: true,
  lng: 0,
  lat: 0,
  zoom: 10,
  createdAt: 0,
  authorName: '',
  assignee: null,
  assigneeName: null,
  attachments: [],
  archived: false,
}

const AddEditComment: FC<IAddEditCommentProps> = (props) => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [selectedCommentId, setSelectedCommentId] = useRecoilState(SelectedCommentId)
  const setSelectedExternalData = useSetRecoilState(SelectedExternalData)
  const [comment, setComment] = useState<IComment>(EMPTY_COMMENT)
  const [tempComment, setTempComment] = useState<IComment>(EMPTY_COMMENT)
  const [replies, setReplies] = useState<IComment[]>([])
  const [files, setFiles] = useState<File[]>([])
  const { uploadProgress, uploadFile } = useUploadAttachments()
  const comments = useRecoilValueLoadable(Comments)
  const [commentUploading, setCommentUploading] = useRecoilState(CommentUploading)
  const handleDrawer = useHandleDrawer()

  const refreshComments = useRefreshComments()

  const { commentState, setCommentState, commented, setCommented, commentsVisibility } = props
  const { handleChange, errors, isValid, handleTouched, resetForm, touched } = useFormValidation({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema: addEditCommentFormSchema,
  })

  useEffect(() => {
    const isDirty = Object.keys(comment).some((key) => comment[key] !== tempComment[key]) || files.length > 0
    setDirty(isDirty)
  }, [comment, tempComment, setDirty, files.length])

  useEffect(() => {
    setLoading(true)
    if (comments.state !== 'hasValue' || !comments.contents) return

    if (selectedCommentId) {
      const commentDetails = comments.contents.find((comment) => comment.commentId === selectedCommentId)
      if (commentDetails) {
        setComment({ ...commentDetails })
        if (commentState === 'edit') {
          setTempComment(commentDetails)
          resetForm({
            title: commentDetails.title,
            content: commentDetails.content,
          })
        } else {
          setTempComment({
            ...commentDetails,
            title: `RE: ${commentDetails.title}`,
            content: '',
            commentId: '',
            attachments: [],
            threadId: commentDetails.commentId,
          })
        }
        setReplies(
          comments.contents.filter((comment) => comment.threadId && comment.threadId === commentDetails.commentId),
        )
      }
    }

    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newCommentLocation, selectedCommentId, props.phaseId, commented, comments, commentState])

  useLayoutEffect(() => {
    if (commentState === 'new') {
      setComment({ ...EMPTY_COMMENT })
      setTempComment({
        ...EMPTY_COMMENT,
        onlyVisibleToOwners: commentsVisibility === CommentVisibilityEnum.INTERNAL,
      })
    }
    setFiles([])
  }, [commentState, commentsVisibility])

  useLayoutEffect(() => {
    if (props.newCommentLocation) {
      setComment((c) => {
        return { ...c, ...props.newCommentLocation }
      })
      setTempComment((c) => {
        return { ...c, ...props.newCommentLocation }
      })
    }
    // state is required here to keep in sync with above LayoutEffect
  }, [commentState, props.newCommentLocation])

  const categories =
    props.categoryList?.map((cat) => {
      return { id: cat.id ? cat.id : '', label: cat.categoryName }
    }) || []

  if (tempComment.categoryId) categories.unshift({ id: 'no category', label: 'No Category' })

  const handleContentChange = (content: string) => {
    handleChange('content', content)
    setTempComment({
      ...tempComment,
      content: content,
    })
  }

  const handleTitleChange = (title: string) => {
    handleChange('title', title)
    setTempComment({
      ...tempComment,
      title: title.substring(0, 49),
    })
  }

  const handleCategorySelection = (categoryId: ReactText) => {
    setTempComment({
      ...tempComment,
      categoryId: categoryId === 'no category' ? null : categoryId,
      assignee: null,
    })
  }

  const handleChangeVisibility = (visibility: string) => {
    setTempComment({
      ...tempComment,
      onlyVisibleToOwners: visibility === CommentVisibilityEnum.INTERNAL.toString(),
    })
  }

  const saveComment = async () => {
    if (!isValid) {
      return
    }

    setCommentUploading(true)
    const toRemove =
      commentState === 'edit' ? comment.attachments.filter((f) => !tempComment.attachments.includes(f)) : undefined

    const fileNames = files.map((file) => truncateFileName(file.name))

    const request: ICommentAddProps = {
      phaseId: props.phaseId,
      comment: {
        ...tempComment,
        phaseId: props.phaseId,
        threadId: tempComment.threadId || null,
        attachments: fileNames,
      },
      toRemove,
    }

    const newComment = !tempComment.commentId ? await addComment(request) : await updateComment(request)

    if (!newComment) {
      addToast({
        type: 'error',
        message: 'There was an issue saving the comment.',
        timeout: 4000,
      })

      setCommentUploading(false)
      return
    }

    if (files.length) {
      const result = await uploadFile({
        commentId: newComment.commentId,
        phaseId: props.phaseId,
        files: files,
        fileNames,
      })
      if (!result)
        addToast({
          type: 'error',
          message: 'There was an issue uploading the comment attachments.',
          timeout: 3000,
        })
    }

    setCommentUploading(false)
    setSelectedCommentId(newComment.commentId)
    setCommentState('exisiting')
    props.removeNewCommentLocation()
    handleDrawer(true)
    setCommented(true)
    refreshComments()
  }

  const setEdit = (commentId: string) => {
    setCommentState('edit')
    setSelectedCommentId(commentId)
  }

  const handleAssignee = (selected: boolean) => {
    if (!category) return

    setTempComment({
      ...tempComment,
      assignee: selected ? category.categoryLead : null,
      assigneeName: category.categoryUserName ? category.categoryUserName : null,
    })
  }

  const handleCancelEdit = () => {
    setCommentState('exisiting')
    setTempComment({
      ...comment,
      title: `RE: ${comment.title}`,
      content: '',
      commentId: '',
      attachments: [],
      threadId: comment.commentId,
    })
    if (comment.threadId) {
      setSelectedCommentId(comment.threadId)
    }
  }

  const removeExistingAttachment = useCallback((index: number) => {
    setTempComment((temp) => {
      return {
        ...temp,
        attachments: temp.attachments.filter((f, i) => i !== index),
      }
    })
  }, [])

  const handleDone = () => {
    setCommented(false)
    if (comment.threadId) {
      if (comment.threadId.includes('#')) {
        const [dataId, id] = comment.threadId.split('#')
        setSelectedExternalData({ dataId, id })
        setSelectedCommentId(null)
      } else setSelectedCommentId(comment.threadId)
    } else handleCancelEdit()
  }

  const heading = commentState === 'edit' ? 'Edit a comment' : 'Add New Comment'

  const category = props.categoryList?.filter((cat) => cat.id === tempComment.categoryId)[0]

  const categoryLeadName = category?.categoryUserName
    ? `Assign to Category Lead: ${category.categoryUserName}`
    : 'No Category Lead Assigned'

  const categoryLeadDisable = !category?.categoryUserName
  const categoryLeadCheck = category?.categoryLead === tempComment.assignee && !categoryLeadDisable
  const categoryDropdownClass = `is-aal-dropdown is-aal-scrollable ${tempComment.categoryId === comment.categoryId ? '' : 'is-active'}`

  if (loading)
    return (
      <div className={Style.commentEditWrapper}>
        <Loader label='Loading comment...' />
      </div>
    )

  if (commentState === 'exisiting') {
    return (
      <>
        <div className={Style.existingComments}>
          <div className={Style.commentEditWrapper}>
            <SideComment
              phaseId={props.phaseId}
              comment={comment}
              categoryList={props.categoryList}
              onCancel={handleDone}
              onEdit={setEdit}
              canEdit={props.myMemberId === comment.userId && commentState === 'exisiting' && !commented}
              displayActions={commented}
            />
            {replies.map((reply) => (
              <SideComment
                phaseId={props.phaseId}
                key={reply.commentId}
                comment={reply}
                categoryList={props.categoryList}
                onCancel={handleDone}
                onEdit={setEdit}
                canEdit={props.myMemberId === reply.userId && commentState === 'exisiting' && !commented}
                displayActions={false}
              />
            ))}
          </div>
        </div>
        {!commented && (
          <ReplyComment
            externalData={null}
            comment={comment}
            categoryList={props.categoryList}
            authorName={comment.authorName}
            title={comment.title}
            phaseId={props.phaseId}
            onClose={props.onClose}
            setCommented={() => setCommented(true)}
            setCommentState={setCommentState}
          />
        )}
      </>
    )
  }

  return (
    <div className={Style.commentEditWrapper}>
      <Text type='h2'>{heading}</Text>
      <Radio
        items={CommentsVisibilityOptions}
        selectedItem={
          tempComment.onlyVisibleToOwners
            ? CommentVisibilityEnum.INTERNAL.toString()
            : CommentVisibilityEnum.PUBLIC.toString()
        }
        onItemSelect={handleChangeVisibility}
        label='Visibility:'
        horizontal
        labelInline
      />
      <Dropdown
        items={categories}
        placeholder='Select a category...'
        selectedItem={tempComment && tempComment.categoryId ? tempComment.categoryId : ''}
        onSelectItem={handleCategorySelection}
        default
        cssClass={Style.dropdown + categoryDropdownClass}
      />
      <Checkbox
        label={categoryLeadName}
        disabled={!tempComment.categoryId || categoryLeadDisable}
        onChange={handleAssignee}
        checked={categoryLeadCheck}
        cssClass={Style.categoryLeadName}
      />
      <FormInput
        label=''
        placeholder='Type in a title...'
        default
        onBlur={() => handleTouched('title')}
        onChange={handleTitleChange}
        value={tempComment.title}
        error={touched?.title ? errors?.title : ''}
        cssClass={Style.titleInput}
      />
      <FormInput
        label=''
        placeholder='Type in a comment...'
        multiline
        default
        onBlur={() => handleTouched('content')}
        onChange={handleContentChange}
        value={tempComment.content}
        multilineLimit={240}
        error={touched?.content ? errors?.content : ''}
      />
      <FileUpload
        files={files}
        setFiles={(file) => {
          setFiles(file)
        }}
        existingFiles={tempComment.attachments}
        progress={commentUploading ? uploadProgress : null}
        removeExisting={removeExistingAttachment}
        loading={commentUploading}
      />

      {commentState === 'edit' && (
        <div className={Style.drawerActions}>
          <Button label='Save Changes' onClick={saveComment} loading={commentUploading} disabled={!dirty || !isValid} />
          <Button label='Cancel' type='secondary' disabled={commentUploading} onClick={handleCancelEdit} />
        </div>
      )}

      {commentState === 'new' && (
        <div className={Style.drawerActions}>
          <Button label='Add Comment' onClick={saveComment} loading={commentUploading} disabled={!dirty || !isValid} />
          <Button label='Cancel' type='secondary' disabled={commentUploading} onClick={props.onClose} />
        </div>
      )}
    </div>
  )
}

export default AddEditComment
