import { useCallback } from 'react'
import { atom, selector, selectorFamily, useSetRecoilState } from 'recoil'
import { getPhaseGroups, getAllLayers, getSectionVideos, ILayerGroup } from '../api/layers'
import { getSource } from '../api/storage'
import { ProjectDetails } from './projectStore'
import { LayerGroupTypeEnum } from '../enums/LayerGroupTypeEnum'
import { getPhaseSources, ISource } from '../api/sources'
import { SourceTypeEnum } from './../enums/SourceTypeEnum'

export const SelectedLayerId = atom({
  key: 'selected_layer_id',
  default: selector({
    key: 'selected_layer_id/default',
    get: ({ get }) => {
      const groups = get(LayerGroups)
      const options = groups.filter((group) => group.groupType === LayerGroupTypeEnum.DESIGN_OPTION)
      if (options.length && options[0].Layers.length) return options[0].Layers[0].layerId
      else return ''
    },
  }),
})

export const SelectedGISGroup = atom({
  key: 'selected_gis_group',
  default: '',
})

export const OnlyShowGroupLayers = atom({
  key: 'only_show_group_layers',
  default: false,
})

export const StorageLink = selectorFamily({
  key: 'storage_link',
  get: (location: { phaseId: string; filename: string }) => async () => {
    const { phaseId, filename } = location
    return getSource({ phaseId, filename })
  },
})

export const SourceQuery = selectorFamily({
  key: 'source_query',
  get:
    (location: { phaseId: string; filename: string }) =>
    async ({ get }) => {
      const url = `${location.phaseId}/${location.filename}`
      try {
        const response = get(StorageLink(location))
        return { url, data: response }
      } catch {
        return { url, data: null }
      }
    },
})

const RefreshLayerGroups = atom({
  key: 'refreshLayerGroups',
  default: 0,
})

export const useRefreshLayerGroups = (): (() => void) => {
  const setRefresh = useSetRecoilState(RefreshLayerGroups)

  return useCallback(() => {
    setRefresh((val) => val + 1)
  }, [setRefresh])
}

export const LayerGroups = selector({
  key: 'layerGroups',
  get: async ({ get }) => {
    const projectDetails = get(ProjectDetails)
    if (!projectDetails) return []

    get(RefreshLayerGroups)

    const layerGroups = await getPhaseGroups({ phaseId: projectDetails.phaseId })
    if (!layerGroups) return []

    const layerGroupsOrdered = [...layerGroups].sort((a, b) => a.displayOrder - b.displayOrder)
    layerGroupsOrdered.forEach((group) => {
      group.Layers.sort((l1, l2) => l1.LayerGroupLayers.displayOrder - l2.LayerGroupLayers.displayOrder)
    })
    return layerGroupsOrdered
  },
})

export const AllLayers = selector({
  key: 'layerAll',
  get: async ({ get }) => {
    const projectDetails = get(ProjectDetails)
    if (!projectDetails) return []

    get(RefreshAllLayers)
    const allLayers = await getAllLayers({ phaseId: projectDetails.phaseId })
    if (!allLayers) return []

    return [...allLayers].sort((a, b) => a.layerName.localeCompare(b.layerName))
  },
})

export const LayerSource = selectorFamily({
  key: 'layerType',
  get:
    (layerId: string) =>
    async ({ get }) => {
      try {
        const sources = get(PhaseSources)
        if (!sources) return null

        let source = null

        sources.forEach((s) => {
          s.Layers.forEach((layer) => {
            if (layer.layerId === layerId) source = s
          })
        })

        return source
      } catch {
        return null
      }
    },
})

const RefreshSources = atom({
  key: 'refresh_sources',
  default: 0,
})

export const useRefreshSources = (): (() => void) => {
  const setRefresh = useSetRecoilState(RefreshSources)

  return useCallback(() => {
    setRefresh((val) => val + 1)
  }, [setRefresh])
}

export const PhaseSources = selector({
  key: 'phaseSources',
  get: async ({ get }) => {
    get(RefreshSources)
    const projectDetails = get(ProjectDetails)
    if (!projectDetails) return null

    const phaseSources = await getPhaseSources({ phaseId: projectDetails.phaseId })
    if (!phaseSources) return null

    return phaseSources
  },
})

export interface ILayerState {
  [key: string]: boolean
}

export const LayerState = atom({
  key: 'layerState',
  default: {} as ILayerState,
})

const getTypeSortOrder = (type: number) => {
  if (type === SourceTypeEnum.HEATMAP) return SourceTypeEnum.FILL
  else return type
}

const sortSources = (sources: ISource[], layerGroups: ILayerGroup[]) => {
  return sources.sort((s1, s2) => {
    const s1Type = getTypeSortOrder(s1.type)
    const s2Type = getTypeSortOrder(s2.type)
    if (s1Type !== s2Type) {
      return s2Type - s1Type
    } else {
      let order1 = 0
      let order2 = 0
      layerGroups.forEach((lg) => {
        lg.Layers.forEach((l) => {
          if (l.layerId === s1.Layers[0].layerId) {
            order1 = lg.displayOrder * 100 + l.LayerGroupLayers.displayOrder
          }
          if (l.layerId === s2.Layers[0].layerId) {
            order2 = lg.displayOrder * 100 + l.LayerGroupLayers.displayOrder
          }
        })
      })
      return order1 - order2
    }
  })
}

export const MapSources = selector({
  key: 'sources',
  get: ({ get }) => {
    const phaseSources = get(PhaseSources)
    if (!phaseSources) return []
    const layerGroups = get(LayerGroups)
    const filteredSources = phaseSources.filter((source) => source.Layers.length)
    return sortSources(filteredSources, layerGroups).map((source) => {
      if (source.location.includes('mapbox://')) return { source, url: source.location, data: null, mapbox: true }
      const sourceDetails = get(SourceQuery({ phaseId: source.phaseId, filename: source.location }))
      return { ...sourceDetails, source, mapbox: false }
    })
  },
})

export const SectionVideos = selector({
  key: 'sectionVideos',
  get: async ({ get }) => {
    const projectDetails = get(ProjectDetails)
    if (!projectDetails) return []

    return getSectionVideos({ phaseId: projectDetails.phaseId })
  },
})

interface ISourceUpload {
  [sourceFile: string]: {
    sourceId?: string
    sourceName: string
    loaded: number
    total: number
  }
}

export const SourceUploadProgress = atom<ISourceUpload>({
  key: 'sourceUpload',
  default: {},
})

const RefreshAllLayers = atom({
  key: 'refreshAllLayers',
  default: 0,
})

export const useRefreshAllLayers = (): (() => void) => {
  const setRefresh = useSetRecoilState(RefreshAllLayers)

  return useCallback(() => {
    setRefresh((val) => val + 1)
  }, [setRefresh])
}
