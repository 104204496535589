import { LngLatLike, Marker } from 'mapbox-gl'
import { FC, ReactElement, ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { getMap } from './HybridMap'

interface IHybridMarkerProps {
  lngLat: LngLatLike
  className?: string
}

const createMarker = (children: ReactNode, lngLat: LngLatLike, className?: string) => {
  const map = getMap()
  if (!map) return undefined

  const element = document.createElement('div')
  if (children) ReactDOM.render(children as ReactElement, element)

  const marker = new Marker({ element }).setLngLat(lngLat).addTo(map)
  if (className) marker.getElement().className += ' ' + className

  return marker
}

export const HybridMarker: FC<IHybridMarkerProps> = (props) => {
  const marker = useRef<Marker>()
  const { children, lngLat, className } = props

  useEffect(() => {
    if (!marker.current) {
      marker.current = createMarker(children, lngLat, className)
    }
  })

  useEffect(() => {
    marker.current?.setLngLat(lngLat)
  }, [lngLat])

  useEffect(() => {
    if (marker.current) {
      const pos = marker.current.getLngLat()
      marker.current.remove()
      marker.current = createMarker(children, pos, className)
    }
  }, [children, className])

  useEffect(() => {
    return () => {
      if (marker.current) {
        marker.current.remove()
      }
    }
  }, [])

  return null
}
