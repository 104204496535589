export enum CommentVisibilityEnum {
  PUBLIC = 0,
  INTERNAL = 1,
}

export const CommentVisibilityLabels = {
  [CommentVisibilityEnum.PUBLIC]: 'Public',
  [CommentVisibilityEnum.INTERNAL]: 'Aurecon-Internal',
}

export const CommentsVisibilityOptions = [
  { id: CommentVisibilityEnum.PUBLIC.toString(), label: CommentVisibilityLabels[CommentVisibilityEnum.PUBLIC] },
  { id: CommentVisibilityEnum.INTERNAL.toString(), label: CommentVisibilityLabels[CommentVisibilityEnum.INTERNAL] },
]
