import Joi from '@hapi/joi'

export const addEditCommentFormSchema = Joi.object({
  title: Joi.string().trim().required().max(100).messages({
    'string.base': 'You need to provide comment title.',
    'string.empty': 'You need to provide comment title.',
    'string.max': 'Title too long (100 characters limit)',
  }),
  content: Joi.string().trim().required().max(240).messages({
    'string.base': 'You need to provide comment content.',
    'string.empty': 'You need to provide comment content.',
    'string.max': 'Content too long (100 characters limit)',
  }),
})

export const replyCommentFormSchema = Joi.object({
  content: Joi.string().trim().required().max(240).messages({
    'string.base': 'You need to provide comment content.',
    'string.empty': 'You need to provide comment content.',
    'string.max': 'Content too long (100 characters limit)',
  }),
})
