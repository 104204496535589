import config from '../config/config'
import { Auth0ContextInterface } from '@auth0/auth0-react'
import { IProjectDetails } from '../api/projects'
import { UserRolesEnum } from '../enums/UserRolesEnum'

export const isGlobalAdmin = (user: Auth0ContextInterface): boolean => {
  if (!user?.[config.AUTH0_ROLES]) return false

  let globalAdmin = false
  user[config.AUTH0_ROLES]?.forEach((role: string) => {
    if (role === config.AUTH0_GLOBAL_ADMIN_ROLE) globalAdmin = true
  })

  return globalAdmin
}

export const isProjectAdmin = (details: IProjectDetails | null): boolean =>
  details?.userRole === UserRolesEnum.PROJECT_ADMIN
