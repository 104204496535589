import { selector, atom, selectorFamily } from 'recoil'
import { getExternalData, getExternalDataViewLink, IExternalData, IField } from '../api/storage'
import { ExternalDataTypeEnum } from '../enums/ExternalDataTypeEnum'
import { ProjectDetails } from './projectStore'

export interface ISelectedExternalData {
  dataId: string
  id: string
}

export const SelectedExternalData = atom<ISelectedExternalData | null>({
  key: 'selectedExternalData',
  default: null,
})

export const ExternalDataLink = selectorFamily({
  key: 'externalDataLink',
  get: (location: { phaseId: string; filename: string }) => async () => {
    const { phaseId, filename } = location
    return getExternalDataViewLink({ phaseId, filename })
  },
})

export const ExternalData = selector({
  key: 'externalData',
  get: async ({ get }) => {
    const details = get(ProjectDetails)
    if (!details) return [] as IExternalData[]

    const externalData = await getExternalData({ phaseId: details.phaseId })
    if (!externalData) return [] as IExternalData[]

    return Promise.all(
      externalData.map(async (dataSet): Promise<IExternalData> => {
        const signedLink = await getExternalDataViewLink({ phaseId: dataSet.phaseId, filename: dataSet.dataIcon })
        dataSet.dataIcon = signedLink ?? ''
        return dataSet
      }),
    )
  },
})

export interface ISelectedExternalDataPoint {
  id: string
  phaseId: string
  dataIcon: string
  colourFieldId: string
  dataId: string
  dataLocation: number
  dataName: string
  dataType: number
  imageLocation: number
  date: Date
  lat: number
  long: number
  fields: { id: string; data: IField[]; name: string; order: number; labelPosition: number; type: number }[]
}

export const ExternalDataPoint = selector({
  key: 'externalDataPoint',
  get: ({ get }): ISelectedExternalDataPoint | null => {
    const externalData = get(ExternalData)
    const selectedData = get(SelectedExternalData)
    if (!externalData || !selectedData) return null

    const dataSet = [...externalData].filter((data: IExternalData) => data.dataId === selectedData.dataId)[0]
    if (!dataSet) return null

    const dataPoint = dataSet.data.find((data) => {
      return data.partitionKey === selectedData.dataId && data.rowKey === selectedData.id
    })
    if (!dataPoint) return null

    const unorderedFields = []
    const date = new Date(dataPoint.timestamp)
    const uniqueId = `${dataPoint.partitionKey}#${dataPoint.rowKey}`

    for (const f in dataSet.fields) {
      const field = { ...dataSet.fields[f], id: '', data: [] as IField[] }
      const isImage = field.type === ExternalDataTypeEnum.IMAGE

      dataPoint.fields.forEach((point) => {
        if (point.id === f) {
          const newPoint = { ...point }
          if (isImage && point.value) {
            const fullname = point.value.toString().split('/')
            const filename = fullname[fullname.length - 1]
            const signedLink = get(ExternalDataLink({ phaseId: dataSet.phaseId, filename }))
            newPoint.value = signedLink
          }
          field.id = newPoint.id
          field.data.push(newPoint)
        }
      })
      unorderedFields.push(field)
    }

    const orderedFields = unorderedFields.sort((a, b) => a.order - b.order)

    return {
      ...dataSet,
      id: uniqueId,
      lat: dataPoint.lat,
      long: dataPoint.long,
      date,
      fields: orderedFields,
    }
  },
})
