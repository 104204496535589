import { atom } from 'recoil'
import { area, length } from '@turf/turf'
import { Feature, LineString, Polygon } from 'geojson'

export type MeasureType = 'line' | 'area'

export interface IMeasurement {
  id: number
  mode: MeasureType
  points: number[][]
  length: string
  area: string
  visible: boolean
}

export const Measuring = atom({
  key: 'measuring',
  default: false,
})

export const MeasureMode = atom<MeasureType | null>({
  key: 'measureMode',
  default: null,
})

export const Measurements = atom<IMeasurement[]>({
  key: 'measurements',
  default: [],
})

export const CurrentMeasurement = atom<number[][]>({
  key: 'currentMeasurement',
  default: [],
})

export const getDistance = (points: number[][], mode: MeasureType): string => {
  const coords = [...points]
  if (mode === 'area') coords.push(coords[0])

  const geojson: Feature<LineString> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: coords,
    },
  }

  return (Math.round(length(geojson, { units: 'metres' }) * 100) / 100).toLocaleString() + 'm'
}

export const getArea = (points: number[][]): string => {
  const coords = [...points]
  coords.push(coords[0])
  const geojson: Feature<Polygon> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [coords],
    },
  }
  return (Math.round(area(geojson) * 100) / 100).toLocaleString() + 'm²'
}
