import { useCallback } from 'react'
import { selector, atom, useSetRecoilState } from 'recoil'
import { ProjectDetails } from './projectStore'
import { getComments, IComment, ShowComment } from '../api/comments'
import { ILocation } from '../components/AddEditComment'

import isWithinInterval from 'date-fns/isWithinInterval'
import isSameDay from 'date-fns/isSameDay'
import fromUnixTime from 'date-fns/fromUnixTime'

import { CommentFiltersEnum } from '../enums/CommentFiltersEnum'
import { UserDetails } from './authStore'
import { addDays } from 'date-fns'

export const SelectedCommentId = atom<string | null>({
  key: 'selected_comment_id',
  default: null,
})

export const NewCommentLocation = atom<ILocation | null>({
  key: 'new_comment_location',
  default: null,
})

export const CommentUploading = atom<boolean>({
  key: 'comment_uploading',
  default: false,
})

export const ShowCommentOption = atom<ShowComment>({
  key: 'show_comment',
  default: 'all',
})

export const Comments = selector({
  key: 'comments',
  get: async ({ get }) => {
    get(RefreshComments)
    const details = get(ProjectDetails)
    if (!details) return [] as IComment[]

    const showComment = get(ShowCommentOption)
    const comments = await getComments({ phaseId: details.phaseId, showComment })
    return comments ? [...comments].sort((comment1, comment2) => comment2.createdAt - comment1.createdAt) : comments
  },
})

export const FilteredComments = selector({
  key: 'filteredComments',
  get: async ({ get }) => {
    const comments = get(Comments)
    const filters = get(CommentFilters)
    const user = get(UserDetails)

    let commentsPrep: IComment[] = comments || []

    // Date filter
    if (filters.dateFilter) {
      commentsPrep = commentsPrep.filter((comment) => {
        if (!filters.dateRange[0].endDate || isSameDay(filters.dateRange[0].startDate, filters.dateRange[0].endDate))
          return isSameDay(fromUnixTime(comment.createdAt / 1000), filters.dateRange[0].startDate)

        return isWithinInterval(fromUnixTime(comment.createdAt / 1000), {
          start: filters.dateRange[0].startDate,
          end: addDays(filters.dateRange[0].endDate, 1),
        })
      })
    }

    // Visibility filters
    if (filters.visibility.length) {
      commentsPrep = commentsPrep.filter((comment) => {
        let response = false
        filters.visibility.forEach((filter) => {
          if (filter === CommentFiltersEnum.VISIBILITY_INTERNAL && comment.onlyVisibleToOwners) response = true
          if (filter === CommentFiltersEnum.VISIBILITY_PUBLIC && !comment.onlyVisibleToOwners) response = true
        })
        return response
      })
    }

    if (filters.assigned.length) {
      commentsPrep = commentsPrep.filter((comment) => {
        let response = false
        filters.assigned.forEach((filter) => {
          if (filter === CommentFiltersEnum.ASSIGNED_ME && comment.assigneeName === user.name) response = true
        })
        return response
      })
    }

    if (filters.attachments.length) {
      commentsPrep = commentsPrep.filter((comment) => {
        return filters.attachments.some(
          (attach) =>
            (attach === CommentFiltersEnum.ATTACHMENTS_YES && comment.attachments.length) ||
            (attach === CommentFiltersEnum.ATTACHMENTS_NO && !comment.attachments.length),
        )
      })
    }

    if (filters.category.length) {
      commentsPrep = commentsPrep.filter((comment) => {
        let response = false
        filters.category.forEach((filter) => {
          if (filter === comment.categoryId) response = true
        })
        return response
      })
    }

    if (filters.status.length) {
      commentsPrep = commentsPrep.filter((comment) => {
        let response = false
        filters.status.forEach((filter) => {
          if (filter === CommentFiltersEnum.STATUS_ASSIGNED && comment.assignee) response = true
          if (filter === CommentFiltersEnum.STATUS_UNASSIGNED && !comment.assignee) response = true
        })
        return response
      })
    }

    return commentsPrep
  },
})

const RefreshComments = atom({
  key: 'refreshComments',
  default: 0,
})

export const useRefreshComments = (): (() => void) => {
  const setRefresh = useSetRecoilState(RefreshComments)

  return useCallback(() => {
    setRefresh((val) => val + 1)
  }, [setRefresh])
}

export interface IDateRange {
  startDate: Date
  endDate?: Date
  key: string
}

interface ICommentFilters {
  visibility: string[]
  assigned: string[]
  attachments: string[]
  category: string[]
  status: string[]
  dateFilter: boolean
  dateRange: IDateRange[]
}

export const NO_FILTERS: ICommentFilters = {
  visibility: [],
  assigned: [],
  attachments: [],
  category: [],
  status: [],
  dateFilter: false,
  dateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ],
}

export const CommentFilters = atom({
  key: 'commentFilters',
  default: NO_FILTERS,
})
