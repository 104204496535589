export interface IAppVersion {
  version: string
}

export const getAppVersion = async (): Promise<IAppVersion> => {
  try {
    const response = await fetch('/app/version')
    if (response.ok) return await response.json()
    else return { version: '0.0.0' }
  } catch {
    return { version: '0.0.0' }
  }
}
