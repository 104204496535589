import { FeatureCollection, Feature } from '@turf/turf'
import { MAPBOX_ACCOUNT } from '../config/config'
import { IResponse } from '../helpers/utils'
import { getMapboxToken } from './storage'

const BaseAPIURL = 'https://api.mapbox.com/tilesets/v1'

const precisionOffset = 5

const recipeTemplate = {
  name: '',
  recipe: {
    version: 1,
    layers: {
      default: {
        source: '',
        minzoom: 0,
        maxzoom: 16,
      },
    },
  },
  description: '',
}

const readFileAsync = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsText(file)
  })
}

const publishTileset = async (id: string, token: string) => {
  const response = await fetch(`${BaseAPIURL}/${MAPBOX_ACCOUNT}.${id}/publish?access_token=${token}`, {
    method: 'POST',
  })

  const json = await response.json()
  return { success: response.ok, message: json.jobId || json.message }
}

const createTileset = async (name: string, id: string, projectName: string, precision: number, token: string) => {
  const recipe = { ...recipeTemplate }
  recipe.name = name
  recipe.recipe.layers.default.source = `mapbox://tileset-source/${MAPBOX_ACCOUNT}/${id}`
  recipe.recipe.layers.default.maxzoom = precisionOffset + precision
  recipe.description = projectName

  const response = await fetch(`${BaseAPIURL}/${MAPBOX_ACCOUNT}.${id}?access_token=${token}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(recipe),
  })

  const json = await response.json()
  return { success: response.ok, message: json.message }
}

const uploadFileToMapbox = async (
  file: File,
  id: string,
  token: string,
  setUploadProgress: (loaded: number, total: number) => void,
) => {
  let geojsonldFile: File
  try {
    const geojson = JSON.parse(await readFileAsync(file)) as FeatureCollection | Feature
    const geojsonld = geojson['features']
      ? geojson['features'].map((feature: unknown) => JSON.stringify(feature)).join('\n')
      : JSON.stringify(geojson)
    geojsonldFile = new File([geojsonld], file.name)
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Invalid file format' }
  }

  try {
    const fm = new FormData()
    fm.set('file', geojsonldFile)

    return new Promise<IResponse>((resolve, reject) => {
      const xmlReq = new XMLHttpRequest()
      xmlReq.upload.addEventListener('progress', (ev) => setUploadProgress(ev.loaded, ev.total))
      xmlReq.addEventListener('error', reject)
      xmlReq.addEventListener('load', () => {
        if (xmlReq.status === 200) resolve({ success: true })
        else resolve({ success: false, message: JSON.parse(xmlReq.responseText).message })
      })
      xmlReq.open('POST', `${BaseAPIURL}/sources/${MAPBOX_ACCOUNT}/${id}?access_token=${token}`)
      xmlReq.send(fm)
    })
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Network error' }
  }
}

const uploadFile = async (
  file: File,
  sourceName: string,
  projectName: string,
  phaseId: string,
  tilesetId: string,
  precision: number,
  setUploadProgress: (loaded: number, total: number) => void,
): Promise<IResponse> => {
  const token = await getMapboxToken({ phaseId })
  if (!token) return { success: false, message: 'Failed to get authentication' }

  const uploadRes = await uploadFileToMapbox(file, tilesetId, token, setUploadProgress)
  if (!uploadRes.success) return uploadRes

  const createRes = await createTileset(sourceName, tilesetId, projectName, precision, token)
  if (!createRes.success) return createRes

  const publishRes = await publishTileset(tilesetId, token)
  if (!publishRes.success) return publishRes

  return { success: true, message: publishRes.message }
}

export default uploadFile
