import { LngLatLike, Popup } from 'mapbox-gl'
import { FC, ReactElement, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { getMap } from './HybridMap'

interface IHybridPopupProps {
  lngLat: LngLatLike
  className?: string
  offset?: [number, number]
  closeButton?: boolean
  closeOnClick?: boolean
  tipSize?: number
  maxWidth?: string
  onClose?: () => void
}

export const HybridPopup: FC<IHybridPopupProps> = (props) => {
  const popup = useRef<Popup>()
  const { children, className, lngLat, offset, closeButton, closeOnClick, tipSize, maxWidth, onClose } = props

  useEffect(() => {
    if (popup.current) return

    const map = getMap()
    if (!map) return

    const e = document.createElement('div')
    if (children) ReactDOM.render(children as ReactElement, e)

    popup.current = new Popup({ className, closeButton: closeButton !== false, closeOnClick })
      .setLngLat(lngLat)
      .setDOMContent(e)
      .setOffset(offset)
      .addTo(map)

    if (tipSize !== undefined) {
      popup.current
        .getElement()
        .querySelector('.mapboxgl-popup-tip')
        ?.setAttribute('style', `border-width:${tipSize}px`)
    }

    if (maxWidth !== undefined) popup.current.setMaxWidth(maxWidth)
  })

  useEffect(() => {
    popup.current?.setLngLat(lngLat)
  }, [lngLat])

  useEffect(() => {
    if (onClose) popup.current?.on('close', onClose)
    return () => {
      if (onClose) popup.current?.off('close', onClose)
    }
  }, [onClose])

  useEffect(() => {
    if (popup.current) {
      const e = document.createElement('div')
      if (children) ReactDOM.render(children as ReactElement, e)

      popup.current.setDOMContent(e)
    }
  }, [children])

  useEffect(() => {
    return () => {
      if (popup.current) {
        popup.current.remove()
      }
    }
  }, [])

  return null
}
