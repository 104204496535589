import Joi from '@hapi/joi'

const noAccessFormSchema = Joi.object({
  name: Joi.string().label('Full Name').trim().required().max(100).messages({
    'string.base': 'Please provide a {#label}',
    'string.empty': 'Please provide a {#label}',
    'string.max': '{#label} too long (max of 100 characters)',
  }),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .label('Email Address')
    .required()
    .messages({
      'string.base': 'Please provide an {#label}',
      'string.empty': 'Please provide an {#label}',
    }),
  reason: Joi.string().label('Reason').trim().required().default(`I'd like an access, please.`).messages({
    'string.base': 'Please provide a {#label}',
    'string.empty': 'Please provide a {#label}',
  }),
})

export default noAccessFormSchema
