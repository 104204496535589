import { TokenExpiryWrapper } from './TokenManager'
import { headersWithToken, IResponse } from '../helpers/utils'
import { IRequestPhaseProps, IRequestTokenProps } from './apiprops'
import { useCallback, useState } from 'react'
import uploadMapboxVectorFile from './mapbox'
import uploadMapboxRasterFile from './mapbox_raster'
import { IFeatureCollection } from './layers'

type FileDestination = 'attachments' | 'sources'

export const getStorageAccount = async (props: IRequestTokenProps): Promise<string | null> => {
  try {
    const response = await fetch('/storage/account', {
      headers: headersWithToken(props.token),
    })
    if (response.ok) return response.text()
    else return null
  } catch {
    return null
  }
}

interface IGetAttachmentProps {
  token?: string
  location: string
  phaseId: string
  commentId: string
}

const createBlobURL = async (response: Response): Promise<string | null> => {
  if (response.ok) {
    const blob = await response.blob()
    const newBlob = new Blob([blob], { type: response.headers.get('Content-Type') ?? '' })
    const blobUrl = window.URL.createObjectURL(newBlob)
    return blobUrl
  } else {
    console.log(`Error creating the blobURL:`, response.text())
    return null
  }
}

const getAttachmentRequest = async (props: IGetAttachmentProps): Promise<string | null> => {
  try {
    const response = await fetch(
      `/storage/attachment/${props.phaseId}/${props.commentId}/${encodeURIComponent(props.location)}`,
      {
        headers: headersWithToken(props.token),
      },
    )
    return await createBlobURL(response)
  } catch {
    return null
  }
}
export const getAttachment = TokenExpiryWrapper(getAttachmentRequest, [], null)

const uploadFileToAzure = async (
  phaseId: string,
  destination: FileDestination,
  location: string,
  file: File,
  progressCallback?: (filename: string, loaded: number) => void,
  token?: string,
): Promise<void> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('location', `${destination}/${location}`)
  formData.append('phaseId', phaseId)
  formData.append('filename', file.name)
  const response = await fetch(`/storage/upload-${destination}`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  })
  if (response.ok) {
    progressCallback && progressCallback(file.name, file.size)
  } else {
    throw new Error(`Failed to upload ${file.name} to azure-storage`)
  }
}

export interface IUploadAttachmentsProps {
  token?: string
  phaseId: string
  commentId: string
  files: File[]
  fileNames: string[]
  progressCallback?: (filename: string, loaded: number) => void
}

const uploadAttachmentsRequest = async (props: IUploadAttachmentsProps): Promise<boolean> => {
  if (!props.files.length) return true

  const promises: Promise<void>[] = []
  for (const file of props.files) {
    promises.push(
      uploadFileToAzure(props.phaseId, 'attachments', `${props.commentId}`, file, props.progressCallback, props.token),
    )
  }

  try {
    await Promise.all(promises)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

const uploadAttachments = TokenExpiryWrapper(uploadAttachmentsRequest, [], false)

export const useUploadAttachments = (): {
  uploadProgress: IUploadProgress
  uploadFile: (props: IUploadAttachmentsProps) => Promise<boolean>
} => {
  const [uploadProgress, setUploadProgress] = useState<IUploadProgress>({})

  const uploadAttachment = useCallback((props: IUploadAttachmentsProps) => {
    return uploadAttachments({
      ...props,
      progressCallback: (filename: string, loaded: number) => {
        setUploadProgress((prog) => {
          return { ...prog, [filename]: loaded }
        })
      },
    })
  }, [])

  return { uploadProgress, uploadFile: uploadAttachment }
}

export interface IUploadSourceProps {
  token?: string
  phaseId: string
  file: File
  fileName: string
  progressCallback: (loaded: number, total: number) => void
}

const uploadSourceRequest = async (props: IUploadSourceProps): Promise<IResponse> => {
  try {
    await uploadFileToAzure(
      props.phaseId,
      'sources',
      props.fileName,
      props.file,
      (_: string, loaded: number) => props.progressCallback(loaded, props.file.size),
      props.token,
    )
    return { success: true }
  } catch (error) {
    console.log(error)
    return { success: false, message: 'Failed to upload file' }
  }
}

const uploadSource = TokenExpiryWrapper(uploadSourceRequest, [], { success: false, message: '' })

interface IUploadProgress {
  [filename: string]: number
}

const getMapboxTokenRequest = async (props: IRequestPhaseProps): Promise<string | null> => {
  try {
    const response = await fetch(`/storage/mapbox/${props.phaseId}`, {
      headers: headersWithToken(props.token),
    })
    if (response.ok) return response.text()
    else return null
  } catch {
    return null
  }
}
export const getMapboxToken = TokenExpiryWrapper(getMapboxTokenRequest, [], null)

export const uploadSourceFile = async (
  sourceDestination: string,
  file: File,
  fileName: string,
  phaseId: string,
  sourceName: string,
  projectName: string,
  precision: number,
  uploadCallback: (loaded: number, total: number) => void,
): Promise<IResponse> => {
  if (sourceDestination === 'azure') {
    // Upload to Azure storage
    return uploadSource({
      file,
      fileName,
      phaseId,
      progressCallback: uploadCallback,
    })
  } else if (sourceDestination === 'mapbox_vector') {
    // Upload to mapbox
    return uploadMapboxVectorFile(file, sourceName, projectName, phaseId, fileName, precision, uploadCallback)
  } else {
    return uploadMapboxRasterFile(file, phaseId, fileName, sourceName, uploadCallback)
  }
}

interface IViewLinkRequest {
  token?: string
  phaseId: string
  filename: string
}

const getSourceRequest = async (props: IViewLinkRequest): Promise<IFeatureCollection> => {
  const response = await fetch(`/storage/source/${props.phaseId}/${encodeURIComponent(props.filename)}`, {
    headers: headersWithToken(props.token),
  })
  if (!response.ok) throw response
  return response.json()
}
export const getSource = TokenExpiryWrapper(getSourceRequest, [], null)

const getExternalDataViewLinkRequest = async (props: IViewLinkRequest): Promise<string | null> => {
  const response = await fetch(`/storage/external/${props.phaseId}/${encodeURIComponent(props.filename)}`, {
    headers: headersWithToken(props.token),
  })
  return await createBlobURL(response)
}
export const getExternalDataViewLink = TokenExpiryWrapper(getExternalDataViewLinkRequest, [], null)

interface IGetExternalDataProps {
  token?: string
  phaseId: string
}

export interface IExternalData {
  phaseId: string
  dataIcon: string
  colourFieldId: string
  dataId: string
  dataLocation: number
  dataName: string
  dataType: number
  fields: IFieldDef
  imageLocation: number
  data: IExternalDataPoint[]
}

export interface IExternalDataPoint {
  partitionKey: string
  rowKey: string
  timestamp: string
  phaseId: string
  lat: number
  long: number
  fields: IField[]
}

export interface IField {
  id: string
  value: number | string | null
  value2?: number | string | null
}

export interface IFieldDef {
  [key: string]: {
    name: string
    order: number
    labelPosition: number
    type: number
  }
}

const getExternalDataRequest = async (props: IGetExternalDataProps): Promise<IExternalData[] | null> => {
  try {
    const response = await fetch(`/external/${props.phaseId}`, {
      headers: headersWithToken(props.token),
    })
    if (!response.ok) throw new Error(`Error: ${JSON.stringify(response.text())}`)

    return await response.json()
  } catch (error) {
    return null
  }
}

export const getExternalData = TokenExpiryWrapper(getExternalDataRequest, [], null)

const getLayerVideoRequest = async (props: IViewLinkRequest): Promise<string | null> => {
  const response = await fetch(`/storage/layer/video/${props.phaseId}/${encodeURIComponent(props.filename)}`, {
    headers: headersWithToken(props.token),
  })
  return await createBlobURL(response)
}
export const getLayerVideo = TokenExpiryWrapper(getLayerVideoRequest, [], null)
